import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { SocketService, SocketRoom } from "../../services/socket.service";

@Injectable({ providedIn: "root" })
export class PatientSocketResolver implements Resolve<null> {
  constructor(private _socketService: SocketService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): null {
    const options = {
      CPMRN: route.paramMap.get("patientCPMRN"),
      encounters: route.paramMap.get("patientEncounters"),
    };

    this._socketService.connect();

    setTimeout(() => {
      this._socketService.joinRoom(SocketRoom.Patient, options);
      this._socketService.leaveRoom({ room: SocketRoom.PatientList });
    }, 500);

    return null;
  }
}
