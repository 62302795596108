import { Injectable } from "@angular/core";

import {
  auditorScopes,
  billingAdministratorScopes,
  superBillingAdministratorScopes,
  dietitianScopes,
  documentationSpecialistScopes,
  labTechnicianScopes,
  medicalAdministratorScopes,
  rnScopes,
  ccrnScopes,
  nursePractitionerScopes,
  pharmacologistScopes,
  physicianScopes,
  specialistScopes,
  ralertScopes,
  superAdministratorScopes,
  systemAdministratorScopes,
  tvScopes,
  physicalTherapistScopes,
  occupationalTherapistScopes,
  respiratoryTherapistScopes,
  dataEntryAssociate,
  physicianAssociateScopes,
  tvUnitScopes,
  ccaPhysicanScops,
} from "./permissions";

const mapRoleToScope = new Map([
  ["Tv", tvScopes],
  ["Tv-Covid", tvScopes],
  ["TV-view (Unit)", tvUnitScopes],
  ["Nurse", rnScopes],
  ["Critical Care Nurse", ccrnScopes],
  ["Critical Care Associate", nursePractitionerScopes],
  ["R-Alert", ralertScopes],
  ["Auditor", auditorScopes],
  ["Dietitian", dietitianScopes],
  ["Physician", physicianScopes],
  ["Specialist", specialistScopes],
  ["CCA Physician", ccaPhysicanScops],
  ["Lab Technician", labTechnicianScopes],
  ["Pharmacologist", pharmacologistScopes],
  ["Super Administrator", superAdministratorScopes],
  ["System Administrator", systemAdministratorScopes],
  ["Super Billing Administrator", superBillingAdministratorScopes],
  ["Billing Administrator", billingAdministratorScopes],
  ["Medical Administrator", medicalAdministratorScopes],
  ["Documentation Specialist", documentationSpecialistScopes],
  ["Data Entry Associate", dataEntryAssociate],
  ["Physical Therapist", physicalTherapistScopes],
  ["Physician Associate", physicianAssociateScopes],
  ["Occupational Therapist", occupationalTherapistScopes],
  ["Respiratory Therapist", respiratoryTherapistScopes],
]);

@Injectable({ providedIn: "root" })
export class PermissionService {
  getPermissions(role: string) {
    return mapRoleToScope.get(role) || [];
  }
}
