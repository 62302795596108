export const template = new Map([
  // default Template
  ["primaryTemplate", "<p><strong><u>Summary</u></strong>:&nbsp;</p>"],
  [
    "diagnosisTemplate",
    "<p><strong><u>Diagnosis</u></strong>:&nbsp;</p><ul><li></li></ul>",
  ],
  [
    "secondaryTemplateMICU",
    "<p><strong><u>Assessment and Plan</u></strong>:&nbsp;</p><ul><li><strong>CNS/Psych</strong>:&nbsp;</li><li><strong>CVS</strong>:&nbsp;</li><li><strong>Resp</strong>:&nbsp;</li><li><strong>Abd/nutrition</strong>:&nbsp;</li><li><strong>Renal</strong>:&nbsp;</li><li><strong>ID/Lines/Tubes</strong>:&nbsp;</li><li><strong>Hematologic</strong>:&nbsp;</li><li><strong>Endo</strong>:&nbsp;</li><li><strong>Prophylaxis</strong>:&nbsp;</ul>",
  ],
  [
    "tertiaryMICU-2Template",
    "<p><strong><u>Family Counselling and Patient communication</u></strong>:&nbsp;</p><ul><li><br></li></ul><p><br></p><p><strong><u>To-Do List</u></strong>:&nbsp;</p><ul><li><br></li></ul>",
  ],
  [
    "tertiaryTemplate",
    "<p><strong><u>To-Do List</u></strong>:&nbsp;</p><ul><li><br></li></ul>",
  ],

  // NICU admission Template

  [
    "primaryTemplateNICU",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p><p><span>Reason for referral:&nbsp;</span></p><p><span>Presenting complaints:&nbsp;</span></p><p><span>Previous treatment Details (if any):&nbsp;</span></p><p><span>CRIB 2 score/ SNAPPE-II score:&nbsp;</span></p><p><br></p>",
  ],
  [
    "diagnosisTemplateNICU",
    "<p><strong><u>Maternal History</u></strong>:&nbsp;</p><p><br></p><p><span>Mother's Age:&nbsp;</span></p><p><br></p><p><strong>G</strong><span>:</span>&nbsp;&nbsp;&nbsp;&nbsp;<strong>P</strong><span>:</span>&nbsp;&nbsp;&nbsp;&nbsp;<strong>A</strong><span>:</span>&nbsp;&nbsp;&nbsp;&nbsp;<strong>L</strong><span>:</span>&nbsp;&nbsp;&nbsp;&nbsp;<strong>D</strong><span>:</span></p><p><br></p><p><span>LMP:&nbsp;</span></p><p><span>EDD:&nbsp; </span></p><p><span>Steroids:&nbsp;</span></p><p><br></p><p><strong>Serology</strong><span>:&nbsp;</span></p><p><span>VDRL:&nbsp;</span></p><p><span>HIV:&nbsp;</span></p><p><span>HBsAg:&nbsp;</span></p><p><span>Rubella Antibodies:&nbsp;</span></p><p><br></p><p><span>Antenatal Scans/ Doppler:&nbsp;</span></p><p><span>Liquor volume:&nbsp;</span></p><p><span>Scan EDD:&nbsp;</span></p><p><br></p><p><span>Any relevant Medical Illness:&nbsp;</span></p><p><span>Mother's Blood Group:&nbsp;</span></p><p><span>Hb:&nbsp;</span></p><p><br></p><p><span>Any Significant Antenatal report:&nbsp;</span></p><p><br></p><p><strong><u>Birth History</u></strong><span>:&nbsp;</span></p><p><br></p><p><span>Mode of delivery:&nbsp;</span></p><p><span>Indication:&nbsp;</span></p><p><span>Liquor:&nbsp;Clear / MSL</span></p><p><span>Birth weight:&nbsp;</span></p><p><br></p><p><span>ROM:&nbsp;</span></p><p><span>Risk Factors for Sepsis:&nbsp;</span></p><p><br></p><p><span>Delayed cord clamping/ milking? YES/ NO</span></p><p><br></p><p><span>Resuscitation details (if any):&nbsp;</span></p><p><span>Presentation:&nbsp;Vertex / Breech</span></p><p><br></p><p><span>Apgar:</span>&nbsp;</p><p><span>1min:</span>&nbsp;</p><p><span>5min:</span>&nbsp;</p><p><span>10min:</span>&nbsp;</p><p><span>Inj Vitamin K:&nbsp;Given / Not Given</span>&nbsp;</p><p><br></p><p><span>Condition on arrival:</span>&nbsp;</p>",
  ],
  [
    "secondaryTemplateNICU",
    "<p><strong><u>Admission details</u></strong>:&nbsp;</p><p><br></p><p><strong><u>Anthropometry</u></strong><span>:&nbsp;</span></p><p><br></p><p><span>Admission weight:&nbsp;</span></p><p><span>Head circumference:&nbsp;</span></p><p><span>Length:&nbsp;</span></p><p><br></p><p><span>Ponderal index:&nbsp;</span></p><p><br></p><p><span>Gestational Age:&nbsp;</span></p><p><span>By Dates:&nbsp;</span></p><p><span>By Ballard’s:&nbsp;</span></p><p><br></p><p><strong><u>General physical examination</u></strong><span>:&nbsp;</span></p><p><span>Cyanosis/ Plethora/ Icterus/ Pallor/ Oedema</span></p><p><br></p><p><strong><u>Vital Signs</u></strong><span>:&nbsp;</span></p><p><span>Temperature:&nbsp;</span></p><p><span>Heart rate:&nbsp;</span></p><p><span>Respiratory rate:&nbsp;</span></p><p><span>Mean BP:&nbsp;</span></p><p><span>SPO2:&nbsp;</span></p><p><br></p><p><strong><u>Dysmorphic features/ congenital anomalies</u></strong><span>:&nbsp;</span></p><p><br></p><p><span>Orifices:&nbsp;</span></p><p><span>Skin:&nbsp;</span></p><p><span>Head:&nbsp;</span></p><p><span>AF:&nbsp;</span></p><p><span>Eyes:&nbsp;</span></p><p><span>Ears:&nbsp;</span></p><p><span>Nose:&nbsp;</span></p><p><span>Oral Cavity:&nbsp;</span></p><p><span>Lungs &amp; Chest:&nbsp;</span></p><p><span>Cardiac:&nbsp;</span></p><p><span>Pulses:&nbsp;</span></p><p><span>Abdomen:&nbsp;</span></p><p><span>Umbilicus:&nbsp;</span></p><p><span>Back &amp; Spine:&nbsp;</span></p><p><span>Limbs &amp; Hips:&nbsp;</span></p><p><span>Neuro:&nbsp;</span></p><p><span>Genitalia:&nbsp;</span></p>",
  ],
  [
    "tertiaryTemplateNICU",
    "<p><strong><u>Systemic examination</u></strong>:&nbsp;</p><p><br></p><p><strong><u>Respiratory system</u></strong>:&nbsp;</p><p><br></p><p>Downes score/ Silverman score:&nbsp;</p><p><br></p><p><strong><u>Cardiovascular system</u></strong>:&nbsp;</p><p><br></p><p><strong>Abdomen</strong>:&nbsp;</p><p><br></p><p><strong>CNS: </strong>Cry:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tone:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Activity:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moros Reflex:</p>",
  ],
  [
    "medicationTemplateNICU",
    "<p><strong><u>Diagnosis</u></strong>:</p><ol><li><br></li><li><br></li><li><br></li></ol>",
  ],
  [
    "reportTemplateNICU",
    "<p><strong><u>Plan of treatment</u></strong><span>:&nbsp;</span></p><br><p><strong><u>Ventilation</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Ventilation care</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Nutrition</u></strong><span>:&nbsp;</span></p><p><span>    Feeds</span></p><p><span>    TPN</span></p><p><br></p><p><strong><u>IV Fluids</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Antibiotics</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Inotropes</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Sedation</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Transfusions</u></strong><span>:&nbsp;</span></p><p><br></p><p><strong><u>Others</u></strong><span>:</span>&nbsp;</p><p><br></p><p><strong><u>Investigations</u></strong><span>:&nbsp;</span></p>",
  ],

  //NICU progress template

  [
    "primaryTemplateProgressNICU",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p><p><br></p><p><strong>HOL/DOL</strong>:</p><p><strong>DOA</strong>:</p><p><strong>GA</strong>:</p><p><strong>CGA</strong>:</p><p><br></p><p><strong><u>Birth weight</u></strong>:&nbsp;</p><p><br></p><p><strong><u>Current weight</u></strong>:&nbsp;</p><p><br></p><p><strong><u>Problems list</u></strong>:</p><p><span>1.</span></p><p><span>2.</span></p><p><span>3.</span></p><p><span>4.</span></p>",
  ],
  [
    "diagnosisTemplateProgressNICU",
    "<p><strong><u>Assessment</u></strong>:</p><p><br></p><p><strong>Vitals</strong>: <p>Temp:</p> <p>RR:</p> <p>HR:</p> <p>BP:</p> <p>CRT:</p> <p>SpO2:</p><p>FiO2:</p><p><br></p><p><strong>CNS</strong>:</p><p><span>Consciousness:</span></p><p><span>Cry/tone/activity:</span></p><p><span>Reflexes:</span></p><p><span>Anti convulsants:</span></p><p><span>Sedation</strong>:</span></p><p><br></p><p><strong>CVS</strong>:</p><p><strong>Resp</strong>:</p><p><strong>Abd/nutrition</strong>:</p><p><strong>Abdominal Girth</strong>:</p><p><strong>Motions</strong>:</p><p><strong>Renal</strong>:</p><p><span>Urine output/ Fluid balance</span></p><p><br></p><p><strong>Hematologic</strong>:</p><p><strong>TSB/Phototherapy</strong>:</p><p><strong>Transfusions</strong>:</p><p><strong>Endo</strong>:</p><p><strong>GRBS</strong>:</p><p><strong>Lines</strong>:</p><p><strong>PIV</strong>:</p><p><strong>Extravasation/inflammation</strong>:</p>",
  ],
  [
    "secondaryTemplateProgressNICU",
    "<p><strong><u>Diagnosis</u></strong>:</p><ol><li><br></li><li><br></li><li><br></li></ol>",
  ],
  [
    "tertiaryTemplateProgressNICU",
    "<p><strong><u>Plan of treatment</strong></u>:</p><ol><li><br></li><li><br></li></ol>",
  ],
  [
    "medicationTemplateProgressNICU",
    "<p><strong><u>Active medications</u></strong>:</p><ol><li><br></li><li><br></li></ol>",
  ],
  [
    "reportTemplateProgressNICU",
    "<p><strong><u>Labs and investigations</u></strong>:</p><p><span>(Past 24 hours)</span></p><ol><li><br></li><li><br></li></ol>",
  ],

  // NICU nursing Template

  [
    "primaryNursingNoteTemplateNICU",
    "<p><strong><u>Basic information</u></strong>:&nbsp;<br><p><span>HOL/DOL</span>:&nbsp;</p><p><span>GA</span>:&nbsp;</p><p><span>CGA</span>:&nbsp;</p><p><span>Birth weight</span>:&nbsp;</p><p><span>Today's weight</span>:&nbsp;</p><p><br></p><p><br></p><p><strong><u>Vitals</u></strong>:&nbsp;</p><p><span>Temp</span>:&nbsp;</p><p><span>HR</span>:&nbsp;</p><p><span>RR</span>:&nbsp;</p><p><span>SpO2</span>:&nbsp;</p><p><span>BP</span>:&nbsp;</p><p><br></p><p><strong><u>Primary Diagnosis</u></strong>:&nbsp;</p>",
  ],
  [
    "diagnosisNursingNoteTemplateNICU",
    "<p><strong><u>Patient Specific Monitoring</u></strong>:&nbsp;</p><ul><li></li></ul>",
  ],
  [
    "secondaryNursingNoteTemplateNICU",
    "<p><strong><u>Systemic Assessment</u></strong>:&nbsp;<br><p><strong><u>Respiratory system </u></strong>&nbsp;</p><p><span>Airway</span>:&nbsp;</p><p><span>Secretion</span>:&nbsp;</p><p><span>Chest movement</span>:&nbsp;</p><p><span>Audible sounds</span>:&nbsp;</p><p><br></p><p><strong><u>Ventilatory support</u></strong>&nbsp;</p><p><span>Target Spo2</span>:&nbsp;</p><p><br></p><p><strong><u>Cardiovascular system</u></strong>&nbsp;</p><p><span>Pulse volume and site</span>:&nbsp;</p><p><span>CFT</span>:&nbsp;</p><p><span>Color</span>:&nbsp;</p><p><br></p><p><strong><u>CNS</u></strong>&nbsp;</p><p><span>Consciousness</span>:&nbsp;</p><p><span>Cry/Tone/activity</span>:&nbsp;</p><p><span>Anterior Fontanelle</span>:&nbsp;</p><p><span>Pupils</span>:&nbsp;</p><p><br></p><p><strong><u>Per abdomen</u></strong>&nbsp;</p><p><span>Feeds</span>:&nbsp;</p><p><span>Palpation: Soft / Distended / Tense</span>:&nbsp;</p><p><span>Abdominal sounds</span>:&nbsp;</p><p><span>AG (before feeds)</span>:&nbsp;</p><p><span>Aspirates</span>:&nbsp;</p><p><span>Last bowel open</span>:&nbsp;</p><p><br></p><p><span>(Last 24 hour)</span></p><p><span>Input</span>:&nbsp;</p><p><span>Output</span>:&nbsp;</p><p><span>Drains</span>:&nbsp;</p><p><span>Balance</span>:&nbsp;</p><p><br></p><p><strong><u>Fluids &amp; Nutrition</u></strong>&nbsp;</p><p><span>IVF</span>:&nbsp;</p><p><span>TPN</span>:&nbsp;</p><p><br></p><p><strong><u>Transfusions</u></strong>&nbsp;</p><p><br></p><p><strong><u>Lines</u> </strong>&nbsp;</p><p><span>Peripheral: Location: Day</span>:&nbsp;</p><p><span>Central: Location: Day</span>:&nbsp;</p><p><br></p><p><strong><u>Phototherapy</u></strong>&nbsp;</p><p><span>Type</span>:&nbsp;</p><p><span>Day</span>:&nbsp;</p>",
  ],
  [
    "tertiaryNursingNoteTemplateNICU",
    "<p><strong><u>To Do List </u></strong>:&nbsp;</p>",
  ],
  [
    "medicationNursingNoteTemplateNICU",
    "<p><strong><u>Medications</u></strong>:&nbsp;</p><p><span>Last 24 hours</span></p>",
  ],
  [
    "reportNursingNoteTemplateNICU",
    "<p><strong><u>Investigations</u></strong>:&nbsp;</p><p><span>Last 24 hours</span></p>",
  ],

  //Admission & Progress Template
  [
    "primaryTemplateHDUPostOp",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p><ul><li><strong>Comorbid</strong>:&nbsp;</li><li><strong>Surgery done</strong>:&nbsp;</li><li><strong>Anesthesia type</strong>:&nbsp;</li><li><strong>Blood loss documented</strong>:&nbsp;</li><li><strong>Current issues<span class='ql-cursor'>&#xFEFF;</span></strong>:&nbsp;</li></ul>",
  ],
  [
    "secondaryTemplateHDUMedical",
    "<p><strong><u>Assessment and Plan</u></strong>:&nbsp;</p><ul><li><strong>Comorbidities</strong>:&nbsp;</li><li><strong>Vitals</strong>:&nbsp;</li><li><strong>Appears comfortable</strong>:&nbsp;(Yes/No - if 'No', specify)</li><li><strong>Positive systemic examination findings reported by the bedside doctor</strong>:&nbsp;</li><li><strong>Anticipated Problems</strong>:&nbsp;</li><li><strong>Mechanical Ventilation</strong>:&nbsp;(Yes/No - if 'Yes', enter settings and weaning plan)</li><li><strong>Antibiotics</strong>:&nbsp;</li><li><strong>DVT Prophylaxis</strong>:&nbsp;(Yes/No - if 'No', enter advice)</li><li><strong>Investigation/Imaging review</strong>:&nbsp;(Specify plan for abnormal investigations)</li><li><strong>2D Echo</strong>:&nbsp;</li><li><strong>Plan</strong>:&nbsp;</li></ul>",
  ],
  [
    "secondaryTemplateHDUPostOp",
    "<p><strong><u>Assessment</u></strong>:&nbsp;</p><ul><li><strong>Vitals</strong>:&nbsp;</li><li><strong>Airway</strong>:&nbsp;</li><li><strong>Feeding</strong>:&nbsp;</li><li><strong>Analgesia/sedation</strong>:&nbsp;</li><li><strong>Prophylaxis</strong>:&nbsp;</li><li><strong>Glycemic control</strong>:&nbsp;</li><li><strong>Renal</strong>:&nbsp;</li><li><strong>ID/Lines/Tubes</strong>:&nbsp;</li><li><strong>Lab review</strong>:&nbsp;</li></ul>",
  ],
  [
    "secondaryTemplateHDUCardiac",
    "<p><strong><u>Assessment and Plan</u></strong>:&nbsp;</p><ul><li><strong>Procedure</strong>:&nbsp;</li><li><strong>Intra-procedure complications</strong>:&nbsp;</li><li><strong>Comorbidities</strong>:&nbsp;</li><li><strong>Vitals</strong>:&nbsp;</li><li><strong>Appears comfortable</strong>:&nbsp;(Yes/No - if 'No', specify)</li><li><strong>Positive systemic examination findings reported by the bedside doctor</strong>:&nbsp;</li><li><strong>Anticipated Problems</strong>:&nbsp;</li><li><strong>Mechanical Ventilation</strong>:&nbsp;(Yes/No - if 'Yes', enter settings and weaning plan)</li><li><strong>Leg Pulses reporting by bedside</strong>:&nbsp;(Yes/No - if 'Radial', specify finger/hand perfusion)</li><li><strong>DVT Prophylaxis</strong>:&nbsp;(Yes/No - if 'No', enter advice)</li><li><strong>Sheath</strong>:&nbsp;(Yes/No - if 'Yes', specify site and whether transduced or not, also mention sheath removal plan)</li><li><strong>Sheath site assessment done by the bedside for hematoma</strong>:&nbsp;(Yes/No)</li><li><strong>2D Echo</strong>:&nbsp;</li><li><strong>Plan</strong>:&nbsp;</li></ul>",
  ],
  [
    "secondaryTemplateCOVID19",
    "<p><strong><u>Assessment and Plan</u></strong>:&nbsp;</p><ul><li><strong>Comorbidities</strong>:&nbsp;</li><li><strong>Vitals</strong>:&nbsp;</li><li><strong>GCS</strong>:&nbsp;</li><li><strong>RS assessment</strong>:&nbsp;</li><li><strong>ABG</strong>:&nbsp;</li><li><strong>Xray/ECG</strong>:&nbsp;</li><li><strong>CT/Echo</strong>:&nbsp;</li><li><strong>RFT/CBC/glucose</strong>:&nbsp;</li><li><strong>LDH/D.dimer/Trop/Ferritin/CRP</strong>:&nbsp;</li><li><strong>Remdesivir/Antimicrobials</strong>:&nbsp;</li><li><strong>Steroid plan</strong>:&nbsp;</li><li><strong>Anticoagulation plan</strong>:&nbsp;</li><li><strong>Additional system findings</strong>:&nbsp;</li></ul>",
  ],

  // Nursing Procedure
  [
    "nursingAssessmentTemplate",
    "<p><strong>Neuro check</strong></p><ul><li><strong>GCS</strong>:&nbsp;</li><li><strong>Pupils</strong>:&nbsp;</li><li><strong>Antiedema measures (3% saline, mannitol)</strong>:&nbsp;</li><li><strong>Ventilator settings/oxygen needs</strong>:&nbsp;</li><li><strong>Sedation</strong>:&nbsp;</li><li><strong>SBT</strong>:&nbsp;</li></ul><p><br></p><p><strong>Eye care</strong>:&nbsp;</p><p><strong>Oral care</strong>:&nbsp;</p><p><br></p><p><strong>IV cannula/CVP line</strong></p><ul><li><strong>Date</strong>:&nbsp;</li><li><strong>Site</strong>:&nbsp;</li><li><strong>VIP</strong>:&nbsp;</li></ul><p><br></p><p><strong>Feeding</strong></p><ul><li><strong>Oral</strong>:&nbsp;</li><li><strong>NG/Tube feeds</strong>:&nbsp;</li><li><strong>NPO</strong>:&nbsp;</li><li><strong>TPN</strong>:&nbsp;</li></ul><p><br></p><p><strong>GI prophylaxis</strong></p><p><strong>Drug</strong>:&nbsp;N/A</p><p><br></p><p><strong>DVT prophylaxis</strong></p><ul><li><strong>SCD'S</strong>:&nbsp;</li><li><strong>Enoxaparin</strong>:&nbsp;</li><li><strong>Heparin</strong>:&nbsp;</li></ul><p><br></p><p><strong>GRBS</strong>:&nbsp;</p><p><br></p><p><strong>CAUTI Check</strong></p><ul><li><strong>Foleys cath Day</strong>:&nbsp;</li><li><strong>SPC/Male cath</strong>:&nbsp;</li><li><strong>Self voiding</strong>:&nbsp;</li><li><strong>Urine output (24 hrs)</strong>:&nbsp;</li></ul><p><br></p><p><strong>Bowel regimen</strong>:&nbsp;</p><p><br></p><p><strong>Position change</strong>:&nbsp;</p><p><strong>Skin assessment</strong>:&nbsp;</p><p><br></p><p><strong>Labs/radiology</strong></p><ul><li><strong>Critical values</strong>:&nbsp;</li></ul>",
  ],

  //Procedure Template
  [
    "primaryProcedureTemplate",
    "<p><strong>Procedure Performed</strong><span>:&nbsp;</span></p><p><br></p><p><strong>Site</strong><span>:&nbsp;</span></p><p><br></p><p><strong>Anaesthesia</strong><span>:&nbsp;</span></p><p><br></p><p><strong>Assistants</strong><span>:&nbsp;</span></p>",
  ],
  [
    "diagnosisProcedureTemplate",
    "<p><strong ><u>Checklist</u></strong>:&nbsp;</p><p><br></p><p><strong >Consent taken - (Y/N)</strong>:&nbsp</p><p><br></p><p><strong >Time out ( right patient/site/procedure) - (Y/N)</strong>:&nbsp</p><p><br></p><p><strong >Cleaning - (Y/N)</strong>:&nbsp</p><p><br></p><p><strong >Aseptic precautions - (Y/N)</strong>:&nbsp;</p>",
  ],
  [
    "secondaryProcedureTemplateMICU",
    "<p><strong>Procedure notes</strong><span>:&nbsp;</span></p>",
  ],
  [
    "tertiaryProcedureTemplateMICU",
    "<p><strong>Complications</strong><span>:&nbsp;</span></p><p><br></p><p><strong>Post procedure Investigations</strong><span>:&nbsp;</span></p>",
  ],

  //ICU to ward transfer

  [
    "primaryWardTransferTemplate",
    "<p><strong>ICU stay Summary ( course/procedures/surgery)</strong>:&nbsp;</p><p><br></p><p><br></p><p><br></p><p><strong>Reason for ward transfer</strong>:&nbsp;</p>",
  ],
  ["diagnosisWardTransferTemplate", "<p><strong>Diagnosis</strong>:&nbsp;"],
  [
    "secondaryWardTransferTemplateMICU",
    "<p><strong ><u>Assessment</u></strong>&nbsp;</p><p><strong >Hemodynamics </strong>:&nbsp;</p><p><br></p><p><strong >CNS</strong>:&nbsp;<span >GCS - </span></p><p><br></p><p><strong >RS</strong>:&nbsp;</p><p><br></p><p><strong >Cultures and Antibiotic plan</strong>:&nbsp;</p><p><br></p><p><strong >Other Systems (GI/Endo/heme/Onc)</strong>:&nbsp;</p><p><br></p><p><strong>Date of procedures/extubation/tracheostomy/surgeries</strong>:&nbsp;</p><p><br></p><p><strong >Devices in situ with plan (mention date of insertion) </strong>:&nbsp;</p><p><br></p><p><strong >Pressure sores </strong>:&nbsp;</p><p><br></p><p><strong >Drug chart reconciliation done (Y/N)</strong>:&nbsp;</p>",
  ],
  [
    "tertiaryWardTransferTemplateMICU",
    "<p><strong >Family/Patient Counselling</strong>:&nbsp</p><p><br></p><p><strong >Medical recommendations and follow up list</strong>:&nbsp</p><p><br></p><p><strong >Referrals</strong>:&nbsp</p><p><br></p><strong >Handover to</strong>:&nbsp</p><p><br></p><strong >Handover time</strong>:&nbsp</p><p><br></p>",
  ],

  //Tele Critical Care Review
  [
    "primaryTeleCriticalCareReviewTemplate",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p>",
  ],
  [
    "diagnosisTeleCriticalCareReviewTemplate",
    "<p><strong><u>Diagnosis</u></strong>:&nbsp;</p><ul><li></li></ul>",
  ],

  [
    "tertiaryTeleCriticalCareReviewTemplateMICU",
    "<p><strong><u>Comments and recommendations</u></strong>:&nbsp;</p>",
  ],

  // consult note
  [
    "primaryConsultNoteTemplate",
    "<p><strong >Speciality</strong>:&nbsp;</p><p><br></p><p><strong >Reason for consult</strong>:&nbsp;</p>",
  ],
  [
    "secondaryConsultNoteTemplate",
    "<p><strong><u>Assessment</u></strong></p><p><br></p><p><strong >Subjective</strong>:&nbsp;</p><p><br></p><p><strong >Objective</strong>:&nbsp;</p>",
  ],
  ["tertiaryConsultNoteTemplate", "<p><strong>Plan</strong>:&nbsp;</p>"],

  // Intensivist review note
  [
    "primaryIntensivistReviewTemplate",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p><ul><li><strong>Chief complaints</strong>:&nbsp;</li><li><strong>Current Issues</strong>:&nbsp;</li></ul>",
  ],
  [
    "secondaryIntensivistReviewTemplate",
    "<p><strong><u>Assessment</u></strong>:&nbsp;</p><ul><li><strong>CVS</strong>:&nbsp;</li><li><strong>Resp</strong>:&nbsp;</li><li><strong>Abdo</strong>:&nbsp;</li><li><strong>Renal</strong>:&nbsp;</li><li><strong>ID/Lines</strong>:&nbsp;</li><li><strong>Prophylaxis</strong>:&nbsp;</li></ul>",
  ],

  // nursing template
  [
    "secondaryNursingTemplate",
    "<p><strong>Assessment</strong>:&nbsp;</p><p><br></p><p><strong>CNS</strong>:&nbsp;</p><p><span>Change in GCS</span>:&nbsp;</p><p><span>Pupils</span>:&nbsp;</p><p><span>Neuroprotective measures</span>:&nbsp;</p><p><br></p><p><strong>CVS</strong>:&nbsp;</p><p><span>Rhythm</span>:&nbsp;</p><p><span>BP</span>:&nbsp;</p><p><span>Vasopressors</span>:&nbsp;</p><p><br></p><p><strong>Resp</strong>:&nbsp;<span> </span></p><p><span>Change in Spo2</span>:&nbsp;</p><p><span>Ventilator settings</span>:&nbsp;</p><p><span>SAT/SBT</span>:&nbsp;</p><p><br></p><p><strong>Abdomen/Nutrition</strong>:&nbsp;</p><p><br></p><p><strong>Renal(urine output/RFT review/Dialysis)</strong>:&nbsp;</p><p><br></p><p><strong>Medication review</strong><span></span>:&nbsp;</p><p><br></p><p><strong>Glycemic check</strong>:&nbsp;</p><p><br></p><p><strong>Skin assessment (bedsore care, thrombophlebitis)</strong>:&nbsp;</p><p><br></p><p><strong>Status of Invasive lines/tubes(Site,assessment/care)</strong>:&nbsp;</p><p><br></p><p><strong>Prophylaxis (GI/DVT/ Ambulation)</strong>:&nbsp;</p><p><br></p><p><strong>Investigations /Imaging review</strong>:&nbsp;</p>",
  ],
  [
    "tertiaryNursingTemplate",
    "<p><strong>To do list/follow up</strong>:&nbsp;</p><p><br></p><p><span>Pending</span>:&nbsp;</p><p><span>Planned procedures (Surgery, blood transfusion, Hemodialysis)</span>:&nbsp;</p>",
  ],

  // Disclaimer Template
  [
    "disclaimer",
    "<p><br></p><h6><em>Disclaimer: Our evaluation of the patient and the recommendations provided are based on the information transmitted to us by staff nurse/doctors over telephone/teleconference and on a camera based visual evaluation of the patient. At the time of this encounter, we were unable to assess the patient satisfactorily due to technical issues with connectivity and/or internet. For this reason, we suggest that the bedside care providers evaluate the patient personally and consider our inputs as suggestions to facilitate care rather than instructions to follow.</em></h6>",
  ],

  //Admission and progress notes
  ["medicationTemplate", "<p><strong><u>Medications</u></strong>:&nbsp;</p>"],
  [
    "reportTemplate",
    "<p><strong><u>Significant Reports</u></strong>:&nbsp;</p><p><br></p><p><br></p><p><strong><u>Pending Reports</u></strong>:&nbsp;</p>",
  ],

  // Death summary notes

  [
    "primaryDeathTemplate",
    "<p><strong><u>Date and time of death</u></strong>:&nbsp;</p>",
  ],
  [
    "diagnosisDeathTemplate",
    "<p><strong><u>Admission Diagnosis</u></strong>:&nbsp;</p>",
  ],
  [
    "secondaryDeathTemplate",
    "<p><strong><u>Procedures/Surgery done</u></strong>:&nbsp;</p>",
  ],
  [
    "tertiaryDeathTemplate",
    "<p><strong><u>Cause of death</u></strong>:&nbsp;</p><p><span>Immediate Cause</span>:&nbsp;</p><p><br></p><p><span>Antecedent Cause</span>:&nbsp;</p><p><br></p><p><span>Other significant conditions contributing</span>:&nbsp;</p><p><br></p><p><br></p>",
  ],
  [
    "reportDeathTemplate",
    "<p><strong><u>Medications given</u></strong>:&nbsp;</p>",
  ],
  [
    "medicationDeathTemplate",
    "<p><strong><u>Course of hospital stay</u></strong>:&nbsp;</p>",
  ],

  //Nutritionist note
  [
    "primaryNutritionistTemplate",
    "<p><strong><u>Summary</u></strong>:&nbsp;</p>",
  ],

  [
    "secondaryNutritionistTemplate",
    "<p><strong><u>Nutritional Care Plan</u></strong>:&nbsp;</p><p><span>Anthropometric data</span>:&nbsp;</p><p><br></p><p><span>Diet and Nutrition History</span>:&nbsp;</p><p><br></p><p><span>Nutritional Biochemistry</span>:&nbsp;</p><p><br></p><p><span>Diet plan</span>:&nbsp;</p><p><br></p><p><span>Current feed plan provides</span>:&nbsp;</p><p><br></p><p><span>Diet plan advised</span>:&nbsp;</p><p><br></p><p><span>Monitor</span>:&nbsp;</p><p><br></p>",
  ],

  //Nursing note
  [
    "diagnosisNursingShortNoteTemplate",
    "<p><strong><u>Diagnosis</u></strong>:&nbsp;</p><ul><li></li></ul>",
  ],
  [
    "secondaryNursingShortNoteTemplate",
    "<p><strong ><u>Diagnosis specific assessment</u></strong>:&nbsp;</p><p><br></p><p><br></p><p><strong ><u>Change in Vitals/Vasopressor requirement/Ventilation/Critical issues</u></strong>:&nbsp;</p><p><br></p><p><br></p><p><strong ><u>FAST HUG BID-T</u></strong>:&nbsp;</p><ul><li><strong >Feeding</strong>:&nbsp;</li><li><strong >Analgesia and Sedation</strong>:&nbsp;</li><li><strong >Thromboprophylaxis</strong>:&nbsp;</li><li><strong >Head end elevation</strong>:&nbsp;</li><li><strong >Ulcer prophylaxis</strong>:&nbsp;</li><li><strong >Glycemic control (GRBS and intervention if any)</strong>:&nbsp;</li><li><strong >Bowel opened (Yes/No)</strong>:&nbsp;</li><li><strong >Bladder Pattern (Urine output)</strong>:&nbsp;</li><li><strong >Indwelling catheter/Lines</strong>:&nbsp;</li><li><strong >Medication Reconciliation (Done/not done)</strong>:&nbsp;</li><li><strong >Temperature</strong>:&nbsp;</li></ul><p><br></p><p><strong ><u>Skin assessment</u></strong>:&nbsp;<ul><li><strong >Pressure sore assessment</strong>:&nbsp;</ul>",
  ],
  [
    "tertiaryNursingShortNoteTemplate",
    "<p><strong><u>To-Do List</u></strong>:&nbsp;</p><ul><li><br></li></ul>",
  ],

  //NICU Discharge note

  [
    "primaryNICUDischargeTemplate",
    "<p><strong><u>Patient details</u></strong>:&nbsp;</p><br><p><strong>Date &amp; Time of Birth</strong>:&nbsp;</p><p><strong>Date &amp; Time of Admission</strong>:&nbsp;</p><p><strong>Date &amp; Time of Discharge</strong>:&nbsp;</p><p><strong>Admission Weight</strong>:&nbsp;</p><p><strong>Discharge Weight</strong>:&nbsp;</p><p><strong>Referring Hospital</strong>:&nbsp;</p>",
  ],
  [
    "diagnosisNICUDischargeTemplate",
    "<p><strong><u>Discharge Diagnosis</u></strong>:&nbsp;</p><ul><li><br></li></ul><p><br></p>",
  ],
  [
    "secondaryNICUDischargeTemplate",
    "<p><strong><u>Course of hospital stay</u></strong>:&nbsp;</p><br><p><strong>Respiratory</strong>:&nbsp;<span>- (Down's Score, Surfactant, Ventilatory support, Days on ventilation, Supplemental oxygen, Apnea, RDS, Pul. Edema, Pul. Haemorrhage, Caffeine)</span></p><p><br></p><p><strong>Cardiovascular</strong>:&nbsp;<span>- (Diagnoses/therapies, inotropes, Echo, PDA, ECG)</span></p><p><br></p><p><strong>Fluids, Electrolytes, Nutrition</strong>:&nbsp;<span>- (Electrolyte imbalances &amp; management, Brief feeding history, TPN)</span></p><p><br></p><p><strong>GI</strong>:&nbsp;<span>- (NEC, Maximum bilirubin, feed intolerance)</span></p><p><br></p><p><strong>Hematology</strong>:&nbsp;<span>- (Patient blood type, transfusions, Anaemia, Thrombocytopenia)</span></p><p><br></p><p><strong>Infectious Disease</strong>:&nbsp;<span>- (Complete blood counts, cultures, colonization if appropriate, antibiotic courses)</span></p><p><br></p><p><strong>Neurology</strong>:&nbsp;<span>- (Seizures, IVH and management)</span></p><p><br></p><p><strong>Ophthalmology</strong>:&nbsp;<span>- (ROP)</span></p><p><br></p><p><strong>ENT</strong>:&nbsp;<span>- (OAE / Bera examination)</span></p><p><br></p>",
  ],
  [
    "reportNICUDischargeTemplate",
    "<p><strong><u>Discharge Exam</u></strong>:&nbsp;</p><br><p><strong>Anthropometry</strong>:&nbsp;</p><p><br></p><p><span>Weight (g)</span>:&nbsp;</p><p><span>Length (cm)</span>:&nbsp;</p><p><span>Head circ (cm)</span>:&nbsp;</p><p><span>Ponderal index</span>:&nbsp;</p><p><br></p><p><strong>Gestational Age</strong>:&nbsp;</p><p><span>By Dates</span>:&nbsp;</p><p><span>By Ballard's</span>:&nbsp;</p><p><br></p><p><strong>General physical examination</strong>:&nbsp;</p><p><br></p><p><strong>Vitals</strong>:&nbsp;</p><p><span>Temp</span>:&nbsp;</p><p><span>HR</span>:&nbsp;</p><p><span>RR</span>:&nbsp;</p><p><span>SpO2</span>:&nbsp;</p><p><span>BP</span>:&nbsp;</p><p><br></p><p><strong>Dysmorphic features/ congenital anomalies</strong>:&nbsp;</p><p><br></p><p><span>Orifices</span>:&nbsp;</p><p><span>Skin</span>:&nbsp;</p><p><span>Head</span>:&nbsp;</p><p><span>AF</span>:&nbsp;</p><p><span>Eyes</span>:&nbsp;</p><p><span>Ears</span>:&nbsp;</p><p><span>Nose</span>:&nbsp;</p><p><span>Oral Cavity</span>:&nbsp;</p><p><span>Lungs &amp; Chest</span>:&nbsp;</p><p><span>Cardiac</span>:&nbsp;</p><p><span>Pulses</span>:&nbsp;</p><p><span>Abdomen &amp; Cord</span>:&nbsp;</p><p><span>Genitalia</span>:&nbsp;</p><p><span>Back &amp; Spine</span>:&nbsp;</p><p><span>Limbs &amp; Hips</span>:&nbsp;</p><p><span>Neuro</span>:&nbsp;</p><p><br></p><p><strong>Condition at discharge</strong>:&nbsp;</p><p><br></p><p><strong>Prognosis</strong>:&nbsp;</p><p><br></p>",
  ],
  [
    "tertiaryNICUDischargeTemplate",
    "<p><strong><u>Discharge advice</u></strong>:&nbsp;</p><br><p><strong>Discharge medications</strong>:&nbsp;</p><ul><li><br></li></ul><p><br></p><p><strong>Special Instructions to family</strong>:&nbsp;</p>",
  ],
]);
