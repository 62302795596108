import { EntityState } from "@ngrx/entity";
import { TrendType } from "../../constants/trends.data";
import { GrowthTrendsConfig } from "../../models/growth-trends.model";

export interface GrowthTrendsReducerInterface
  extends EntityState<GrowthTrendsConfig> {}

export interface TrendCommonReducerInterface {
  trendType: TrendType;
}

export interface TrendsActionReducerMapInterface {
  trendsCommon: TrendCommonReducerInterface;
  growthTrends: GrowthTrendsReducerInterface;
}
