import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "../auth.guard";
import { RalertMessageListResolverService } from "./services/ralert-message-list-resolver.service";
import { RalertDeviceListPageComponent } from "./containers/ralert-device-list-page/ralert-device-list-page.component";
import { RalertSocketResolver } from "../resolvers/socket/ralert-socket.resolver";
import { RalertMessageListPageComponent } from "./containers/ralert-message-list-page/ralert-message-list-page.component";

const routes: Routes = [
  {
    path: "r-alert",
    component: RalertMessageListPageComponent,
    canActivateChild: [AuthGuard],
    resolve: {
      messages: RalertMessageListResolverService,
      socket: RalertSocketResolver,
    },
  },
  {
    path: "hospital-admin/ralert",
    component: RalertDeviceListPageComponent,
    canActivateChild: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RalertRoutingModule {}
