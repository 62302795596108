<app-loader *ngIf="ncpFetchLoader$ | async"></app-loader>
<section class="vital-view-section">
  <ng-container *ngIf="(displayedRows$ | async)?.length > 0">
    <mat-accordion displayMode="flat" class="mat-table">
      <section class="mat-elevation-z2 mat-header-row header-text">
        <span class="mat-header-cell">Author name</span>
        <span class="mat-header-cell">Date created</span>
        <span class="mat-header-cell">List edited</span>
      </section>
      <ng-container *ngIf="displayedRows$ | async as displayedRows">
        <mat-expansion-panel
          class="trigger-panel"
          *ngFor="let ncp of displayedRows; let i = index"
          [expanded]="expandedPanelIndex === i"
          (opened)="expandedPanelIndex = i"
        >
          <mat-expansion-panel-header
            class="mat-row"
            [ngClass]="{ 'expanded-header': expandedPanelIndex === i }"
          >
            <span class="mat-cell">{{ ncp.createdBy }}</span>
            <span class="mat-cell">{{
              ncp.createdAt | date: "dd-MM-yyyy"
            }}</span>
            <span class="mat-cell">{{
              ncp.updatedAt | date: "dd-MM-yyyy"
            }}</span>
          </mat-expansion-panel-header>
          <div class="accordion-expansion-container iris-form">
            <div class="iris-v-row">
              <div class="row-left"></div>
              <div class="row-right">
                <div class="iris-form-row float">
                  <span>
                    <mat-icon class="print-icon cp-cursor-pointer mr"
                      >print</mat-icon
                    ></span
                  >
                  <ng-container *ngxPermissionsOnly="['create_vital']">
                    <span
                      *ngIf="ncp.createdBy === (getUser$ | async).name"
                      class="cp-cursor-pointer mr"
                      (click)="edit(ncp.plans)"
                    >
                      <img src="assets/icons/edit.svg"
                    /></span>
                  </ng-container>
                </div>
              </div>
            </div>
            <mat-divider class="divider mb"></mat-divider>
            <div *ngFor="let plan of ncp.plans" class="params-body">
              <div class="iris-v-row">
                <div class="row-left">
                  <div class="iris-form-row diagnose-text-style mb">
                    {{ plan.diagnose }}
                  </div>
                </div>
              </div>

              <ng-container *ngFor="let param of plan.fields | keyvalue">
                <div class="iris-v-row param-text-style">
                  <div class="row-left">
                    <div class="iris-form-row">{{ param.key }}:</div>
                  </div>
                  <div class="row-right">
                    <div class="iris-form-row">
                      {{ param.value }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
  <!-- <ng-container *ngIf="totalRows > 14">
    <mat-paginator
      #paginator
      [length]="totalRows"
      [pageIndex]="0"
      [pageSize]="14"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </ng-container> -->
  <!--   [length]="totalRows$ | async" -->
</section>
