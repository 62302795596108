import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from "@angular/core";
import {
  GRAPH_MARGINS_NEW_CHART,
  TRENDS_DEFAULT_HEIGHT,
  TRENDS_DEFAULT_WIDTH,
} from "../../constants/new-trends.data";
import * as d3 from "d3";
import { TrendType } from "../../constants/trends.data";
import { XAxisConfig, YAxisConfig } from "../../models";
import { GrowthDataPoints } from "../../models/growth-trends.model";
import { Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { takeUntil } from "rxjs/operators";
import { Patient } from "src/app/models/patient";
import { getGraphHeightWidth } from "../../support";
import { GrowthChartSupportService } from "../../services/growth-chart-support.service";

@Component({
  selector: "app-trends-base",
  templateUrl: "./trends-base.component.html",
  styleUrls: ["./trends-base.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TrendsBaseComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject();

  protected patientHeader$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  protected patientInfo: Patient;
  public trendType;
  public margin;
  public width: number;
  public height: number;
  public graphWidth: number;
  public graphHeight: number;

  protected _gcs = inject(GrowthChartSupportService);
  constructor(protected _store: Store<any>) {
    /* Get Patient info */
    this.patientHeader$.subscribe((patient) => {
      this.patientInfo = JSON.parse(JSON.stringify(patient));
      this.patientInfo["birthStatus"] = this._gcs.getBirthStatus(
        this.patientInfo
      );
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setGraphHeightWidht(trendType: TrendType = null) {
    [this.height, this.width] = getGraphHeightWidth(trendType);
    this.margin = GRAPH_MARGINS_NEW_CHART;

    this.graphWidth = this.width - this.margin.right - this.margin.left;
    this.graphHeight = this.height - this.margin.top - this.margin.bottom;
  }

  clearAllSVGElements() {
    return d3.selectAll("svg").remove();
  }

  attachSVGElement(nativeEle) {
    return d3
      .select(nativeEle)
      .append("svg")
      .attr("class", nativeEle.id)
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("class", "plot-area")
      .attr("width", this.graphWidth)
      .attr("height", this.graphHeight)
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
  }

  tooltip: any;
  makeTooltip(nativeEle) {
    this.tooltip = d3
      .select(nativeEle)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("background-color", "white")
      .style("box-shadow", "rgb(0 0 0 / 20%) 0px 0px 5px")
      .style("padding", "12px 16px")
      .style("border-radius", "5px");
  }

  x: any;
  attachXAxis(svgRef, xAxisConfig: XAxisConfig) {
    const xDomain = this._gcs.makeXDomainData(this.trendType, xAxisConfig);
    switch (this.trendType) {
      case TrendType.growthChart:
        this.x = d3.scaleLinear().domain(xDomain).range([0, this.graphWidth]);

        if (!xAxisConfig?.niceDisable) this.x.nice();

        svgRef
          .append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + this.graphHeight + ")")
          .call(
            d3
              .axisBottom(this.x)
              .tickValues(xAxisConfig?.tickValues)
              .tickFormat(d3.format("d"))
              .tickPadding(15)
              .tickSize(-this.graphHeight)
          )
          .call((g) => g.select(".domain").remove());
        break;
      default:
        break;
    }

    if (!xAxisConfig.labelName) return;

    svgRef
      .append("text")
      .attr("class", "axis-text x-axis-text")
      .attr("text-anchor", "middle")
      .attr("x", this.graphWidth / 2)
      .attr("y", this.graphHeight + this.margin.top + 20)
      .text(xAxisConfig.labelName);
  }

  y: any;
  attachYAxis(svgRef, YAxisConfig: YAxisConfig, yDataPoints: number[]) {
    const yDomain = this._gcs.makeYDomainData(
      this.trendType,
      YAxisConfig,
      yDataPoints
    );

    this.y = d3.scaleLinear().range([this.graphHeight, 0]);
    this.y.domain(yDomain).nice();

    switch (this.trendType) {
      case TrendType.growthChart:
        svgRef
          .append("g")
          .attr("class", "axis axis--y")
          .call(
            d3
              .axisLeft(this.y)
              .tickFormat(d3.format("d"))
              .tickPadding(15)
              .tickSize(-this.graphWidth)
          )
          .call((g) => g.select(".domain").remove());
        break;
      default:
        break;
    }

    if (!YAxisConfig?.labelName) return;

    svgRef
      .append("text")
      .attr("class", "axis-text y-axis-text")
      .attr("text-anchor", "middle")
      .attr("y", 15 - this.margin.left)
      .attr("x", 0 - this.graphHeight / 2)
      .attr("dy", "1em")
      .attr("transform", "rotate(-90)")
      .text(YAxisConfig?.labelName);
  }

  scatterDots(svgRef, graphData) {
    svgRef
      .append("g")
      .attr("class", "scatter-dots cursor-pointer")
      .selectAll("dot")
      .data(graphData)
      .enter()
      .append("circle")
      .attr("cx", (d: any) => this.x(d.xValue))
      .attr("cy", (d: any) => this.y(d.yValue))
      .attr("r", 3)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", (d) => this.fillScatterDotColor(d))
      .style("stroke", (d) => this.fillScatterDotColor(d))
      .style("stroke-width", "0")
      .on("mouseover", this.showPopover.bind(this))
      .on("mouseleave", this.hidePopover.bind(this));
  }

  fillScatterDotColor(dataPoint: GrowthDataPoints) {
    return dataPoint?.pointColor ?? "#34A2B1";
  }

  line: any;
  drawLineAndPath(svgRef, graphData) {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.xValue))
      .y((d: any) => this.y(d.yValue));

    // Configuring line path
    svgRef
      .append("path")
      .datum(graphData)
      .attr("class", "line")
      .attr("d", this.line)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "none")
      .style("stroke", "#34A2B1")
      .style("stroke-width", "2");
  }

  showPopover(mouseEvent, data) {
    this.enlargeCircle(mouseEvent, data);
    let htmlData = this._gcs.makeHTMLDataForPopover(
      this.trendType,
      data,
      this.patientInfo
    );
    this.translatePopup(htmlData, mouseEvent);
  }

  enlargeCircle(circleEle, data) {
    d3.select(circleEle.target)
      .style("stroke-width", "9")
      .style("stroke-opacity", "0.3")
      .transition()
      .duration(200);
  }

  resetEnlargeScatterDot(circleEle) {
    d3.select(circleEle?.target)
      .style("stroke-width", "0")
      .transition()
      .duration(200);
  }

  hidePopover(mouseEvent, data) {
    this.tooltip.transition().duration(100).style("opacity", "0");
    this.resetEnlargeScatterDot(mouseEvent);
  }

  translatePopup(htmlData, event) {
    this.tooltip
      .html(htmlData)
      .style("left", event.pageX - 45 + "px")
      .style("top", event.pageY - 90 + "px")
      .transition()
      .duration(100) // ms
      .style("opacity", "1");
  }

  makeMaxMinText(svgRef, dataPoints: GrowthDataPoints[]) {
    if (!dataPoints || dataPoints?.length < 2) return;

    const [maxDataObj, minDataObj] = this._gcs.tranformMaxMinData(dataPoints);

    const maxMinText = svgRef.append("g").attr("class", "max-min-text");

    let yMaxPoint = this.y(maxDataObj.yValue);
    yMaxPoint = this._gcs.alignElements(
      "y-axis",
      yMaxPoint,
      15,
      15,
      this.graphWidth,
      this.graphHeight,
      10
    );
    let xMaxPoint = this.x(maxDataObj.xValue);
    xMaxPoint = this._gcs.alignElements(
      "x-axis",
      xMaxPoint,
      25,
      25,
      this.graphWidth,
      this.graphHeight
    );
    maxMinText
      .append("text")
      .attr("class", "max-text")
      .attr("x", xMaxPoint)
      .attr("y", yMaxPoint)
      .attr("dy", "0rem")
      .style("text-anchor", "middle")
      .text(`Max: ${maxDataObj.yValue}`);

    if (!minDataObj) return;

    // y coord of minText should be below the text
    let yMinPoint = this.y(minDataObj.yValue) + 30;
    yMinPoint = this._gcs.alignElements(
      "y-axis",
      yMinPoint,
      0,
      30,
      this.graphWidth,
      this.graphHeight,
      10
    );
    let xMinPoint = this.x(minDataObj.xValue);
    xMinPoint = this._gcs.alignElements(
      "x-axis",
      xMinPoint,
      25,
      25,
      this.graphWidth,
      this.graphHeight
    );
    maxMinText
      .append("text")
      .attr("class", "min-text")
      .attr("x", xMinPoint)
      .attr("y", yMinPoint)
      .attr("dy", "0rem")
      .style("text-anchor", "middle")
      .text(`Min: ${minDataObj.yValue}`);
  }

  plotALine(svgRef, graphName) {
    const lineFunc = d3
      .line()
      .x((d: any) => this.x(d.xValue))
      .y((d: any) => this.y(d.yValue));

    const linecoo = this._gcs.makeGraphDefaultLines(
      this.trendType,
      graphName,
      this.patientInfo
    );

    const lineColor = this._gcs.makeGraphDefaultLineColor(
      this.trendType,
      this.patientInfo
    );
    const lineLabelsConfig = this._gcs.growthChartLineLabelsConfig(
      this.trendType,
      this.patientInfo
    );
    if (!linecoo?.length) return;

    for (const [index, lineCoord] of linecoo.entries()) {
      const labelYTextPoint = this._gcs.alignElements(
        "y-axis",
        this.y(lineCoord.slice(lineCoord?.length - 1)[0]["yValue"]),
        15,
        10,
        this.graphWidth,
        this.graphHeight
      );

      const lineRef = svgRef.append("g").attr("class", "line");

      lineRef
        .append("path")
        .datum(lineCoord)
        .attr("d", lineFunc)
        .attr("transform", "translate(" + 0 + "," + 0 + ")")
        .style("fill", "none")
        .style("stroke", lineColor ? lineColor[`line${index + 1}`] : "#B5D2FE")
        .style("stroke-width", "2");

      const currentLineLabelConfig = lineLabelsConfig[`line${index + 1}`];
      const pSex =
        this.patientInfo?.sex === "O"
          ? this.patientInfo?.genderForGrowthChart
          : this.patientInfo?.sex;
      if (
        currentLineLabelConfig &&
        currentLineLabelConfig["labelName"] &&
        !currentLineLabelConfig["isHideFor"]?.includes(
          `${this.patientInfo?.birthStatus}-${pSex}-${graphName}`
        )
      ) {
        lineRef
          .append("text")
          .attr("class", "line-labels")
          .attr(
            "x",
            this.graphWidth + (currentLineLabelConfig["displacementX"] || 0)
          )
          .attr(
            "y",
            labelYTextPoint + (currentLineLabelConfig["displacementY"] || 0)
          )
          .attr(
            "transform",
            `rotate(${currentLineLabelConfig["transformAngle"]}, ${
              this.graphWidth + currentLineLabelConfig["transformX"]
            }, ${labelYTextPoint + currentLineLabelConfig["transformY"]})`
          )
          .text(currentLineLabelConfig["labelName"]);
      }
    }

    if (this.trendType === TrendType.growthChart) {
      // plotting REFERENCE PERCENTILE TEXT
      const yAxisRefLabel = this._gcs.trendYaxisReferenceLabel(
        this.trendType,
        this.patientInfo
      );
      const refLabelPositionVertical = this._gcs.getTrendReferenceLabelCoords(
        this.trendType,
        this.patientInfo?.birthStatus,
        linecoo
      );
      const refLabelDisplacements =
        this._gcs.getTrendReferenceLabelCoordsDisplacements(
          this.trendType,
          this.patientInfo?.birthStatus
        );
      const alignedRefVerticalCoord = refLabelPositionVertical
        ? -this.y(refLabelPositionVertical) + refLabelDisplacements
        : -20 - this.graphHeight / 2;
      svgRef
        .append("text")
        .attr("class", "normal-range-text")
        .attr("y", this.graphWidth + 50)
        .attr("x", alignedRefVerticalCoord)
        .attr("transform", `rotate(-90)`)
        .text(yAxisRefLabel);
    }
  }
}
