import { Component, Input, OnInit } from "@angular/core";
import { NcpList, UpdateNcp } from "../../type/ncp-data.interface";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as fromNcpSelector from "../../store/selector/ncp.selector";
import * as fromNcpAction from "../../store/action/ncp.action";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-ncp-input-form",
  templateUrl: "./ncp-input-form.component.html",
  styleUrls: ["./ncp-input-form.component.scss"],
})
export class NcpInputFormComponent implements OnInit {
  @Input() currentPatient = null;
  isEdit: boolean = false;
  ngDestroyed$ = new Subject();
  public loader: boolean = false;
  @Input()
  set _data(data: NcpList) {
    this.buildForm();
  }

  get ncpCreateSuccess$() {
    return this.store.pipe(select(fromNcpSelector.getNcpCreateSuccessData));
  }

  public data: NcpList[];
  private unsubscribe$: Subject<any> = new Subject<any>();
  get data$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpListData),
      takeUntil(this.unsubscribe$)
    );
  }

  get isEdit$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpIsEDit),
      takeUntil(this.unsubscribe$)
    );
  }
  // public patientHeader$ = this.store.pipe(
  //   select(fromPatientHeaderReducers.getPatHeaderData)
  // );
  form: FormGroup;

  date = this.fb.group({
    ncpDateAndTime: new UntypedFormControl("", [Validators.required]),
  });

  constructor(
    public fb: FormBuilder,
    private store: Store<any>,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    // this.patientHeader$.pipe(takeUntil(this.ngDestroyed$)).subscribe((user) => {
    //   this.currentUser = user.CPMRN;
    // });
    this.buildForm();
  }
  buildForm() {
    this.data$.subscribe((data) => {
      this.data = data;
    });

    this.form = this.fb.group({
      entries: this.fb.array([]),
    });

    const entriesArray = this.form.get("entries") as FormArray;

    this.data.forEach((entry) => {
      const entryGroup = this.fb.group({
        _id: [entry?._id],
        createdAt: [entry.createdAt],
        diagnose: [entry.diagnose],
        fields: this.fb.group({}),
      });

      const fieldsGroup = entryGroup.get("fields") as FormGroup;

      let transformedArray = entry.fields;
      const outputArray = Object.entries(transformedArray).map(
        ([key, value]) => ({ [key]: value })
      );

      outputArray.forEach((params) => {
        let key = Object.keys(params);
        let value = Object.values(params);

        //key.forEach((element, i) => {

        fieldsGroup.addControl(key[0], new FormControl(value[0]));
        //  });
      });

      entriesArray.push(entryGroup);
    });
  }

  clearAll() {
    this.form.get("entries")["controls"].forEach((element) => {
      element.get("fields").reset();
    });
  }

  onSubmit() {
    //converting date to UTC format @sheethalkhan  08-07-2023
    const dateAndTime = this.datePipe.transform(
      this.date.get("ncpDateAndTime").value,
      "yyyy-MM-ddTHH:mm:ss.SSS'Z'",
      "UTC"
    );

    const entriesArray = this.form.get("entries") as FormArray;
    entriesArray.controls.forEach((entry) => {
      entry.get("createdAt").setValue(dateAndTime);
    });

    this.loader = true;

    this.isEdit$.subscribe((data) => {
      this.isEdit = data;
    });
    if (this.isEdit) {
      const updatePayload = this.form.controls.entries.value.map((item) => ({
        planId: item._id,
        fields: item.fields,
      }));

      this.store.dispatch(
        fromNcpAction.updateNcp({
          data: updatePayload,
          cpmrn: this.currentPatient.CPMRN,
          encounter: this.currentPatient.encounters,
        })
      );
    } else {
      const formArray = this.form.controls.entries.value.map((item) => {
        const { _id, ...rest } = item;
        return rest;
      });

      this.store.dispatch(
        fromNcpAction.createNcp({
          data: formArray,
          cpmrn: this.currentPatient.CPMRN,
          encounter: this.currentPatient.encounters,
        })
      );
    }

    this.ncpCreateSuccess$
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((data) => {
        this.loader = false;
        if (data) {
          this.store.dispatch(
            fromNcpAction.fetchNcpData({
              cpmrn: this.currentPatient.CPMRN,
              encounter: this.currentPatient.encounters,
            })
          );

          this.store.dispatch(fromNcpAction.resetState());
        }
      });
  }
}
