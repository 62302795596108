// import { Socket } from 'ngx-socket-io';
import { select, Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _moment from "moment-timezone";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from "moment-timezone";

import * as fromRAlert from "../../reducers";
import * as fromUser from "../../../store/reducers/user";
import { ClockService } from "../../../shared/clock/clock.service";
import { AudioService } from "../../../shared/audio/audio.service";
import {
  RAlertMessageListPageActions,
  RAlertSocketActions,
} from "../../actions";
import { RAlertMessage } from "../../../models/RAlert/message.model";
import { User } from "src/app/models/user";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { socket, SocketService } from "../../../services/socket.service";

const moment = _rollupMoment || _moment;
const alertTime = 300000;

@Component({
  selector: "app-ralert-message-list-page",
  templateUrl: "./ralert-message-list-page.component.html",
  styleUrls: ["./ralert-message-list-page.component.scss"],
})
export class RalertMessageListPageComponent implements OnInit, OnDestroy {
  /**
   * @description To unsubscribe the observable
   * @type {Observable}
   */
  private unsubscribe$ = new Subject();

  public messages$ = this._store.pipe(
    select(fromRAlert.getRalertMessages),
    takeUntil(this.unsubscribe$)
  );
  private _hospitals = [];
  private _units = [];
  private user: User | null = null;

  constructor(
    private _store: Store<any>,
    private _route: ActivatedRoute,
    private _audioService: AudioService,
    private _clockService: ClockService,
    private _socketService: SocketService
  ) {}

  ngOnInit() {
    this._store
      .pipe(select(fromUser.getUserHospitals), takeUntil(this.unsubscribe$))
      .subscribe((hospitals) => {
        this._hospitals = hospitals;
      });

    this._store
      .pipe(select(fromUser.getUserUnits), takeUntil(this.unsubscribe$))
      .subscribe((units) => {
        this._units = units;
      });

    this._store
      .pipe(select(fromUser.getCurrUser), takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.user = user;
      });

    socket.on("ralert:message", (message) => {
      if (
        this.user.isCommandCenterUser &&
        message.commandCenterID === this.user.commandCenterID
      ) {
        this.showRalertMessage(message);
      } else if (
        this._hospitals.indexOf(message.hospitalName) !== -1 &&
        this._units.indexOf(message.unitName) !== -1
      ) {
        this.showRalertMessage(message);
      }
    });

    socket.on("ralert:message:acknowledge", (id) => {
      this._store
        .pipe(select(fromRAlert.selectMessageById, id), take(1))
        .subscribe((message) => {
          if (!message) {
            return;
          }

          this._store.dispatch(
            RAlertSocketActions.acknowledgeRAlertMessage({ id })
          );

          if (message.alertTimeoutId) {
            clearTimeout(message.alertTimeoutId);
          }

          this._audioService.stopRalertAlertAudio(message.message);
        });
    });
  }

  showRalertMessage(message: RAlertMessage): void {
    const currentTime = moment.tz("Asia/Kolkata");
    const momentTimestamp = moment.tz(message.timestamp, "Asia/Kolkata");

    const timeDiff = currentTime.diff(momentTimestamp);
    let id;

    if (timeDiff < alertTime) {
      id = setTimeout(() => {
        this._audioService.playRalertAlertAudio(message.message);
      }, alertTime - timeDiff);
    }

    this._store.dispatch(
      RAlertSocketActions.addRAlertMessage({
        message: { ...message, momentTimestamp, alertTimeoutId: id },
      })
    );

    this._playAudio(message.message);
  }

  ngOnDestroy() {
    this._socketService.removeAllListeners("ralert:message");
    this._socketService.removeAllListeners("ralert:message:acknowledge");

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onAcknowledge(message: RAlertMessage) {
    const timestamp = this._clockService.getCurrentLocalTime();

    if (message.alertTimeoutId) {
      clearTimeout(message.alertTimeoutId);
    }

    this._audioService.stopRalertAlertAudio(message.message);

    this._store.dispatch(
      RAlertMessageListPageActions.clickAcknowledgeMessageBtn({
        id: message._id,
        data: { timestamp },
      })
    );
  }

  private _playAudio(message: string) {
    this._audioService.playRalertAlertAudio(message);
  }
}
