import { Input, OnInit, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { Store, select } from "@ngrx/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs/Observable";
import { of } from "rxjs/observable/of";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import * as fromNcpSelector from "../../store/selector/ncp.selector";
import * as fromNcpAction from "../../store/action/ncp.action";
import { NcpData, NcpList, NcpResponse } from "../../type/ncp-data.interface";
import moment from "moment-timezone";
import * as fromUserReducer from "src/app/store/reducers/user";

@Component({
  selector: "app-ncp-view",
  templateUrl: "./ncp-view.component.html",
  styleUrls: ["./ncp-view.component.scss"],
})
export class NcpViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private unsubscribe$: Subject<void> = new Subject<void>();
  ncpFetchData$: Observable<NcpResponse[]>;
  getUser$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  public ncpFetchLoader$ = this.store.pipe(
    select(fromNcpSelector.getNcpFetchLoader)
  );
  displayedRows$: Observable<any>;

  expandedPanelIndex: number;
  constructor(private store: Store<any>) {}

  ngOnInit() {
    this.ncpFetchData$ = this.store.pipe(
      select(fromNcpSelector.getNcpFetchData)
    );

    // this.displayedRows$ = this.ncpFetchData$.pipe(
    //   filter((data) => !!data && data.length > 0),
    //   map((data) => data.slice(0, 14)),
    //   takeUntil(this.unsubscribe$)
    // );
    this.ncpFetchData$.subscribe((data) => {
      if (data) {
        this.displayedRows$ = of(this.sort(data));
        this.displayedRows$.subscribe((data) => {
          data.forEach((element) => {
            element.plans.forEach((element) => {});
          });
        });
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // onPageChange(event: PageEvent) {
  //   const startIndex = event.pageIndex * event.pageSize;
  //   const endIndex = startIndex + event.pageSize;
  //   this.displayedRows$ = this.ncpFetchData$.pipe(
  //     filter((data) => !!data && data.length > 0),
  //     map((data) => data.slice(startIndex, endIndex)),
  //     takeUntil(this.unsubscribe$),
  //     tap(() => {
  //       this.expandedPanelIndex = null; // Reset expandedPanelIndex when changing pages
  //     })
  //   );
  // }

  edit(ncp: NcpList[]) {
    this.store.dispatch(
      fromNcpAction.addInputData({
        data: ncp,
        isEdit: true,
      })
    );
  }

  sort(data: NcpResponse[]) {
    return data[0].nursingPlan.reduce((result, nursingPlan) => {
      const foundIndex = result.findIndex(
        (item) =>
          item.createdBy === nursingPlan.createdBy &&
          moment(item.createdAt).format("YYYY-MM-DD") ==
            moment(nursingPlan.createdAt).format("YYYY-MM-DD")
      );

      if (foundIndex !== -1) {
        result[foundIndex].plans.push(nursingPlan);
      } else {
        result.push({
          createdBy: nursingPlan.createdBy,
          createdAt: nursingPlan.createdAt,
          updatedAt: nursingPlan.updatedAt,
          plans: [nursingPlan],
        });
      }
      return result;
    }, []);
  }
}
