<section class="iris-modal-container">
  <div class="cp-grid-row cp-grid-row--no-margin">
    <div class="col-1-of-2 title">Create/Edit plan</div>
    <div class="col-1-of-2">
      <mat-icon class="cp-cursor-pointer" (click)="onClose()">close</mat-icon>
    </div>
  </div>
  <form [formGroup]="ncpForm" class="iris-form">
    <div formGroupName="diagnose">
      <div class="iris-form-row">
        <label>Select Diagnosis:</label>
        <!-- [_matChipListSelectedList]="
            selectedNcpList.length > 0 ? selectedNcpList : []
          " -->
        <app-multiselect-chiplist
          formControlName="name"
          [selectionList]="modifiedData"
          [placeHolder]="'Select diagnoses'"
          [toolTip_length]="'40'"
          (selectionChangeValue)="getDiagnosisList($event)"
        ></app-multiselect-chiplist>
      </div>

      <div *ngIf="isOther" class="iris-form-row">
        <mat-form-field>
          <input
            formControlName="value"
            class="iris-input input-height"
            matInput
            placeholder="Enter diagnosis here"
          />
          <mat-error>Value required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="iris-form-row">
      <label>Select Parameters:</label>
      <app-multiselect-chiplist
        formControlName="parameters"
        [selectionList]="ncpParams"
        [placeHolder]="'Select Parameters'"
      ></app-multiselect-chiplist>
    </div>

    <div class="description">
      You can select the diagnoses and the parameters you want to include in the
      care plan.
    </div>

    <mat-dialog-actions align="end">
      <div *ngIf="false" class="iris-error mr">
        {{ serverError }}
      </div>
      <button
        type="button"
        class="button-secondary"
        mat-flat-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        type="submit"
        class="button-primary"
        [disabled]="!ncpForm.valid"
        mat-flat-button
        (click)="Submit()"
      >
        Create
        <app-loader [buttonLoader]="true" *ngIf="false"></app-loader>
      </button>
    </mat-dialog-actions>
  </form>
</section>
