// import { Socket } from 'ngx-socket-io';
import { Injectable } from "@angular/core";

import { RAlertDeviceStatus } from "../../models/RAlert/device.model";
import { socket } from "../../services/socket.service";

@Injectable({ providedIn: "root" })
export class RalertSocketService {
  constructor() {}

  emitAllDeviceStatusMessage(data: RAlertDeviceStatus[]) {
    socket.emit("ralert:device:status", data);
  }
}
