<div class="component-view" *ngxPermissionsOnly="['get_io']">
  <!-- Table for daily io -->
  <ng-container *ngIf="ioObj; else noInfoBlock">
    <div class="day-selector">
      <button
        matRipple
        [disabled]="ioDayIndex == 0"
        (click)="setDay(ioObj[ioDayIndex - 1], ioDayIndex - 1)"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_left</mat-icon
        >
      </button>

      <mat-form-field floatLabel="always">
        <mat-label></mat-label>
        <mat-select
          [(value)]="ioDayIndex"
          (selectionChange)="setDay(ioObj[$event?.value], $event?.value)"
        >
          <mat-option *ngFor="let days of ioObj; index as i" [value]="i">
            Day {{ days?.dayNumber }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button
        matRipple
        [disabled]="ioDayIndex == ioObj?.length - 1"
        (click)="setDay(ioObj[ioDayIndex + 1], ioDayIndex + 1)"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_right</mat-icon
        >
      </button>
      <mat-form-field floatLabel="always" class="date-selector">
        <input
          class="date-picker-input"
          [matDatepicker]="dob"
          placeholder="yyyy/mm/dd"
          [matDatepickerFilter]="dateFilter"
          (dateChange)="onClickOfDate($event?.value)"
          [value]="selectedDate"
          matInput
        />
        <mat-label></mat-label>
        <mat-datepicker-toggle matSuffix [for]="dob"> </mat-datepicker-toggle>
        <mat-datepicker #dob></mat-datepicker>
      </mat-form-field>
    </div>

    <ng-container *ngIf="ioObj.length > 0; else noInfoBlock">
      <div id="tableHolder" class="table_holder">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="t-date">
                {{
                  ioDay.dayDate | timezone: "DD-MM-YYYY":timeZoneDetail?.name
                }}
              </th>
              <th
                class="io_hrs"
                *ngFor="let hour of ioDay.hours"
                (contextmenu)="
                  onContextMenu(
                    $event,
                    getContextMenuHour(hour, ioDay.dayDate),
                    'io:hour'
                  )
                "
              >
                {{ getHourRange(hour) }}
                <cp-timezone-label
                  *ngIf="timeZoneDetail?.name"
                  [timeZoneDetail]="timeZoneDetail"
                ></cp-timezone-label>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- Feeds -->
            <tr class="intake_sub_row" (click)="showAccordian('feed')">
              <th [ngClass]="{ opened: accordianProperty.showFeed }">
                Feeds
                <fa-icon [icon]="faAngleDown"></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ inFeeds[h] == 0 ? "" : inFeeds[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showFeed">
              <tr class="intake_child_row">
                <th>Oral</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.oral
                    "
                  >
                    <div *ngIf="hour.hourTotal.intake.feeds.oral.count > 1">
                      {{ hour.hourTotal.intake.feeds.oral.val }}
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.oral.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.oral
                          "
                        >
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.oral.note
                                ? ', Note: ' + min.intake.feeds.oral.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'oral'
                                },
                                min.intake.feeds.oral
                              )
                            "
                          >
                            {{ min.intake.feeds.oral.amount }}
                            <label *ngIf="min.intake.feeds.oral.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr
                class="intake_child_row"
                *ngIf="currentPatient?.patientType == 'neonatal'"
              >
                <th>Breast-feeding</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.breastfeed
                    "
                  >
                    <div
                      *ngIf="hour.hourTotal.intake.feeds.breastfeed.count > 1"
                    >
                      {{ hour.hourTotal.intake.feeds.breastfeed.val }} min
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.breastfeed.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.breastfeed
                          "
                        >
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.breastfeed.note
                                ? ', Note: ' + min.intake.feeds.breastfeed.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'breastfeed'
                                },
                                min.intake.feeds.breastfeed
                              )
                            "
                          >
                            {{ min.intake.feeds.breastfeed.amount }} min
                            <label *ngIf="min.intake.feeds.breastfeed.note"
                              >*</label
                            >
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr class="intake_child_row">
                <th>Tube</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.tube
                    "
                  >
                    <div *ngIf="hour.hourTotal.intake.feeds.tube.count > 1">
                      {{ hour.hourTotal.intake.feeds.tube.val }}
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.tube.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.tube
                          "
                        >
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.tube.note
                                ? ', Note: ' + min.intake.feeds.tube.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'tube'
                                },
                                min.intake.feeds.tube
                              )
                            "
                          >
                            {{ min.intake.feeds.tube.amount }}
                            <label *ngIf="min.intake.feeds.tube.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr class="intake_child_row">
                <th>TPN/PN</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.tpnPn
                    "
                  >
                    <div *ngIf="hour.hourTotal.intake.feeds.tpnPn.count > 1">
                      {{ hour.hourTotal.intake.feeds.tpnPn.val }}
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.tpnPn.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.tpnPn
                          "
                        >
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.tpnPn.note
                                ? ', Note: ' + min.intake.feeds.tpnPn.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'tpnPn'
                                },
                                min.intake.feeds.tpnPn
                              )
                            "
                          >
                            {{ min.intake.feeds.tpnPn.amount }}
                            <label *ngIf="min.intake.feeds.tpnPn.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr class="intake_child_row">
                <th>Meals</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.meals
                    "
                  >
                    <div *ngIf="hour.hourTotal.intake.feeds.meals.count > 1">
                      {{ hour.hourTotal.intake.feeds.meals.val }}x
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.meals.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.meals
                          "
                        >
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.meals.note
                                ? ', Note: ' + min.intake.feeds.meals.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'meals'
                                },
                                min.intake.feeds.meals
                              )
                            "
                          >
                            {{ min.intake.feeds.meals.amount }}x
                            <label *ngIf="min.intake.feeds.meals.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
              <tr class="intake_child_row">
                <th>Others</th>
                <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                  <ng-container
                    *ngIf="
                      hour.hourTotal &&
                      hour.hourTotal.intake.feeds &&
                      hour.hourTotal.intake.feeds.others
                    "
                  >
                    <div *ngIf="hour.hourTotal.intake.feeds.others.count > 1">
                      {{ hour.hourTotal.intake.feeds.others.val }}
                    </div>

                    <div
                      *ngIf="hour.minutes.length"
                      class="to_edit"
                      [ngClass]="
                        hour.hourTotal.intake.feeds.others.count > 1
                          ? 'small'
                          : ''
                      "
                    >
                      <ng-container
                        *ngFor="let min of hour.minutes; let ind = index"
                      >
                        <ng-container
                          *ngIf="
                            min.intake &&
                            min.intake.feeds &&
                            min.intake.feeds.others
                          "
                        >
                          <fa-icon
                            [icon]="faPrescriptionBottleAlt"
                            *ngIf="min.intake.feeds.others.name"
                            title="{{ min.intake.feeds.others.name }}"
                          ></fa-icon>
                          <span
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              min.intake.feeds.others.note
                                ? ', Note: ' + min.intake.feeds.others.note
                                : ''
                            }}"
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'intake',
                                  cat: 'feeds',
                                  sub: 'others'
                                },
                                min.intake.feeds.others
                              )
                            "
                          >
                            {{ min.intake.feeds.others.amount }}
                            <label *ngIf="min.intake.feeds.others.note"
                              >*</label
                            >
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>
            </ng-container>

            <!-- Blood Products -->
            <tr class="intake_sub_row" (click)="showAccordian('blood')">
              <th [ngClass]="{ opened: accordianProperty.showBlood }">
                Blood Products
                <fa-icon [icon]="faAngleDown"></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ inBlood[h] == 0 ? "" : inBlood[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showBlood">
              <ng-container *ngFor="let bloodS of bloodStructure">
                <tr class="intake_child_row">
                  <th>{{ bloodS.name }}</th>
                  <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                    <ng-container
                      *ngIf="
                        hour?.hourTotal?.intake?.bloodProducts &&
                        hour?.hourTotal?.intake?.bloodProducts[bloodS.propName]
                      "
                    >
                      <div
                        *ngIf="
                          hour.hourTotal.intake.bloodProducts[bloodS.propName]
                            .count > 1
                        "
                      >
                        {{
                          hour.hourTotal.intake.bloodProducts[bloodS.propName]
                            .val
                        }}
                      </div>

                      <div
                        *ngIf="hour.minutes.length"
                        class="to_edit"
                        [ngClass]="
                          hour.hourTotal.intake.bloodProducts[bloodS.propName]
                            .count > 1
                            ? 'small'
                            : ''
                        "
                      >
                        <ng-container
                          *ngFor="let min of hour.minutes; let ind = index"
                        >
                          <ng-container
                            *ngIf="
                              min?.intake?.bloodProducts &&
                              min?.intake?.bloodProducts[bloodS.propName]
                            "
                          >
                            <span
                              title="Time: {{
                                getTimeStamp(hour.hourName, min.minuteName)
                                  | timezone: 'HH:mm':timeZoneDetail?.name
                              }}hrs {{
                                min.intake.bloodProducts[bloodS.propName].note
                                  ? ', Note: ' +
                                    min.intake.bloodProducts[bloodS.propName]
                                      .note
                                  : ''
                              }}"
                              (click)="
                                fillInputScreen(
                                  ioDay.dayDate,
                                  hour.hourName,
                                  min.minuteName,
                                  {
                                    process: 'intake',
                                    cat: 'bloodProducts',
                                    sub: bloodS.propName
                                  },
                                  min.intake.bloodProducts[bloodS.propName]
                                )
                              "
                            >
                              {{
                                min.intake.bloodProducts[bloodS.propName].amount
                              }}
                              <label
                                *ngIf="
                                  min.intake.bloodProducts[bloodS.propName].note
                                "
                                >*</label
                              >
                            </span>
                            <span>+</span>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <!-- Meds -->
            <tr class="intake_sub_row" (click)="showAccordian('med')">
              <th [ngClass]="{ opened: accordianProperty.showMed }">
                Meds
                <fa-icon [icon]="faAngleDown"></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ inMeds[h] == 0 ? "" : inMeds[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showMed">
              <tr class="intake_tern_row" (click)="showAccordian('infusion')">
                <th [ngClass]="{ opened: accordianProperty.showInfusion }">
                  Infusion
                  <fa-icon
                    [icon]="faAngleDown"
                    *ngIf="infusionProps.length > 0"
                  ></fa-icon>
                </th>
                <td *ngFor="let hour of ioDay.hours; index as h">
                  <span>{{ inInf[h] == 0 ? "" : inInf[h] }}</span>
                </td>
              </tr>
              <ng-container
                *ngIf="
                  infusionProps.length > 0 && accordianProperty.showInfusion
                "
              >
                <tr
                  class="intake_ternchild_row"
                  *ngFor="let inf of infusionProps"
                >
                  <th [matTooltip]="inf">
                    {{ inf.length > 40 ? (inf | slice: 0:40) + "..." : inf }}
                  </th>
                  <td *ngFor="let hour of ioDay.hours" class="to_edit">
                    <ng-container *ngFor="let min of hour.minutes; index as m">
                      <ng-container *ngIf="min?.intake?.meds?.infusion">
                        <ng-container
                          *ngFor="let infVal of min.intake.meds.infusion"
                        >
                          <ng-container *ngIf="inf == infVal.name">
                            <span
                              (click)="
                                fillInputScreen(
                                  ioDay.dayDate,
                                  hour.hourName,
                                  min.minuteName,
                                  {
                                    process: 'intake',
                                    cat: 'meds',
                                    sub: 'infusion'
                                  },
                                  infVal
                                )
                              "
                              title="Time: {{
                                getTimeStamp(hour.hourName, min.minuteName)
                                  | timezone: 'HH:mm':timeZoneDetail?.name
                              }}hrs {{
                                infVal.note ? ', Note: ' + infVal.note : ''
                              }}"
                            >
                              {{ infVal.setPlaceholder ? "--" : infVal.amount }}
                              <label *ngIf="infVal.note">*</label>
                            </span>
                            <span>+</span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>

              <tr class="intake_tern_row" (click)="showAccordian('bolus')">
                <th [ngClass]="{ opened: accordianProperty.showBolus }">
                  Bolus
                  <fa-icon
                    [icon]="faAngleDown"
                    *ngIf="bolusProps.length > 0"
                  ></fa-icon>
                </th>
                <td *ngFor="let hour of ioDay.hours; index as h">
                  <span>{{ inBol[h] == 0 ? "" : inBol[h] }}</span>
                </td>
              </tr>
              <ng-container
                *ngIf="bolusProps.length > 0 && accordianProperty.showBolus"
              >
                <tr class="intake_ternchild_row" *ngFor="let bol of bolusProps">
                  <th [matTooltip]="bol">
                    {{ bol.length > 40 ? (bol | slice: 0:40) + "..." : bol }}
                  </th>
                  <td *ngFor="let hour of ioDay.hours" class="to_edit">
                    <ng-container *ngFor="let min of hour.minutes">
                      <ng-container
                        *ngIf="
                          min.intake && min.intake.meds && min.intake.meds.bolus
                        "
                      >
                        <ng-container
                          *ngFor="let bolVal of min.intake.meds.bolus"
                        >
                          <ng-container
                            *ngIf="bol == bolVal.name"
                            class="to_edit"
                          >
                            <span
                              (click)="
                                fillInputScreen(
                                  ioDay.dayDate,
                                  hour.hourName,
                                  min.minuteName,
                                  {
                                    process: 'intake',
                                    cat: 'meds',
                                    sub: 'bolus'
                                  },
                                  bolVal
                                )
                              "
                              title="Time: {{
                                getTimeStamp(hour.hourName, min.minuteName)
                                  | timezone: 'HH:mm':timeZoneDetail?.name
                              }}hrs {{
                                bolVal.note ? ', Note: ' + bolVal.note : ''
                              }}"
                            >
                              {{ bolVal.setPlaceholder ? "--" : bolVal.amount }}
                              <label *ngIf="bolVal.note">*</label>
                            </span>
                            <span>+</span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>

            <!-- Others -->
            <tr class="intake_sub_row">
              <th>Others</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="hour.hourTotal && hour.hourTotal.intake.others"
                >
                  <div *ngIf="hour.hourTotal.intake.others.count > 1">
                    {{ hour.hourTotal.intake.others.val }}
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.intake.others.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container *ngIf="min.intake && min.intake.others">
                        <span
                          title="{{
                            min.intake.others.name
                              ? 'Title: ' + min.intake.others.name + ', '
                              : ''
                          }}  Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.intake.others.note
                              ? ', Note: ' + min.intake.others.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              { process: 'intake', cat: 'others', sub: '' },
                              min.intake.others
                            )
                          "
                        >
                          {{ min.intake.others.amount }}
                          <label *ngIf="min.intake.others.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <!-- Intake -->
            <tr class="intake_row">
              <th>
                Day {{ ioDay.dayNumber }} Total Intake: +{{
                  intakeTotal | number: "0.0-2"
                }}
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                <!-- {{ getTotal(hour.intake, 'intake') }} -->
                {{ inPerHr[h] | number: "0.0-2" }}
              </td>
            </tr>

            <!-- Urine -->
            <tr class="output_sub_row">
              <th>Urine</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="
                    hour.hourTotal &&
                    hour.hourTotal.output &&
                    hour.hourTotal.output.urine
                  "
                >
                  <div *ngIf="hour.hourTotal.output.urine.count > 1">
                    {{ hour.hourTotal.output.urine.val }}
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.output.urine.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container *ngIf="min.output && min.output.urine">
                        <span
                          title="Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.output.urine.note
                              ? ', Note: ' + min.output.urine.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              { process: 'output', cat: 'urine', sub: '' },
                              min.output.urine
                            )
                          "
                        >
                          {{ min.output.urine.amount }}
                          <label *ngIf="min.output.urine.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <!-- Drains -->
            <tr (click)="showAccordian('drain')" class="output_sub_row">
              <th [ngClass]="{ opened: accordianProperty.showDrain }">
                Drains
                <fa-icon
                  [icon]="faAngleDown"
                  *ngIf="ioProps.drain.length > 0"
                ></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ otDrain[h] == 0 ? "" : otDrain[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showDrain">
              <tr
                class="output_child_row"
                *ngFor="let drainProp of ioProps.drain"
              >
                <th>
                  {{ drainProp.name }} ( {{ drainProp.site }},
                  {{ drainProp.laterality }} )
                </th>
                <td *ngFor="let hour of ioDay.hours">
                  <div *ngIf="hour.minutes.length" class="to_edit">
                    <ng-container *ngFor="let min of hour.minutes">
                      <ng-container *ngFor="let drain of min.output.drain">
                        <ng-container
                          *ngIf="
                            drain.name == drainProp.name &&
                            drain.site == drainProp.site &&
                            drain.laterality == drainProp.laterality
                          "
                        >
                          <span
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                { process: 'output', cat: 'drain', sub: '' },
                                drain
                              )
                            "
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              drain.note ? ', Note: ' + drain.note : ''
                            }}"
                          >
                            {{ drain.amount }}
                            <label *ngIf="drain.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </ng-container>

            <!-- Procedure -->
            <tr class="output_sub_row" (click)="showAccordian('procedure')">
              <th [ngClass]="{ opened: accordianProperty.showProcedure }">
                Procedure
                <fa-icon
                  [icon]="faAngleDown"
                  *ngIf="ioProps.procedure.length > 0"
                ></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ otProcedure[h] == 0 ? "" : otProcedure[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showProcedure">
              <tr
                class="output_child_row"
                *ngFor="let procedureProp of ioProps.procedure"
              >
                <th>{{ procedureProp.name }} ( {{ procedureProp.site }} )</th>
                <td *ngFor="let hour of ioDay.hours">
                  <div *ngIf="hour.minutes.length" class="to_edit">
                    <ng-container *ngFor="let min of hour.minutes">
                      <ng-container
                        *ngFor="let procedure of min.output.procedure"
                      >
                        <ng-container
                          *ngIf="
                            procedure.name == procedureProp.name &&
                            procedure.site == procedureProp.site &&
                            procedure.laterality == procedureProp.laterality
                          "
                        >
                          <span
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                {
                                  process: 'output',
                                  cat: 'procedure',
                                  sub: ''
                                },
                                procedure
                              )
                            "
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              procedure.note ? ', Note: ' + procedure.note : ''
                            }}"
                          >
                            {{ procedure.amount }}
                            <label *ngIf="procedure.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </ng-container>

            <!-- Dialysis -->
            <tr class="output_sub_row" (click)="showAccordian('dialysis')">
              <th [ngClass]="{ opened: accordianProperty.showDialysis }">
                Dialysis
                <fa-icon
                  [icon]="faAngleDown"
                  *ngIf="ioProps.dialysis.length > 0"
                ></fa-icon>
              </th>
              <td *ngFor="let hour of ioDay.hours; index as h">
                {{ otDialysis[h] == 0 ? "" : otDialysis[h] }}
              </td>
            </tr>
            <ng-container *ngIf="accordianProperty.showDialysis">
              <tr
                class="output_child_row"
                *ngFor="let dialysisProp of ioProps.dialysis"
              >
                <th>{{ dialysisProp.name }}</th>
                <td *ngFor="let hour of ioDay.hours">
                  <div *ngIf="hour.minutes.length" class="to_edit">
                    <ng-container *ngFor="let min of hour.minutes">
                      <ng-container
                        *ngFor="let dialysis of min.output.dialysis"
                      >
                        <ng-container
                          *ngIf="
                            dialysis.name == dialysisProp.name &&
                            dialysis.site == dialysisProp.site &&
                            dialysis.laterality == dialysisProp.laterality
                          "
                        >
                          <span
                            (click)="
                              fillInputScreen(
                                ioDay.dayDate,
                                hour.hourName,
                                min.minuteName,
                                { process: 'output', cat: 'dialysis', sub: '' },
                                dialysis
                              )
                            "
                            title="Time: {{
                              getTimeStamp(hour.hourName, min.minuteName)
                                | timezone: 'HH:mm':timeZoneDetail?.name
                            }}hrs {{
                              dialysis.note ? ', Note: ' + dialysis.note : ''
                            }}"
                          >
                            {{ dialysis.amount }}
                            <label *ngIf="dialysis.note">*</label>
                          </span>
                          <span>+</span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </td>
              </tr>
            </ng-container>

            <!-- Stool -->
            <tr class="output_sub_row">
              <th>Stool</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="
                    hour.hourTotal &&
                    hour.hourTotal.output &&
                    hour.hourTotal.output.stool
                  "
                >
                  <div *ngIf="hour.hourTotal.output.stool.count > 1">
                    {{ hour.hourTotal.output.stool.val }}x
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.output.stool.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container *ngIf="min.output && min.output.stool">
                        <span
                          title="Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.output.stool.note
                              ? ', Note: ' + min.output.stool.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              { process: 'output', cat: 'stool', sub: '' },
                              min.output.stool
                            )
                          "
                        >
                          {{ min.output.stool.amount }}x
                          <label *ngIf="min.output.stool.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <!-- Emesis -->
            <tr class="output_sub_row">
              <th>Emesis</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="
                    hour.hourTotal &&
                    hour.hourTotal.output &&
                    hour.hourTotal.output.emesis
                  "
                >
                  <div *ngIf="hour.hourTotal.output.emesis.count > 1">
                    {{ hour.hourTotal.output.emesis.val }}x
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.output.emesis.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container *ngIf="min.output && min.output.emesis">
                        <span
                          title="Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.output.emesis.note
                              ? ', Note: ' + min.output.emesis.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              { process: 'output', cat: 'emesis', sub: '' },
                              min.output.emesis
                            )
                          "
                        >
                          {{ min.output.emesis.amount }}x
                          <label *ngIf="min.output.emesis.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <!-- NG Aspirate -->
            <tr class="output_sub_row">
              <th>NG Aspirate</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="
                    hour.hourTotal &&
                    hour.hourTotal.output &&
                    hour.hourTotal.output.NG_aspirate
                  "
                >
                  <div *ngIf="hour.hourTotal.output.NG_aspirate.count > 1">
                    {{ hour.hourTotal.output.NG_aspirate.val }}
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.output.NG_aspirate.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container
                        *ngIf="min.output && min.output.NG_aspirate"
                      >
                        <span
                          title="Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.output.NG_aspirate.note
                              ? ', Note: ' + min.output.NG_aspirate.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              {
                                process: 'output',
                                cat: 'NG_aspirate',
                                sub: ''
                              },
                              min.output.NG_aspirate
                            )
                          "
                        >
                          {{ min.output.NG_aspirate.amount }}
                          <label *ngIf="min.output.NG_aspirate.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <tr class="output_sub_row">
              <th>Others</th>
              <td *ngFor="let hour of ioDay.hours; index as hrIndex">
                <ng-container
                  *ngIf="
                    hour.hourTotal &&
                    hour.hourTotal.output &&
                    hour.hourTotal.output.others
                  "
                >
                  <div *ngIf="hour.hourTotal.output.others.count > 1">
                    {{ hour.hourTotal.output.others.val }}
                  </div>

                  <div
                    *ngIf="hour.minutes.length"
                    class="to_edit"
                    [ngClass]="
                      hour.hourTotal.output.others.count > 1 ? 'small' : ''
                    "
                  >
                    <ng-container
                      *ngFor="let min of hour.minutes; let ind = index"
                    >
                      <ng-container *ngIf="min.output && min.output.others">
                        <span
                          title="Time: {{
                            getTimeStamp(hour.hourName, min.minuteName)
                              | timezone: 'HH:mm':timeZoneDetail?.name
                          }}hrs {{
                            min.output.others.note
                              ? ', Note: ' + min.output.others.note
                              : ''
                          }}"
                          (click)="
                            fillInputScreen(
                              ioDay.dayDate,
                              hour.hourName,
                              min.minuteName,
                              { process: 'output', cat: 'others', sub: '' },
                              min.output.others
                            )
                          "
                        >
                          {{ min.output.others.amount }}
                          <label *ngIf="min.output.others.note">*</label>
                        </span>
                        <span>+</span>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>

            <!-- Output -->
            <tr class="output_row">
              <th>
                Day {{ ioDay.dayNumber }} Total Output:
                {{ outputTotal == 0 ? outputTotal : "-" + outputTotal }}
              </th>
              <td
                class="indicate_click"
                *ngFor="let hour of ioDay.hours; index as h"
              >
                <!-- {{ getTotal(hour.output, 'output') }} -->
                {{ outPerHr[h] | number: "0.0-2" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="net_report"
        (contextmenu)="
          onContextMenu($event, getContextMenuReport(ioReport), 'io:report')
        "
      >
        Day {{ ioDay.dayNumber }} Net:
        {{ intakeTotal - outputTotal | number: "0.0-2" }}ml
      </div>
      <div class="report_holder small">
        <div class="row py-2 display-flex align-items-center">
          <div class="form-group col-sm-4">
            <mat-form-field floatLabel="always">
              <mat-select
                (selectionChange)="changeTrigger($event.value)"
                [value]="'12'"
                disableOptionCentering
              >
                <mat-option value="" disabled>Get report for</mat-option>
                <mat-option value="6">Past 6hrs</mat-option>
                <mat-option value="12" selected>Past 12hrs</mat-option>
                <mat-option value="24">Past 24hrs</mat-option>
                <mat-option value="date_range">Custom date range</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="ioReport.showReport" class="report_head">
              <b>Since</b>
              {{
                summaryHead["date"]
                  | timezone: "DD-MM-YYYY HH:mm":timeZoneDetail?.name
              }}hrs
              <cp-timezone-label
                *ngIf="timeZoneDetail?.name"
                [timeZoneDetail]="timeZoneDetail"
              ></cp-timezone-label>
            </div>
          </div>
          <ng-container *ngIf="ioReport.showReport">
            <div class="col-sm-2 showavg" *ngIf="ioReport.showReport">
              Average urine
              <br />
              <span class="badge">{{ ioReport.avgUrine }} ml/hr</span>
            </div>
            <div class="col-sm-6">
              <div class="row ion_box">
                <div class="col-sm-4 r_highlight r_intake">
                  Total Intake
                  <br />
                  <span class="badge">+{{ ioReport.intakeReport }}ml</span>
                </div>
                <div class="col-sm-4 r_highlight r_output">
                  Total Output
                  <br />
                  <span class="badge">
                    {{
                      ioReport.outputReport == 0
                        ? ioReport.outputReport
                        : "-" + ioReport.outputReport
                    }}ml
                  </span>
                </div>
                <div class="col-sm-4 r_highlight r_output">
                  Net
                  <br />
                  <span class="badge">{{ ioReport.netReport }}ml</span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="rangedDate" [formGroup]="ioReportTimeRange">
          <div class="display-flex align-items-center report_holder-daterange">
            <span class="report_holder-daterange_label">From:</span>
            <app-date-time-picker
              class="width--100"
              [minValidDate]="matMinDate"
              [maxValidDate]="matMaxDate"
              [dateLabel]="' '"
              [timeLabel]="' '"
              [showTime]="true"
              [isPadding]="true"
              formControlName="from"
              [timeZoneDetail]="timeZoneDetail"
            ></app-date-time-picker>
          </div>
          <div
            class="display-flex align-items-center report_holder-daterange mt-2"
          >
            <span class="report_holder-daterange_label">To: </span>
            <app-date-time-picker
              class="width--100"
              [minValidDate]="matMinDate"
              [maxValidDate]="matMaxDate"
              [dateLabel]="' '"
              [timeLabel]="' '"
              [showTime]="true"
              formControlName="to"
              [isPadding]="true"
              [timeZoneDetail]="timeZoneDetail"
            ></app-date-time-picker>
          </div>
        </div>
        <button
          mat-flat-button
          class="button-primary mt"
          style="padding: 7px !important"
          (click)="getReport()"
        >
          Get Report
        </button>
        <app-io-print
          [disabled]="isReportFieldChange"
          [currentPatient]="currentPatient"
          [report]="ioReport"
          [dateRangeValue]="reportVal"
          [fromDate]="reportFromTime?.value"
          [toDate]="reportToTime?.value"
        >
        </app-io-print>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noInfoBlock>
    <div class="no_data_found_block pt-5">No data found</div>
  </ng-template>
</div>

<ng-template [ngxPermissionsExcept]="['get_io']">
  <div class="no_data_found_block pt-5">
    You are not authorized to view this page
  </div>
</ng-template>
