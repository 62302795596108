import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgxPermissionsModule } from "ngx-permissions";

import { IconModule } from "../icon/icon.module";
import { FormatTimePipe } from "./formatTimePipe/formatTime.pipe";
import { BackendErrorPipe } from "./backendError/backendError.pipe";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { ObjectToArrayPipe } from "./objectToArrayPipe/objectToArray.pipe";
import { MultiSelectComponent } from "./multi-select/multi-select.component";
import { ContextMenuCommentModalComponent } from "./context-menu/context-menu-comment-modal.component";
import { ImgCanvasComponent } from "./img-canvas/img-canvas.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IrisComponentsModule } from "../iris-components/iris-components.module";
import { RegexFilterPipe } from "./regex-filter.pipe";
import { RequestSearchInputComponent } from "./request-search-input/request-search-input.component";
import { LimitCountPipe } from "./pipes/limit-count.pipe";
import { HtmlTagRemoveRegexPipe } from "../note-module/pipe/html-tag-remove-regex.pipe";
import { SBarAlertModule } from "../shared-modules/components/sbar-alert/sbar-alert.module";

const components = [
  BackendErrorPipe,
  LimitCountPipe,
  BreadcrumbComponent,
  FormatTimePipe,
  RegexFilterPipe,
  ObjectToArrayPipe,
  ContextMenuCommentModalComponent,
  MultiSelectComponent,
  ImgCanvasComponent,
  RequestSearchInputComponent,
  HtmlTagRemoveRegexPipe,
];

const services = [NgxPermissionsModule];

@NgModule({
  declarations: components,
  exports: [...components, ...services, SBarAlertModule],
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    IrisComponentsModule,
    SBarAlertModule,
    NgxPermissionsModule.forChild(),
  ],
})
export class SharedModule {}
