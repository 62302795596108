import { Injectable } from "@angular/core";
import SocketIOClient from "socket.io-client";

import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../environments/environment";
import { AlertService } from "../iris-components/service/alert.service";
import { AuthLocalStorageService } from "../get-started/services/auth-localStorage.service";
import { SocketDisconnectComponent } from "../socket-disconnect/socket-disconnect.component";
import { AlertInline } from "../iris-components/model/iris-component.model";

export let socket = null;

enum SocketDisconnectMessage {
  IoServerDisconnect = "io server disconnect",
  IoClientDisconnect = "io client disconnect",
  PingTimeout = "ping timeout",
  TransportClose = "transport close",
  TransportError = "transport error",
}

enum SocketNamespace {
  WebPatient = "web:patient",
  SystemAdmin = "systemAdmin",
  SuperAdmin = "superAdmin",
  Tv = "tv",
  Ralert = "ralert",
}

export enum SocketRoom {
  PatientList = "pl",
  Patient = "patient",
  TvHome = "tvHome",
  TvCovid = "tvCovid",
  User = "user",
}

interface SocketConnectOptions {
  CPMRN: string | null;
  encounters: string | null;
  commandCenterId?: string | null;
}

interface SocketLeaveParams {
  room: string;
}

@Injectable({ providedIn: "root" })
export class SocketService {
  constructor(
    private _alertService: AlertService,
    private _authLocalStorageService: AuthLocalStorageService,
    private dialog: MatDialog
  ) {}

  /**
   * Connects to the socket.
   */
  connect(): void {
    if (socket) {
      return;
    }

    const userRole = this.getUserRole();
    const namespace = this.getSocketNamespace(userRole);

    socket = SocketIOClient(environment.socketUrl + namespace, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      transports: ["websocket"],
      path: environment.socketPath,
      auth: {
        token: localStorage.getItem("token"),
      },
    });

    socket.on("disconnect", (message) => {
      if (message === SocketDisconnectMessage.IoServerDisconnect) {
        this.onSocketDisconnect();
      }
    });

    socket.on("reconnect_failed", () => {
      this.onSocketDisconnect();
    });

    socket.io.on("reconnect", (attempt) => {
      console.log(attempt, "reconnect success");
      this.showReconnectMessage(
        {
          type: "Success",
          title: "Reconnected successfully",
          message: "",
        },
        2000
      );

      this.reconnectMessageShown = false;
    });

    socket.io.on("reconnect_attempt", (attempt) => {
      console.log(attempt, "reconnect attempt");
      if (!this.reconnectMessageShown) {
        this.showReconnectMessage(
          {
            type: "Info",
            title: "Session expired",
            message: "Trying to reconnect...",
          },
          15000
        );

        this.reconnectMessageShown = true;
      }
    });

    socket.io.on("reconnect_error", (error) => {
      console.log(error, "reconnect_error");
    });

    socket.io.on("reconnect_failed", () => {
      console.log("socket reconnect failed");
      this.onSocketDisconnect();
    });
  }

  /**
   * Disconnects the socket.
   */
  disconnect() {
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  }

  /**
   * Removes all socket listeners associated with the event
   *
   * @param {string|null} event - name of the socket event.
   */
  removeAllListeners(event = null): void {
    if (socket) {
      socket.removeAllListeners(event);
    }
  }

  /**
   * Fetches the user role from local storage
   *
   * @return {string|null} - Returns the user role.
   * @private
   */
  private getUserRole(): string | null {
    const user = this._authLocalStorageService.getCurrentUser();

    return user && user.role ? user.role : null;
  }

  /**
   * Figures the socket namespace based on the role.
   *
   * @param {string} role - user's assigned role
   * @return {string} Namespace associated with the role.
   * @private
   */
  private getSocketNamespace(role: string): string {
    if (role === "System Administrator") {
      return SocketNamespace.SystemAdmin;
    } else if (role === "Tv" || role === "Tv-Covid") {
      return SocketNamespace.Tv;
    } else if (role === "R-Alert") {
      return SocketNamespace.Ralert;
    } else if (role === "Super Administrator") {
      return SocketNamespace.SuperAdmin;
    }

    return SocketNamespace.WebPatient;
  }

  /**
   * @description This is to show the socket disconnection alert
   * @author Suraj Shenoy
   * @date 30th Nov 2021
   */
  onSocketDisconnect(): void {
    if (!this.isSocketMessageShown) {
      this.isSocketMessageShown = true;

      this.dialog.open(SocketDisconnectComponent, {
        autoFocus: true,
        disableClose: true,
      });
    }
  }

  /**
   * @description To register whether the reconnect message is shown or not
   */
  reconnectMessageShown: boolean = false;

  /**
   * @description To show the reconnect message
   */
  showReconnectMessage(message: AlertInline, duration: number): void {
    this._alertService.showInlineNotification(
      message,
      "center",
      "bottom",
      duration
    );
  }

  /**
   * @description This is to keep a track whether socket disconnect is shown or not
   */
  isSocketMessageShown: boolean = false;

  /**
   * Emits the socket event for joining the room.
   *
   * @param {string} room - name of the room
   * @param {SocketConnectOptions|null} params
   */
  joinRoom(room: string, params: SocketConnectOptions | null = null): void {
    if (socket) {
      socket.emit("socket-room-join", { room, params });
    }
  }

  /**
   * Emits the socket event for leaving the room
   *
   * @param {SocketLeaveParams} params
   */
  leaveRoom(params: SocketLeaveParams): void {
    if (socket) {
      socket.emit("socket-room-leave", params);
    }
  }
}
