import { createAction, props } from "@ngrx/store";
import {
  NcpData,
  NcpList,
  NcpResponse,
  UpdateNcp,
} from "../../type/ncp-data.interface";

export const addInputData = createAction(
  "[NCP create] Ncp Input",
  props<{ data: NcpList[]; isEdit: boolean }>()
);

export const createNcp = createAction(
  "[NCP create] Create Ncp",
  props<{ data: NcpList[]; cpmrn: string; encounter: number }>()
);

export const createNcpSuccess = createAction(
  "[NCP create] Create Ncp Success",
  props<{ data: NcpResponse }>()
);

export const resetState = createAction("[NCP Create] Reset State");

export const fetchNcpData = createAction(
  "[NCP fetch] Fetch Ncp Data",
  props<{ cpmrn: string; encounter: number }>()
);

export const fetchNcpDataSuccess = createAction(
  "[NCP fetch] Fetch Ncp Data Success",
  props<{ data: NcpData }>()
);

export const fetchNcpDataFailure = createAction(
  "[NCP fetch] Fetch Ncp Data Failure"
);

export const updateNcp = createAction(
  "[NCP create] Update Ncp",
  props<{ data: UpdateNcp[]; cpmrn: string; encounter: number }>()
);
