export const environment = {
  production: true,
  apiUrl: "https://release.cloudphysicianworld.org/api/",
  devResUrl: "https://release.cloudphysicianworld.org/api/",
  ptzApiUrl: "https://release.cloudphysicianworld.org/api/",
  socketUrl: "https://release.cloudphysicianworld.org/",
  billingUrl: "https://release.cloudphysicianworld.org/ ",
  recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
  passwordSecretKey: "CloudPhysician",
  wikiDomain: "api.sandbox.cloudphysicianworld.org",
  s3region: "ap-south-1",
  socketPath: "/api/CP-EMR",
  s3bucket: "emr-dev-public/release/",
};
