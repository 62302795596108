import { Subject } from "rxjs";
// import { Socket } from 'ngx-socket-io';
import { select, Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Component, HostListener, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { TaskBE } from "../models/task/Task.model";
import * as fromOrder from "../store/reducers/order";
import { OrderViewActions } from "../store/actions/order";
import { tabCount } from "src/app/shared/global.variables";
import { OrderService } from "src/app/services/order.service";
import { PatientSocketActions } from "../store/actions/socket";
import * as fromUserReducer from "src/app/store/reducers/user";
import { PatientService } from "src/app/services/patient.service";
import * as rootActions from "src/app/store/actions/root.actions";
import { SplashScreenService } from "../services/splash-screen.service";
import * as actions from "src/app/store/actions/order/order-main.actions";
import * as hospitalActions from "src/app/store/actions/hospitals.actions";
import * as fromDocuments from "../store/reducers/patient-chart/documents";
import * as ioActions from "src/app/store/actions/patient-chart/io/io.actions";
import { populateOrder } from "src/app/patient/intake-output/io-view/get-order-data";
import * as notesActions from "src/app/store/actions/patient-chart/notes/notes.actions";
import * as vitalActions from "src/app/vitals/store/actions/vitals.actions";
import * as noteAction from "../../app/note-module/store/actions";
import * as documentsActions from "src/app/store/actions/patient-chart/documents/documents.actions";
import * as patHeaderActions from "src/app/store/actions/patient-chart/patient-header/patient-data.actions";
import * as fromPatChartActions from "src/app/store/actions/patient-chart/patient-header/patient-data.actions";
import * as fromChatIndex from "src/app/patient/chat/store/reducers";
import { Favicons } from "src/app/favicons";
import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import * as notificationActions from "../store/actions/notifications/notifications";
import * as fromPatientHeader from "src/app/store/reducers/patient-chart/patient-header";
import {
  MarInfusionActions,
  MarNormalActions,
  MarCommonActions,
  MarSOSActions,
  MarDietActions,
} from "@mar/store/actions/index";
import { isEmpty } from "lodash-es";
import { setRoxColor } from "src/app/support-functions/calculateRox";
import { NgxPermissionsService } from "ngx-permissions";
import { PatientMonitorSocketActions } from "../patient-monitor/actions";
import { AuthLocalStorageService } from "../get-started/services/auth-localStorage.service";

import { socket, SocketService } from "../services/socket.service";

//store
import * as fromNoteStore from "../../app/note-module/store";
import * as fromLabStore from "../../app/labs-scans-module/store";
import { Notes } from "../../app/note-module/model/notes.model";
import { CodeSheet } from "../note-module/model/code-sheet.model";
@Component({
  selector: "app-patient",
  templateUrl: "./patient.component.html",
  styleUrls: ["./patient.component.scss"],
})
export class PatientComponent implements OnInit {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public activeCulturesCount$ = this.store.pipe(
    select(fromOrder.getActiveCulturesCount)
  );
  public cultureDocumentCount$ = this.store.pipe(
    select(fromDocuments.getCulturesDocumentsCount)
  );

  public activeCovidOrdersCount$ = this.store.pipe(
    select(fromOrder.getActiveCovidOrdersCount)
  );
  public covidDocumentCount$ = this.store.pipe(
    select(fromDocuments.getCovidDocumentsCount)
  );

  public chatReadReciepts$ = this.store.pipe(
    select(fromChatIndex.getChatReceipts),
    takeUntil(this.unsubscribe$)
  );
  public chatNoti$ = this.store.pipe(
    select(fromChatIndex.getNotificationStatus),
    takeUntil(this.unsubscribe$)
  );
  public chatMsgs$ = this.store.pipe(
    select(fromChatIndex.getChatMessages),
    takeUntil(this.unsubscribe$)
  );

  public roxIndex$ = this.store.pipe(
    select(fromPatientHeader.getRox),
    takeUntil(this.unsubscribe$)
  );

  public CPMRN;
  public encounters;
  public currentUser;
  public notesCount;
  public showHeader = true;
  public isDischargeTimeElapsed = false;

  public noOfTabs = tabCount;

  public recievedData;

  public showData = true;
  public loadingData: boolean = true;

  public di;

  public socketChannels = {
    header: null,
    multiplePatients: null, // used for updating data of multiple patients e.g. updating PCP [primary md]
    vitals: null,
    notes: null,
    notesDraft: null,
    io: null,
    documents: null,
    documentsEdit: null,
    task: null,
    notebook: null,
    chat: null,
    chatReciepts: null,
    roxIndex: null,
    di: null,
    patientMonitor: null,
    marActive: null,
    marFetchAll: null,
    marDiscontinue: null,
    notesDelete: null,
    codeSheet: null,
    codesheetDraft: null,
  };

  public roxIndex: any;
  public chatMessages: any = [];

  constructor(
    private notestore: Store<fromNoteStore.NoteState>,
    private labstore: Store<fromLabStore.LabState>,
    private store: Store<{}>,
    private route: ActivatedRoute,
    private _orderService: OrderService,
    private _patientService: PatientService,
    private _splashScreen: SplashScreenService,
    private pageTitle: Title,
    private favicons: Favicons,
    private ngxPermissionsService: NgxPermissionsService,
    private _authLocalStorageService: AuthLocalStorageService,
    private _socketService: SocketService
  ) {
    // get all hospitals
    // this.store.dispatch(hospitalActions.getHospitals());
  }

  ngOnInit() {
    // start loading
    // this._splashScreen.startLoading();

    // get cpmrn and encounters from URL
    this.CPMRN = this.route.snapshot.paramMap.get("patientCPMRN"); // get CPMRN from url
    this.encounters = this.route.snapshot.paramMap.get("patientEncounters");

    // if show data show the data and open sockets
    // get the user data from store
    this.store
      .pipe(select(fromUserReducer.getCurrUser), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.currentUser = data;

        if (this.currentUser.name) {
          this.getComponentData();

          // Open socket channels for all components
          this.setSockets();
        }
      });

    // show notification
    this.chatNoti$.subscribe((data) => {
      if (data) {
        // show chat favicon
        this.useFavicon("chat_icon");
      } else {
        // show CP favicon
        this.useFavicon("cp_icon");
      }
    });

    /** DISABLING THIS TO DISABLE ROUNDAR FOR RADAR 4.0 */
    // if (this.ngxPermissionsService.getPermission("get_notebook")) {
    //   this.store
    //     .pipe(select(fromNotebook.getNotebookStatusLoaded), take(1))
    //     .subscribe((loaded) => {
    //       if (!loaded && this.CPMRN && this.encounters) {
    //         this.store.dispatch(
    //           NotebookActions.getNotebookPages({
    //             cpmrn: this.CPMRN,
    //             encounters: this.encounters,
    //           })
    //         );
    //       }
    //     });
    // }

    // set rox
    this.roxIndex$.subscribe((rox) => {
      this.roxIndex = rox;
    });

    // get messages
    this.chatMsgs$.subscribe((chat) => (this.chatMessages = chat));
  }

  getComponentData() {
    this._patientService.getPatient(this.CPMRN, this.encounters).subscribe(
      (data) => {
        // set header
        if (data["header"] && data["header"]["CPMRN"]) {
          let patient = { ...data["header"] };

          this.store.dispatch(fromPatChartActions.setPatient({ patient }));

          // set the page title
          let title =
            patient.name +
            (patient.bedNo ? " | " + patient.bedNo : "") +
            " | " +
            patient.CPMRN;
          this.pageTitle.setTitle(title);

          this.isDischargeTimeElapsed =
            this._patientService.checkIfDischargeTimeElapsed(patient);
        }

        // set vital
        if (data["vitalInfo"]) {
          this.store.dispatch(
            vitalActions.setVitals({ vitals: data["vitalInfo"] })
          );
        }

        // set notes
        if (data["notes"]) {
          this.notestore.dispatch(
            new fromNoteStore.LoadCodeSheetNote(data["notes"] as CodeSheet)
          );
          this.notestore.dispatch(
            new fromNoteStore.LoadNote(data["notes"] as Notes)
          );
        }

        // set io
        if (data["io"]) {
          let ioObj = {};
          let intakeProps = {
            infusionProps: [],
            bolusProps: [],
          };

          // setup med props
          if (data["io"].medProps?.length) {
            data["io"].medProps.forEach((medProp) => {
              if (medProp.medType === "infusion") {
                intakeProps.infusionProps.push(medProp.name);
              }
              if (medProp.medType === "bolus") {
                intakeProps.bolusProps.push(medProp.name);
              }
            });
          }

          let outputProps: {};
          let proceduresConnected = {};

          ioObj = data["io"]["days"];
          outputProps = data["io"]["props"];

          let tempInfusionProps: any = [];
          let tempBolusProps: any = [];
          data["io"]["days"].forEach((day) => {
            day.hours.forEach((hr) => {
              // get infusion
              if (hr.intake && hr.intake.meds && hr.intake.meds.infusion) {
                hr.intake.meds.infusion.forEach((inf) => {
                  if (tempInfusionProps.indexOf(inf.name) < 0) {
                    tempInfusionProps.push(inf.name);
                  }
                });
              }

              // get bolus
              if (hr.intake && hr.intake.meds && hr.intake.meds.bolus) {
                hr.intake.meds.bolus.forEach((bol) => {
                  if (tempBolusProps.indexOf(bol.name) < 0) {
                    tempBolusProps.push(bol.name);
                  }
                });
              }
            });
          });

          // order has data
          if (Object.keys(data["orders"]).length > 0) {
            let dischargedDate = data["header"]["ICUDischargeDate"]
              ? data["header"]["ICUDischargeDate"]
              : null; // check if discharge date exists

            let tempRes = populateOrder(
              data["orders"],
              data["header"]["ICUAdmitDate"],
              ioObj,
              data["header"]["weightKg"],
              dischargedDate
            );
            proceduresConnected = tempRes.proceduresConnected;
          }

          this.store.dispatch(
            ioActions.setIo({
              ioObj,
              intakeProps,
              outputProps,
              proceduresConnected,
            })
          );
        }

        // set documents
        if (data["documents"]) {
          this.store.dispatch(
            documentsActions.setDocuments({ docs: data["documents"] })
          );
          this.labstore.dispatch(
            new fromLabStore.LoadLabDocumentSocketSuccess(data["documents"])
          );
        }
        // set orders
        if (data["orders"]) {
          this.store.dispatch(new actions.AddAllOrders(data["orders"]));
        }
        // set chat
        if (data["chat"]) {
          // set read reciepts
          if (data["chat"]["readReciepts"]) {
            this.store.dispatch(
              chatActions.setReadReciepts({
                readReciepts: data["chat"]["readReciepts"],
              })
            );
          }
          // set chat messages
          if (data["chat"]["messages"] && data["chat"]["messages"].length) {
            this.store.dispatch(
              chatActions.setChat({ messages: data["chat"]["messages"] })
            );
          }
        }

        // show chat notification
        if (data["unreadChat"]) {
          this.store.dispatch(chatActions.showNotification());
        }

        //set the mar discharged data in the store
        if (data["marDiscontinuedData"]) {
          this.store.dispatch(
            MarCommonActions.setInactiveMar({
              mar: data["marDiscontinuedData"],
            })
          );
        }

        //set the mar data in the store
        if (data["marData"]) {
          let normalPayload = [];
          if (!isEmpty(data["marData"] && data["marData"]["normal"])) {
            let normal = data["marData"]["normal"];
            for (const key in normal) {
              normalPayload.push({
                day: key,
                records: normal[key],
              });
            }
          }

          let sosPayload = [];
          if (!isEmpty(data["marData"] && data["marData"]["sos"])) {
            let sos = data["marData"]["sos"];
            for (const key in sos) {
              sosPayload.push({
                day: key,
                records: sos[key],
              });
            }
          }

          let dietPayload = [];
          if (!isEmpty(data["marData"] && data["marData"]["diet"])) {
            let diet = data["marData"]["diet"];
            for (const key in diet) {
              dietPayload.push({
                day: key,
                records: diet[key],
              });
            }
          }

          let infusionPayload = [];
          if (!isEmpty(data["marData"] && data["marData"]["infusion"])) {
            let infusion = data["marData"]["infusion"];
            for (const key in infusion) {
              infusionPayload.push({
                day: key,
                records: infusion[key],
              });
            }
          }

          this.store.dispatch(
            MarNormalActions.setMarNormal({ mar: normalPayload })
          );
          this.store.dispatch(MarSOSActions.setMarSOS({ mar: sosPayload }));
          this.store.dispatch(MarDietActions.setMarDiet({ mar: dietPayload }));
          this.store.dispatch(
            MarInfusionActions.setMarInfusion({ mar: infusionPayload })
          );
        }

        // Stop loading
        // this._splashScreen.stopLoading();
        this.loadingData = false;

        // load protocol files
        if (data["activeProtocols"] && data["activeProtocols"].length > 0) {
          this.store.dispatch(
            new actions.SetActiveProtocols(data["activeProtocols"])
          );
        }
      },
      (err) => {
        console.log(err);
        if (!localStorage.getItem("refreshToken")) {
          this.store.dispatch(rootActions.logout());
          this._authLocalStorageService.clearLocalStorage();
          return;
        }
        // TODO - dunno
        // window.location.href = "/patient-not-found"
      }
    );
  }

  loadOrders(type) {
    this.store.dispatch({
      type: actions.ActionTypes.listOrders,
      payload: {
        type: type,
        CPMRN: this.CPMRN,
        encounters: this.encounters,
      },
    });
  }

  setSockets() {
    this.socketChannels = {
      header: this.CPMRN + this.encounters,
      multiplePatients: "multiple-patients-update",
      vitals: this.CPMRN + this.encounters + "vitals-update",
      notes: this.CPMRN + this.encounters + "Notes",
      notesDelete: this.CPMRN + this.encounters + "NoteDelete",
      notesDraft:
        this.CPMRN + this.encounters + "Notes" + this.currentUser.email,
      io: this.CPMRN + this.encounters + "IO",
      documents: this.CPMRN + this.encounters + "documents",
      documentsEdit: this.CPMRN + this.encounters + "DOC Edit",
      task: `${this.CPMRN}:${this.encounters}` + "Receive Task",
      chat: `${this.CPMRN}:${this.encounters}` + "Chat",
      chatReciepts: `${this.CPMRN}:${this.encounters}` + "Chat-Recietps",
      notebook: `${this.CPMRN}:${this.encounters}Notebook`,
      roxIndex: `${this.CPMRN}:${this.encounters}` + "rox_index",
      di: `${this.CPMRN}:${this.encounters}` + "di",
      patientMonitor: `${this.CPMRN}:${this.encounters}:patientMonitor`,
      marFetchAll: `${this.CPMRN}-${this.encounters}-active-marFetchAll`,
      marActive: `${this.CPMRN}-${this.encounters}-active-marUpdated`,
      marDiscontinue: `${this.CPMRN}-${this.encounters}-marDiscontinued`,
      codeSheet: this.CPMRN + this.encounters + "CodeSheetNotes",
      codesheetDraft: this.CPMRN + this.encounters + "codesheetDraft",
    };

    /* Socket for Hospitals */
    socket.on("hospitalUpdated", (data) => {
      this.store.dispatch(hospitalActions.setHospitals({ hospitals: data }));
    });

    /* Socket for Patient header */
    socket.on(this.socketChannels.header, (data) => {
      let patientData = { ...data };
      this.store.dispatch(
        patHeaderActions.setPatient({ patient: patientData })
      );
    });

    /**
     * @description socket for updating some keys for multiple patients
     * @example updating primary md [PCP] for multiple patients [ if we change a PCP's speciality, we need to update all patients which have that PCP assigned ]
     * @param socketRes - { patients: [cpmrn1, cpmrn2 ... ect], data: { key1: value1, key2: value2, etc} }
     * @author Rajat Saini
     * @date Jan 11, 2023
     */
    socket.on(this.socketChannels.multiplePatients, (socketRes) => {
      const { patients, data } = socketRes as { patients: string[]; data: any };
      if (
        !patients ||
        !patients?.length ||
        !patients.includes(this.CPMRN) ||
        !data ||
        !Object.keys(data)?.length
      )
        return;

      this.store.dispatch(
        patHeaderActions.setPatient({ patient: { ...data } })
      );
    });

    /* Socket for Rox heindexader */
    socket.on(this.socketChannels.roxIndex, (data) => {
      this.roxIndex = data;

      this.store.dispatch(
        patHeaderActions.setPatient({ patient: { rox: data } })
      );
    });

    /* Socket for Rox di */
    socket.on(this.socketChannels.di, (data) => {
      this.di = data;

      this.store.dispatch(
        patHeaderActions.setPatient({ patient: { di: data } })
      );
    });

    /* Socket for Vitals */
    socket.on(this.socketChannels.vitals, (data) => {
      this.store.dispatch(vitalActions.updateVital({ vital: data }));
    });

    /* Socket for Notes */
    socket.on(this.socketChannels.notes, (notes) => {
      // this.store.dispatch(notesActions.setNotes({ notes: notes }));
      this.notestore.dispatch(new fromNoteStore.LoadNoteFinalSocket(notes));
    });

    /* Socket for Notes Draft */
    socket.on(this.socketChannels.notesDraft, (notes) => {
      // this.store.dispatch(notesActions.setNotes({ notes: notes }));
      this.notestore.dispatch(new fromNoteStore.LoadNoteDraftSocket(notes));
    });

    /* Socket for Notes Delete*/
    socket.on(this.socketChannels.notesDelete, (notes) => {
      // this.store.dispatch(notesActions.setNotes({ notes: notes }));
      this.notestore.dispatch(new fromNoteStore.NoteDeletePendedSuccess(notes));
      // this.notestore.dispatch(new fromNoteStore.LoadNote(notes));
    });

    /* Socket for CodeSheetNotes */
    socket.on(this.socketChannels.codeSheet, (notes) => {
      // this.store.dispatch(notesActions.setNotes({ notes: notes }));
      this.notestore.dispatch(new fromNoteStore.LoadCodeSheetNote(notes));
    });

    /* Socket for CodeSheetDraftNotes */
    socket.on(this.socketChannels.codesheetDraft, (notes) => {
      this.notestore.dispatch(
        new fromNoteStore.CreateCodeSheetDraftSuccess(notes)
      );
    });

    /* Socket for Io */
    socket.on(this.socketChannels.io, (message) => {
      if (this.ngxPermissionsService.getPermission("get_io")) {
        this.store.dispatch(
          ioActions.getIo({ CPMRN: this.CPMRN, encounters: this.encounters })
        );
      }
    });

    /* Socket for Documents */
    socket.on(this.socketChannels.documents, (docs) => {
      this.labstore.dispatch(
        new fromLabStore.LoadLabDocumentSocketSuccess(docs["documents"])
      );
    });

    /* Socket for Orders */
    this._orderService.startListening(this.CPMRN, this.encounters);

    /* Socket for Tasks */
    socket.on(this.socketChannels.task, (task: TaskBE) => {
      this.store.dispatch(
        PatientSocketActions.updateTask({
          task: {
            id: task.clientId,
            changes: { output: task.output, status: task.status },
          },
        })
      );
    });

    /* Socket for Patient vitals notification */
    socket.on("notifications-vitals", (data) => {
      this.store.dispatch(
        notificationActions.addNotification({ notification: data })
      );
    });

    /* Socket for Chat */
    socket.on(this.socketChannels.chat, (chatData) => {
      let senderEmail =
        chatData.type == "single"
          ? chatData.data.senderEmail
          : chatData.data[0].senderEmail;
      if (senderEmail != this.currentUser.email) {
        // show notification if the message is not deleted
        if (!chatData.data.isDeleted) {
          this.store.dispatch(chatActions.showNotification());
        }
      } else {
        this.store.dispatch(chatActions.hideNotification());
      }

      if (chatData.type == "single") {
        this.store.dispatch(chatActions.addChat({ message: chatData.data }));
      } else if (chatData.type == "multi") {
        this.store.dispatch(
          chatActions.addMultiChat({ messages: chatData.data })
        );
      }
    });

    /* Socket for Chat Reciepts */
    socket.on(this.socketChannels.chatReciepts, (message) => {
      this.store.dispatch(
        chatActions.updateReadReciepts({ readReciept: message })
      );
    });

    socket.on(this.socketChannels.patientMonitor, (data) => {
      if (
        this.CPMRN === data.CPMRN &&
        this.encounters === data.encounters &&
        data.type === "delete"
      ) {
        this.store.dispatch(
          PatientMonitorSocketActions.deletePatientMonitor({ data })
        );
      }
    });

    socket.on(this.socketChannels.marActive, (data) => {
      if (data?.normal) {
        if (isEmpty(data?.normal)) {
          this.store.dispatch(MarNormalActions.resetMarNormal());
        } else {
          let normalMAR = [];

          for (const key in data.normal) {
            normalMAR.push({
              day: key,
              records: data.normal[key],
            });
          }

          this.store.dispatch(
            MarNormalActions.upsertMarNormal({ mar: normalMAR })
          );
        }
      }

      if (data?.infusion) {
        if (isEmpty(data?.infusion)) {
          this.store.dispatch(MarInfusionActions.resetMarInfusion());
        } else {
          let InfusionMAR = [];

          for (const key in data.infusion) {
            InfusionMAR.push({
              day: key,
              records: data.infusion[key],
            });
          }

          this.store.dispatch(
            MarInfusionActions.upsertMarInfusion({ mar: InfusionMAR })
          );
        }
      }

      if (data?.sos) {
        if (isEmpty(data?.sos)) {
          this.store.dispatch(MarSOSActions.resetMarSOS());
        } else {
          let sosMAR = [];
          for (const key in data.sos) {
            sosMAR.push({
              day: key,
              records: data.sos[key],
            });
          }

          this.store.dispatch(MarSOSActions.upsertMarSOS({ mar: sosMAR }));
        }
      }

      if (data?.diet) {
        if (isEmpty(data?.diet)) {
          this.store.dispatch(MarDietActions.resetMarDiet());
        } else {
          let dietMAR = [];
          for (const key in data.diet) {
            dietMAR.push({
              day: key,
              records: data.diet[key],
            });
          }

          this.store.dispatch(MarDietActions.upsertMarDiet({ mar: dietMAR }));
        }
      }
    });

    socket.on(this.socketChannels.marFetchAll, (data) => {
      this.store.dispatch(
        MarCommonActions.getMar({
          cpmrn: data.cpmrn,
          encounter: +data.encounter,
        })
      );
    });

    socket.on(this.socketChannels.marDiscontinue, (data) => {
      let query = {
        cpmrn: data.cpmrn,
        encounter: +data.encounter,
        marType: data.type,
      };

      if (data.type) {
        query.marType = data.type;
      }

      this.store.dispatch(MarCommonActions.getDischargedMar(query));
    });
  }

  stopAllSockets() {
    for (const key in this.socketChannels) {
      if (this.socketChannels.hasOwnProperty(key)) {
        const sName = this.socketChannels[key];

        // stop sockets
        this._socketService.removeAllListeners(sName);
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe the observable
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    // stop order service
    this._orderService.stopListening();

    // reset all store parts
    this.store.dispatch(rootActions.patientChartReset());

    // destroy all channels
    this.stopAllSockets();
  }

  setCurrentNotificationUser({ cpmrn, encounter }) {
    this.CPMRN = cpmrn;
    this.encounters = encounter;
    this.getComponentData();
  }
  useFavicon(name: string): void {
    // Notice that we don't need to know anything about how the favicon is defined;
    // not URLs, no image types - just the identifier. All of the implementation
    // details have been defined at time.
    this.favicons.activate(name);
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander(event) {
    //update the lst seen only if the last seen is there in the store.
    // this.setLastScene();
  }
}
