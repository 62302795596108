export const GRAPH_MARGINS = { top: 20, right: 25, bottom: 40, left: 50 };
export const GRAPH_MARGINS_NICE_CHART = {
  top: 35,
  right: 40,
  bottom: 40,
  left: 50,
};

export enum TrendType {
  vital = "Vital",
  weight = "Weight",
  niceChart = "NICE Chart",
  growthChart = "GROWTH Chart",
}

// lab names
export const BILIRUBIN_LAB_NAME = "Bilirubin [Moles/Vol]";
export const LFT_LAB_NAME = "Hepatic function 1996 panel";
export const DIRECT_ANTI_GLOBULIN_LAB_NAME =
  "Direct antiglobulin test.poly specific";

// *** WEIGHT TREND CONSTANT *** //
export const weightChartYAxisRanges = [[0, 1000], 10, "Weight(grams)"];
export const weightChartXAxisRanges = [true, [0, 25], "Age(days)"];

// *** NICE CHART CONSTANTS *** //
export const NICE_CHART_Y_AXIS_RANGES = [
  [0, 550],
  10,
  "Total serum bilirubin(mcmol/l)",
];

export const NICE_CHART_X_AXIS_RANGES = [true, [0, 14], "Age(days)"];

export const NICE_CHART_LINE_1_COORDS = {
  min: [
    {
      value: 40,
      age: 0,
    },
    {
      value: 130,
      age: 3,
    },
    {
      value: 130,
      age: 14,
    },
  ],
  "23": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 130,
      age: 3,
    },
    {
      value: 130,
      age: 14,
    },
  ],
  "24": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 140,
      age: 3,
    },
    {
      value: 140,
      age: 14,
    },
  ],
  "25": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 150,
      age: 3,
    },
    {
      value: 150,
      age: 14,
    },
  ],
  "26": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 160,
      age: 3,
    },
    {
      value: 160,
      age: 14,
    },
  ],
  "27": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 170,
      age: 3,
    },
    {
      value: 170,
      age: 14,
    },
  ],
  "28": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 180,
      age: 3,
    },
    {
      value: 180,
      age: 14,
    },
  ],
  "29": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 190,
      age: 3,
    },
    {
      value: 190,
      age: 14,
    },
  ],
  "30": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 200,
      age: 3,
    },
    {
      value: 200,
      age: 14,
    },
  ],
  "31": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 210,
      age: 3,
    },
    {
      value: 210,
      age: 14,
    },
  ],
  "32": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 220,
      age: 3,
    },
    {
      value: 220,
      age: 14,
    },
  ],
  "33": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 230,
      age: 3,
    },
    {
      value: 230,
      age: 14,
    },
  ],
  "34": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 240,
      age: 3,
    },
    {
      value: 240,
      age: 14,
    },
  ],
  "35": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 250,
      age: 3,
    },
    {
      value: 250,
      age: 14,
    },
  ],
  "36": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 260,
      age: 3,
    },
    {
      value: 260,
      age: 14,
    },
  ],
  "37": [
    {
      value: 40,
      age: 0,
    },
    {
      value: 270,
      age: 3,
    },
    {
      value: 270,
      age: 14,
    },
  ],
  "38": [
    {
      value: 100,
      age: 0,
    },
    {
      value: 200,
      age: 1,
    },
    {
      value: 350,
      age: 4,
    },
    {
      value: 350,
      age: 14,
    },
  ],
  max: [
    {
      value: 100,
      age: 0,
    },
    {
      value: 200,
      age: 1,
    },
    {
      value: 350,
      age: 4,
    },
    {
      value: 350,
      age: 14,
    },
  ],
};

export const NICE_CHART_LINE_2_COORDS = {
  min: [
    {
      value: 80,
      age: 0,
    },
    {
      value: 230,
      age: 3,
    },
    {
      value: 230,
      age: 14,
    },
  ],
  "23": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 230,
      age: 3,
    },
    {
      value: 230,
      age: 14,
    },
  ],
  "24": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 240,
      age: 3,
    },
    {
      value: 240,
      age: 14,
    },
  ],
  "25": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 250,
      age: 3,
    },
    {
      value: 250,
      age: 14,
    },
  ],
  "26": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 260,
      age: 3,
    },
    {
      value: 260,
      age: 14,
    },
  ],
  "27": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 270,
      age: 3,
    },
    {
      value: 270,
      age: 14,
    },
  ],
  "28": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 280,
      age: 3,
    },
    {
      value: 280,
      age: 14,
    },
  ],
  "29": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 290,
      age: 3,
    },
    {
      value: 290,
      age: 14,
    },
  ],
  "30": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 300,
      age: 3,
    },
    {
      value: 300,
      age: 14,
    },
  ],
  "31": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 310,
      age: 3,
    },
    {
      value: 310,
      age: 14,
    },
  ],
  "32": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 320,
      age: 3,
    },
    {
      value: 320,
      age: 14,
    },
  ],
  "33": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 330,
      age: 3,
    },
    {
      value: 330,
      age: 14,
    },
  ],
  "34": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 340,
      age: 3,
    },
    {
      value: 340,
      age: 14,
    },
  ],
  "35": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 350,
      age: 3,
    },
    {
      value: 350,
      age: 14,
    },
  ],
  "36": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 360,
      age: 3,
    },
    {
      value: 360,
      age: 14,
    },
  ],
  "37": [
    {
      value: 80,
      age: 0,
    },
    {
      value: 370,
      age: 3,
    },
    {
      value: 370,
      age: 14,
    },
  ],
  "38": [
    {
      value: 100,
      age: 0,
    },
    {
      value: 450,
      age: 3,
    },
    {
      value: 450,
      age: 14,
    },
  ],
  max: [
    {
      value: 100,
      age: 0,
    },
    {
      value: 450,
      age: 3,
    },
    {
      value: 450,
      age: 14,
    },
  ],
};

export enum PhotoTherapyValue {
  Single = "single",
  Multiple = "multiple",
}

export const PHOTO_THERAPY_ICONS = {
  // add: "https://emr-public.s3.ap-south-1.amazonaws.com/assets/images/Add button.svg1677842699105.svg",
  add: `<g><path fill-rule="evenodd" clip-rule="evenodd" d="M4.9291 19.0711C1.0188 15.1608 1.0188 8.83923 4.9291 4.92893C8.8394 1.01863 15.1609 1.01863 19.0712 4.92893C22.9815 8.83923 22.9815 15.1608 19.0712 19.0711C15.1609 22.9814 8.8394 22.9814 4.9291 19.0711ZM17.0842 12.997C17.6358 12.997 18.0813 12.5516 18.0813 12C18.0742 11.4555 17.6216 11.003 17.0842 11.003L12.9972 11.003L12.9972 6.91591C12.9972 6.36437 12.5517 5.91889 12.0001 5.91889C11.4486 5.91889 11.0031 6.36437 11.0031 6.91591V11.003L6.91604 11.003C6.3645 11.003 5.91902 11.4485 5.91902 12C5.91872 12.2645 6.02367 12.5183 6.21071 12.7053C6.39775 12.8924 6.65152 12.9973 6.91604 12.997H11.0031V17.0841C11.0028 17.3486 11.1078 17.6024 11.2948 17.7894C11.4819 17.9765 11.7356 18.0814 12.0001 18.0811C12.5517 18.0811 12.9972 17.6357 12.9972 17.0841V12.997L17.0842 12.997Z" fill="#34A2B1"/>
  </g>`,
  single: `<g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00416 18.456C5.33705 18.7889 5.87479 18.7889 6.20768 18.456L6.54057 18.1231C6.87346 17.7902 6.86492 17.2525 6.54057 16.9281L6.53203 16.9196C6.37256 16.7597 6.15606 16.6699 5.93027 16.6699C5.70449 16.6699 5.48799 16.7597 5.32851 16.9196L4.99563 17.2525C4.67127 17.5939 4.67127 18.1231 5.00416 18.456ZM11.6108 21.196H11.6194C12.0888 21.196 12.4644 20.8205 12.4644 20.351V19.5316C12.4644 19.0621 12.0888 18.6866 11.6194 18.6866H11.6108C11.1414 18.6866 10.7658 19.0621 10.7658 19.5316V20.351C10.7658 20.8205 11.1414 21.196 11.6108 21.196ZM17.0225 18.4646L16.6896 18.1317C16.3567 17.7988 16.3567 17.2696 16.6981 16.9281C17.0225 16.6038 17.5602 16.5953 17.8931 16.9281L18.226 17.261C18.5589 17.5939 18.5589 18.1317 18.226 18.4646C17.8931 18.7974 17.3554 18.7974 17.0225 18.4646Z" fill="#8B94B3"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8241 16.7446C9.23192 16.7446 7.12329 14.636 7.12329 12.0439C7.12329 10.4456 7.92242 9.04209 9.13791 8.18924V6.67156C9.13791 6.30221 9.4401 6.00002 9.80944 6.00002H13.8387C14.208 6.00002 14.5102 6.30221 14.5102 6.67156V8.18924C15.7257 9.04209 16.5248 10.4456 16.5248 12.0439C16.5248 14.636 14.4162 16.7446 11.8241 16.7446ZM9.80927 3.98559C9.80927 3.61624 10.1115 3.31405 10.4808 3.31405H13.167C13.5363 3.31405 13.8385 3.61624 13.8385 3.98559V4.65713H9.80927V3.98559ZM13.1668 8.88803L13.7376 9.29095C14.6442 9.9222 15.1814 10.9497 15.1814 12.0443C15.1814 13.8977 13.6772 15.4019 11.8238 15.4019C9.97031 15.4019 8.46607 13.8977 8.46607 12.0443C8.46607 10.9497 9.0033 9.9222 9.90988 9.29095L10.4807 8.88803V7.34349H13.1668V8.88803Z" fill="#8B94B3"/>    
    </g>`,
  multiple: `<g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4051 3.59958C13.3056 3.53052 13.1846 3.49023 13.0505 3.49023H11.9502V2.93651C11.9502 2.57182 12.2486 2.27344 12.6133 2.27344H15.2655C15.6302 2.27344 15.9286 2.57182 15.9286 2.93651V3.59958H13.4051ZM13.684 4.9257L13.684 6.1425H13.7138C13.8478 6.1425 13.9728 6.18277 14.0774 6.25181H15.2656V7.77688L15.8293 8.17472C16.7244 8.79801 17.2549 9.8125 17.2549 10.8933C17.2549 11.698 16.9677 12.4361 16.4903 13.0105C16.4344 13.6894 16.2381 14.4859 15.7449 15.1694C17.4101 14.4638 18.5811 12.8128 18.5811 10.8933C18.5811 9.31523 17.792 7.92941 16.5919 7.08731V5.58877C16.5919 5.22408 16.2935 4.9257 15.9288 4.9257H13.684ZM18.7441 16.9043L19.0728 17.233C19.4014 17.5617 19.9324 17.5617 20.2611 17.233C20.5898 16.9043 20.5898 16.3734 20.2611 16.0447L19.9324 15.716C19.6037 15.3873 19.0728 15.3957 18.7525 15.716C18.4154 16.0531 18.4154 16.5756 18.7441 16.9043Z" fill="#8B94B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.73953 18.9559C4.06822 19.2846 4.59918 19.2846 4.92787 18.9559L5.25656 18.6272C5.58525 18.2985 5.57682 17.7676 5.25656 17.4473L5.24813 17.4389C5.09067 17.2811 4.8769 17.1924 4.65396 17.1924C4.43103 17.1924 4.21725 17.2811 4.05979 17.4389L3.7311 17.7676C3.41083 18.1047 3.41083 18.6272 3.73953 18.9559ZM10.2623 21.6618H10.2707C10.7342 21.6618 11.1051 21.2909 11.1051 20.8274V20.0183C11.1051 19.5548 10.7342 19.1839 10.2707 19.1839H10.2623C9.79874 19.1839 9.42791 19.5548 9.42791 20.0183V20.8274C9.42791 21.2909 9.79874 21.6618 10.2623 21.6618ZM15.6058 18.9645L15.2771 18.6358C14.9484 18.3071 14.9484 17.7846 15.2855 17.4475C15.6058 17.1272 16.1368 17.1188 16.4655 17.4475L16.7942 17.7762C17.1228 18.1049 17.1228 18.6358 16.7942 18.9645C16.4655 19.2932 15.9345 19.2932 15.6058 18.9645Z" fill="#8B94B3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.4725 17.2656C7.9131 17.2656 5.83105 15.1836 5.83105 12.6241C5.83105 11.046 6.62011 9.66021 7.82027 8.81811V7.31957C7.82027 6.95488 8.11865 6.6565 8.48334 6.6565H12.4618C12.8265 6.6565 13.1248 6.95488 13.1248 7.31957V8.81811C14.325 9.66021 15.114 11.046 15.114 12.6241C15.114 15.1836 13.032 17.2656 10.4725 17.2656ZM8.48346 4.66713C8.48346 4.30244 8.78184 4.00406 9.14653 4.00406H11.7988C12.1635 4.00406 12.4619 4.30244 12.4619 4.66713V5.3302H8.48346V4.66713ZM11.7989 9.50768L12.3625 9.90552C13.2576 10.5288 13.7881 11.5433 13.7881 12.6241C13.7881 14.4542 12.3028 15.9395 10.4727 15.9395C8.64264 15.9395 7.15736 14.4542 7.15736 12.6241C7.15736 11.5433 7.68782 10.5288 8.58296 9.90552L9.14657 9.50768V7.98262H11.7989V9.50768Z" fill="#8B94B3"/>
  </g>`,
};

export const PHOTO_THERAPY_INTENSITY_MAP = [
  {
    label: "Single",
    value: PhotoTherapyValue.Single,
    img: "assets/icons/Single.svg",
  },
  {
    label: "Multiple",
    value: PhotoTherapyValue.Multiple,
    img: "assets/icons/Multiple.svg",
  },
];

export const getPTherapyLine1Coords = (gestAge, lineName) => {
  const LINE_COORDS =
    lineName === "line_1" ? NICE_CHART_LINE_1_COORDS : NICE_CHART_LINE_2_COORDS;
  if (gestAge in LINE_COORDS) return LINE_COORDS[gestAge];

  if (gestAge < 23) return LINE_COORDS["min"];
  if (gestAge > 38) return LINE_COORDS["max"];
};
