import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { CoreHttpService } from "src/app/services/base-service/core-http.service";

@Injectable({
  providedIn: "root",
})
export class SnappFormService extends CoreHttpService {
  constructor(private fb: FormBuilder) {
    super();
  }

  initSnappForm(): FormGroup {
    return this.fb.group({
      meanBP: [null, Validators.required],
      lowestTemp: [null, Validators.required],
      po2Fio2Ratio: [null, Validators.required],
      lowestSerumPh: [null, Validators.required],
      multipleSeizures: [null, Validators.required],
      urineOutput: [null, Validators.required],

      snap2Score: [null, Validators.required],

      apgarScore: [null, Validators.required],
      birthWeight: [null, Validators.required],
      gestationalAge: [null, Validators.required],
      smallForGestationalAge: [null, Validators.required],

      snappe2Score: [null, Validators.required],

      expectedDeathPercentage: [null, Validators.required],

      timestamp: new FormControl(
        new Date(new Date().setSeconds(0, 0)),
        Validators.required
      ),
    });
  }
}
