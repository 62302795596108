<app-loader *ngIf="imageLoading"></app-loader>

<section class="vital-view-section">
  <ng-container
    *ngIf="
      vitals
        | vitalDay: selectedDay
        | removeBlankEntries
          : (currentPatient.patientType == 'neonatal'
              ? vitalKeysNeonatal
              : vitalKeysAdult);
      let vitalsArray
    "
  >
    <table class="vitals-view-table">
      <thead>
        <tr>
          <th>Vitals</th>
          <th
            *ngFor="let day of vitalsArray; index as i"
            (mouseenter)="highlightButton(i)"
            (mouseleave)="highlightButton(-1)"
            (click)="canEditVitals ? highlightDay(day) : null"
            [ngClass]="{
              'iAssessment-header': day?.vitalType === 'Initial',
              highlight: day._id === (vitalClickedDay$ | async)?._id,
              'cp-cursor-pointer': canEditVitals,
              'max-height': showDateOnCol
            }"
            [matTooltip]="canEditVitals ? 'Click here to edit vitals' : ''"
          >
            <div class="info">
              <div class="date-time">
                <div *ngIf="showDateOnCol" class="day">
                  {{ day.timestamp | timezone: "DD / MM":timeZoneDetail?.name }}
                </div>
                <div class="time">
                  {{ day.timestamp | timezone: "HH:mm":timeZoneDetail?.name }}
                </div>
                <cp-timezone-label
                  *ngIf="timeZoneDetail?.name"
                  [timeZoneDetail]="timeZoneDetail"
                  [textColor]="
                    day._id === (vitalClickedDay$ | async)?._id
                      ? '#ccd7eb'
                      : null
                  "
                ></cp-timezone-label>
              </div>

              <div class="icon-holder">
                <!-- <div *ngIf="day.daysReasonBP" class="info-icon">
                  <mat-icon
                    popoverTrigger="hover"
                    cpPopover="{{ day.daysReasonBP }}"
                    popoverTitle="Comments"
                    position="top-start"
                    inline="true"
                    >speaker_notes</mat-icon
                  >
                </div> -->

                <div
                  *ngIf="
                    day.isVerified && day.verifiedTime && day.verifiedBy?.name
                  "
                  class="info-icon"
                  popoverTrigger="hover"
                  cpPopover="{{
                    day.verifiedBy?.name +
                      ' on ' +
                      (day.verifiedTime
                        | timezone: 'HH:mm, DD-MM-YYYY':timeZoneDetail?.name) +
                      ' ( ' +
                      timeZoneDetail?.abbreviation +
                      ' )'
                  }}"
                  popoverTitle="{{ day.dataBy ? 'Verified by' : 'Entered by' }}"
                  position="top-start"
                >
                  <mat-icon inline="true">info</mat-icon>
                </div>
              </div>
            </div>

            <div
              *ngIf="day.isVerified === false"
              matRipple
              [ngClass]="{ show: i === hoveredColumnIndex }"
              class="verification-btn"
              (click)="verifyColumn(day)"
            >
              Verify
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="comment-row">
          <th>Comments</th>
          <td
            popoverTrigger="hover"
            cpPopover="{{ day.daysReasonBP }}"
            position="top-start"
            *ngFor="
              let day of vitals
                | vitalDay: selectedDay
                | removeBlankEntries
                  : (currentPatient.patientType == 'neonatal'
                      ? vitalKeysNeonatal
                      : vitalKeysAdult);
              index as i
            "
            [ngClass]="{
              'iAssessment-body': day?.vitalType === 'Initial'
            }"
          >
            <mat-icon
              [ngClass]="{ blurred: !day.daysReasonBP }"
              inline="true"
              class="mr comment-icon"
              >comment</mat-icon
            >
            <ng-container *ngIf="day.daysReasonBP">
              {{
                day.daysReasonBP.length > 8
                  ? (day.daysReasonBP | slice: 0:8) + "..."
                  : day.daysReasonBP
              }}
            </ng-container>
          </td>
        </tr>
        <tr
          *ngFor="
            let vital of currentPatient.patientType == 'neonatal'
              ? vitalPropsNeonatal
              : vitalPropsAdult
          "
          [ngClass]="{
            top_border:
              vital.val == 'daysGCS' || vital.show == 'Pupillary scale',
            bottom_border: vital.val == 'daysRightPupilaryCondition'
          }"
        >
          <th
            (click)="openVitalTrends(vital)"
            [ngClass]="{
              normal_text_weight: [
                'daysGCSeyes',
                'daysGCSverbal',
                'daysGCSmotor',
                'daysLeftPupillarySize',
                'daysLeftPupilaryCondition',
                'daysRightPupillarySize',
                'daysRightPupilaryCondition'
              ].includes(vital.val),
              highlight_th:
                showVitalTrends &&
                (!currentPatient.patientType ||
                currentPatient?.patientType === 'adult'
                  ? vitalsToTrendsMap.includes(vital?.val)
                  : vitalsToTrendsMapPedNeo.includes(vital?.val))
            }"
          >
            <img
              class="trend-icon"
              *ngIf="
                showVitalTrends &&
                (!currentPatient.patientType ||
                currentPatient?.patientType === 'adult'
                  ? vitalsToTrendsMap.includes(vital?.val)
                  : vitalsToTrendsMapPedNeo.includes(vital?.val))
              "
              src="../../../../assets/icons/line_graph.svg"
              alt=""
            />
            <span [innerHtml]="vital.show"></span>
          </th>
          <td
            *ngFor="
              let vit of vitals
                | vitalDay: selectedDay
                | removeBlankEntries
                  : (currentPatient.patientType == 'neonatal'
                      ? vitalKeysNeonatal
                      : vitalKeysAdult)
            "
            [ngClass]="{
              'iAssessment-body': vit?.vitalType === 'Initial',
              row_highlight: vit.highlight,
              'not-verified': vit?.isVerified === false
            }"
          >
            <ng-container
              *ngIf="
                vital.val !== 'daysRassCAMICU' &&
                vital.val !== 'daysVentTube' &&
                vital.val !== 'daysVentAirway' &&
                vital.val !== 'daysTemperature' &&
                vital.val !== 'daysSpO2' &&
                vital.val !== 'daysMAP' &&
                vital.val !== 'daysAVPU' &&
                vital.val !== 'daysFalls' &&
                vital.val !== 'daysPain' &&
                vital.val !== 'daysBraden' &&
                vital.val !== 'daysGCS' &&
                vital.val !== 'daysGCSverbal' &&
                vital.val !== 'daysHR' &&
                vital.val !== 'daysRR' &&
                vital.val !== 'daysTherapyDevice' &&
                vital.val !== 'daysIE' &&
                vital.val !== 'daysVentAirway' &&
                vital.val !== 'nasopharyngealAirway' &&
                vital.val !== 'oralAirway' &&
                vital.val !== 'biteBlock' &&
                vital.val !== 'supraglotticAirway' &&
                vital.val !== 'cooksAirway' &&
                vital.val !== 'oralEndotrachealTube' &&
                vital.val !== 'nasalEndotrachealTube' &&
                vital.val !== 'tracheostomyTube' &&
                vital.val !== 'daysBP'
              "
            >
              <div class="vital_val">
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If temperature -->
            <ng-container *ngIf="vital.val === 'daysTemperature'">
              <!-- <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning':
                    (vit.daysTemperature > 96 && vit.daysTemperature <= 98) ||
                    (vit.daysTemperature >= 99 && vit.daysTemperature < 101) ||
                    (vit.daysTemperature > 35.56 &&
                      vit.daysTemperature <= 36.66) ||
                    (vit.daysTemperature >= 37.22 && vit.daysTemperature < 38.33),
                  'table-danger':
                    (vit.daysTemperature && vit.daysTemperature <= 35.56) ||
                    (vit.daysTemperature <= 96 && vit.daysTemperature >= 38.33) ||
                    vit.daysTemperature >= 101
                }"
              > -->
              <div
                class="vital_val verify_this"
                [ngClass]="
                  getVitalPriorityCase1(
                    vital.val,
                    vit[vital.val],
                    vit['daysTemperatureUnit']
                  )
                "
              >
                {{ vit[vital.val] }}
                <span
                  *ngIf="
                    vital.val == 'daysTemperature' && vit['daysTemperatureUnit']
                  "
                  >{{ vit["daysTemperatureUnit"] }}</span
                >
              </div>
            </ng-container>

            <!-- If BP for ped and neo-->
            <ng-container
              *ngIf="
                vital.val === 'daysBP' &&
                (currentPatient?.patientType == 'neonatal' ||
                  currentPatient?.patientType == 'pediatric')
              "
            >
              <div
                *ngIf="vit.daysBP"
                class="vital_val verify_this"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If BP for adult-->
            <ng-container
              *ngIf="
                vital.val === 'daysBP' &&
                (currentPatient?.patientType == 'adult' ||
                  currentPatient?.patientType == undefined)
              "
            >
              <div class="vital_val">
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If MAP -->
            <ng-container *ngIf="vital.val === 'daysMAP'">
              <div
                *ngIf="vit.daysMAP"
                class="vital_val"
                [ngClass]="{
                  'table-warning':
                    ((vit.daysMAP >= 51 && vit.daysMAP <= 60) ||
                      (vit.daysMAP >= 100 && vit.daysMAP <= 120)) &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined),
                  'table-danger':
                    (vit.daysMAP < 50 || vit.daysMAP > 120) &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined)
                }"
              >
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If HR -->
            <ng-container *ngIf="vital.val === 'daysHR'">
              <!-- <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning':
                    (vit.daysHR > 40 && vit.daysHR <= 60) ||
                    (vit.daysHR >= 100 && vit.daysHR < 120),
                  'table-danger':
                    vit.daysHR && (vit.daysHR <= 40 || vit.daysHR >= 120)
                }"
              > -->
              <div
                class="vital_val verify_this"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If RR -->
            <ng-container *ngIf="vital.val === 'daysRR'">
              <!-- <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning':
                    (vit.daysRR > 6 && vit.daysRR <= 8) ||
                    (vit.daysRR >= 22 && vit.daysRR < 30),
                  'table-danger':
                    vit.daysRR && (vit.daysRR <= 6 || vit.daysRR >= 30)
                }"
              > -->
              <div
                class="vital_val verify_this"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If Tube -->
            <ng-container *ngIf="vital.val === 'daysVentTube'">
              <ng-container [ngSwitch]="vit['daysVentTube']">
                <span *ngSwitchCase="'neonate'">Neonate (1)</span>
                <span *ngSwitchCase="'infant'">Infant (1½)</span>
                <span *ngSwitchCase="'smPediatric'">Small Pediatric (2)</span>
                <span *ngSwitchCase="'lgPediatric'">Large Pediatric (2½)</span>
                <span *ngSwitchCase="'smAdult'">Small Adult (3)</span>
                <span *ngSwitchCase="'mdAdult'">Medium Adult (4)</span>
                <span *ngSwitchCase="'lgAdult'">Large Adult (5)</span>
                <span *ngSwitchCase="'other'">Other</span>
                <span *ngSwitchDefault></span>
              </ng-container>
            </ng-container>

            <!-- If CAM icu -->
            <!-- <ng-container *ngIf="vital.val === 'daysRassCAMICU' && vit">
              <div
                class="vital_val"
                [ngClass]="{
                  'table-danger': vit.daysRassCAMICU == true
                }"
              >
                <span *ngIf="vit['daysRassCAMICU'] === true">Positive</span>
                <span *ngIf="vit['daysRassCAMICU'] === false">Negative</span>
              </div>
            </ng-container> -->

            <!-- If spo2 -->
            <ng-container *ngIf="vital.val === 'daysSpO2'">
              <!-- <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning': vit.daysSpO2 >= 81 && vit.daysSpO2 <= 90,
                  'table-danger': vit.daysSpO2 && vit.daysSpO2 < 81
                }"
              > -->
              <div
                class="vital_val verify_this"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
                <span *ngIf="vit['daysFiO2']">({{ vit["daysFiO2"] }})</span>
              </div>
            </ng-container>

            <!-- If AVPU -->
            <ng-container *ngIf="vital.val === 'daysAVPU'">
              <div
                class="vital_val verify_this"
                [ngClass]="{
                  'table-warning':
                    (vit.daysAVPU == 'V' || vit.daysAVPU == 'P') &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined),
                  'table-danger':
                    vit.daysAVPU == 'U' &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined)
                }"
              >
                {{ vit[vital.val] }}
              </div>
            </ng-container>

            <!-- If Fall -->
            <ng-container *ngIf="vital.val === 'daysFalls'">
              <!-- <div
                class="vital_val"
                [ngClass]="{
                  'table-warning': vit.daysFalls >= 25 && vit.daysFalls <= 45,
                  'table-danger': vit.daysFalls > 45
                }"
              > -->
              <!-- <div
                class="vital_val"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div> -->
            </ng-container>

            <!-- If Braden -->
            <ng-container *ngIf="vital.val === 'daysBraden'">
              <!-- <div
                class="vital_val"
                [ngClass]="{
                  'table-warning': vit.daysBraden >= 12 && vit.daysBraden < 19,
                  'table-danger': vit.daysBraden >= 6 && vit.daysBraden < 12
                }"
              > -->
              <!-- <div
                class="vital_val"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div> -->
            </ng-container>

            <!-- If Pain -->
            <ng-container *ngIf="vital.val === 'daysPain'">
              <!-- <div
                class="vital_val"
                [ngClass]="{
                  'table-warning': vit.daysPain >= 3 && vit.daysPain < 8,
                  'table-danger': vit.daysPain >= 8
                }"
              > -->
              <!-- <div
                class="vital_val"
                [ngClass]="getVitalPriorityCase1(vital.val, vit[vital.val])"
              >
                {{ vit[vital.val] }}
              </div> -->
            </ng-container>

            <!-- If gcs -->
            <ng-container *ngIf="vital.val == 'daysGCS' && vit['daysGCS']">
              <div
                class="vital_val"
                [ngClass]="{
                  'table-warning':
                    vit['daysGCSverbal'] != 0 &&
                    vit.daysGCS >= 10 &&
                    vit.daysGCS < 13 &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined),
                  'table-danger':
                    vit['daysGCSverbal'] == 0 &&
                    vit.daysGCS >= 2 &&
                    vit.daysGCS < 10 &&
                    (this.currentPatient?.patientType == 'adult' ||
                      this.currentPatient?.patientType == undefined)
                }"
              >
                {{ vit[vital.val] }}
                <span *ngIf="vit['daysGCSverbal'] == 0">T</span>
              </div>
            </ng-container>

            <!-- If gcs verbal -->
            <ng-container *ngIf="vital.val == 'daysGCSverbal'">
              <span class="vital_val">{{
                vit[vital.val] == 0 ? "T" : vit[vital.val]
              }}</span>
            </ng-container>

            <!-- If therapy device -->
            <ng-container *ngIf="vital.val === 'daysTherapyDevice'">
              <div class="vital_val">
                <li *ngFor="let device of vit['daysTherapyDevice']">
                  {{ device }}
                </li>
              </div>
            </ng-container>

            <!-- If I:E -->
            <ng-container *ngIf="vital.val === 'daysIE'">
              <div class="vital_val">
                <span
                  >{{ vit["daysVentI"] || "-" }} :
                  {{ vit["daysVentE"] || "-" }}</span
                >
              </div>
            </ng-container>

            <!-- If airway -->
            <ng-container *ngIf="vital.val === 'daysVentAirway'">
              <div class="vital_val">
                <li *ngFor="let airway of vit['daysVentAirway']">
                  {{ airway }}
                </li>
              </div>
            </ng-container>

            <!-- nasopharyngealAirway -->
            <ng-container *ngIf="vital.val === 'nasopharyngealAirway'">
              <div class="vital_val">
                <div *ngIf="vit.nasopharyngealAirway?.daysVentTubeSize">
                  Size: {{ vit.nasopharyngealAirway.daysVentTubeSize }}
                </div>
              </div>
            </ng-container>

            <!-- oralAirway -->
            <ng-container *ngIf="vital.val === 'oralAirway'">
              <div class="vital_val">
                <div *ngIf="vit.oralAirway?.daysVentTubeSize">
                  Size: {{ vit.oralAirway.daysVentTubeSize }}
                </div>
              </div>
            </ng-container>

            <!-- biteBlock -->
            <ng-container *ngIf="vital.val === 'biteBlock'">
              <div class="vital_val">
                <div *ngIf="vit.biteBlock?.daysVentTubeSize">
                  Size: {{ vit.biteBlock.daysVentTubeSize }}
                </div>
              </div>
            </ng-container>

            <!-- supraglotic -->
            <ng-container *ngIf="vital.val === 'supraglotticAirway'">
              <div class="vital_val">
                <div *ngIf="vit.supraglotticAirway?.daysSupraglotticAirway">
                  Type: {{ vit.supraglotticAirway.daysSupraglotticAirway }}
                </div>
                <div *ngIf="vit.supraglotticAirway?.daysVentTubeSize">
                  Size: {{ vit.supraglotticAirway.daysVentTubeSize }}
                </div>
              </div>
            </ng-container>

            <!-- cooksAirway -->
            <ng-container *ngIf="vital.val === 'cooksAirway'">
              <div class="vital_val">
                <div *ngIf="vit.cooksAirway?.daysVentTubeSize">
                  Size: {{ vit.cooksAirway.daysVentTubeSize }}
                </div>
              </div>
            </ng-container>

            <!-- oralEndotrachealTube -->
            <ng-container *ngIf="vital.val === 'oralEndotrachealTube'">
              <div class="vital_val">
                <div *ngIf="valExists(vit.oralEndotrachealTube?.daysVentCuff)">
                  Cuffed:
                  <ng-container
                    *ngIf="vit.oralEndotrachealTube?.daysVentCuff === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.oralEndotrachealTube?.daysVentCuff === false"
                    >No</ng-container
                  >
                </div>
                <div *ngIf="vit.oralEndotrachealTube?.daysEttTieLevel">
                  ETT tie level: {{ vit.oralEndotrachealTube.daysEttTieLevel }}
                </div>
                <div *ngIf="vit.oralEndotrachealTube?.daysVentTubeSize">
                  Size: {{ vit.oralEndotrachealTube.daysVentTubeSize }}
                </div>
                <div *ngIf="vit.oralEndotrachealTube?.daysVentCuffPressure">
                  Cuff Pressure:
                  {{ vit.oralEndotrachealTube.daysVentCuffPressure }}
                </div>
                <div *ngIf="valExists(vit.oralEndotrachealTube?.daysVentCuff)">
                  Is closed suction:
                  <ng-container
                    *ngIf="vit.oralEndotrachealTube?.isClosedSuction === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.oralEndotrachealTube?.isClosedSuction === false"
                    >No</ng-container
                  >
                </div>
              </div>
            </ng-container>

            <!-- nasalEndotrachealTube -->
            <ng-container *ngIf="vital.val === 'nasalEndotrachealTube'">
              <div class="vital_val">
                <div *ngIf="valExists(vit.nasalEndotrachealTube?.daysVentCuff)">
                  Cuffed:
                  <ng-container
                    *ngIf="vit.nasalEndotrachealTube?.daysVentCuff === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.nasalEndotrachealTube?.daysVentCuff === false"
                    >No</ng-container
                  >
                </div>
                <div *ngIf="vit.nasalEndotrachealTube?.daysEttTieLevel">
                  ETT tie level: {{ vit.nasalEndotrachealTube.daysEttTieLevel }}
                </div>
                <div *ngIf="vit.nasalEndotrachealTube?.daysVentTubeSize">
                  Size: {{ vit.nasalEndotrachealTube.daysVentTubeSize }}
                </div>
                <div *ngIf="vit.nasalEndotrachealTube?.daysVentCuffPressure">
                  Cuff Pressure:
                  {{ vit.nasalEndotrachealTube.daysVentCuffPressure }}
                </div>
                <div *ngIf="valExists(vit.nasalEndotrachealTube?.daysVentCuff)">
                  Is closed suction:
                  <ng-container
                    *ngIf="vit.nasalEndotrachealTube?.isClosedSuction === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.nasalEndotrachealTube?.isClosedSuction === false"
                    >No</ng-container
                  >
                </div>
              </div>
            </ng-container>

            <!-- tracheostomyTube -->
            <ng-container *ngIf="vital.val === 'tracheostomyTube'">
              <div class="vital_val">
                <div *ngIf="valExists(vit.tracheostomyTube?.daysVentCuff)">
                  Cuffed:
                  <ng-container
                    *ngIf="vit.tracheostomyTube?.daysVentCuff === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.tracheostomyTube?.daysVentCuff === false"
                    >No</ng-container
                  >
                </div>
                <div *ngIf="vit.tracheostomyTube?.daysVentTubeSize">
                  Size: {{ vit.tracheostomyTube.daysVentTubeSize }}
                </div>
                <div *ngIf="vit.tracheostomyTube?.daysVentCuffPressure">
                  Cuff Pressure:
                  {{ vit.tracheostomyTube.daysVentCuffPressure }}
                </div>
                <div *ngIf="valExists(vit.tracheostomyTube?.daysVentCuff)">
                  Is closed suction:
                  <ng-container
                    *ngIf="vit.tracheostomyTube?.isClosedSuction === true"
                    >Yes</ng-container
                  >
                  <ng-container
                    *ngIf="vit.tracheostomyTube?.isClosedSuction === false"
                    >No</ng-container
                  >
                </div>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-container
    class="zoomedImageCanvasParent"
    *ngIf="showImageCanvas; then zoomModal"
  ></ng-container>
</section>

<ng-template #zoomModal>
  <div class="zoomedImageCanvasParent" [ngClass]="{ show: appearCanvas }">
    <button class="close" (click)="closeCanvas()" mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <app-img-canvas
      [image]="zoomedImage"
      [canvasWidth]="900"
      [canvasHeight]="700"
    ></app-img-canvas>
  </div>
</ng-template>

<!-- <ng-template #noDataFound>
  <div class="no_data_found_block">No vitals found</div>
</ng-template> -->
