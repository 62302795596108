<div class="vital-info">
  <div class="left-side">
    <div *ngIf="vitals?.length" class="day-selector">
      <button
        [disabled]="disablePrev"
        matRipple
        (click)="changeDay('prev')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_left</mat-icon
        >
      </button>

      <mat-form-field [floatLabel]="'always'">
        <mat-select
          [value]="selectedDay"
          (selectionChange)="dayChanged($event.value)"
          class="iris-input"
        >
          <mat-option *ngFor="let day of vitals | uniqueDay" [value]="day"
            >Day {{ day }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        [disabled]="disableNext"
        matRipple
        (click)="changeDay('next')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_right</mat-icon
        >
      </button>

      <mat-form-field floatLabel="always" class="date-selector">
        <input
          class="date-picker-input"
          [matDatepicker]="dob"
          placeholder="yyyy/mm/dd"
          [value]="selectedDate"
          [matDatepickerFilter]="dateFilter"
          (dateChange)="onClickOfDate($event?.value)"
          matInput
        />
        <mat-label></mat-label>
        <mat-datepicker-toggle matSuffix [for]="dob"> </mat-datepicker-toggle>
        <mat-datepicker #dob></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="right-side" *ngIf="displayGraphButtons">
    <div
      class="nice-chart-btn"
      *ngIf="currPatient?.patientType === patientType.Neonatal"
    >
      <button class="button-secondary" (click)="openNiceChartTrends()">
        View NICE chart
      </button>
      <button class="button-secondary" (click)="openGrownTrends()">
        View Growth chart
      </button>
    </div>
  </div>
</div>

<ng-template #newGenderBlock>
  <div class="iris-modal-container">
    <div class="form-title">
      <ng-container *ngIf="isGenderOthers"
        >Since the gender of the patient is ‘Other’, </ng-container
      >Please select the gender to generate the growth chart
    </div>
    <div class="form-body">
      <label
        mat-flat-button
        *ngFor="let sex of sexList; index as i"
        [for]="'f2Sex' + i"
        class="secondary-checkbox display-flex center form__covid-label"
        style="width: '49%'"
        [ngClass]="{
          mr: i == 0,
          active: genderControl?.value === sex?.value
        }"
      >
        {{ sex?.label }}
        <input
          [id]="'f2Sex' + i"
          hidden
          type="radio"
          [value]="sex?.value"
          [formControl]="genderControl"
        />
      </label>
      <mat-dialog-actions align="end">
        <button
          type="button"
          class="button-secondary"
          mat-flat-button
          mat-dialog-close
        >
          Cancel
        </button>
        <button
          type="submit"
          class="button-primary"
          mat-flat-button
          [disabled]="genderControl?.invalid || isAutoOpenGrowth"
          (click)="saveGender()"
        >
          Submit
          <app-loader
            [buttonLoader]="true"
            *ngIf="isAutoOpenGrowth"
          ></app-loader>
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</ng-template>
