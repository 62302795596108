import { tap } from "rxjs/operators";
// import { Socket } from 'ngx-socket-io';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as documentsActions from "../../store/actions/patient-chart/documents/documents.actions";

import { socket } from "../../services/socket.service";
@Injectable()
export class SocketEffects {
  constructor(private actions$: Actions) {}

  getSignedUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(documentsActions.sendTaskThroughSocket),
        tap(({ task }) => {
          if (task) {
            socket.emit("task", task);
          }
        })
      ),
    { dispatch: false }
  );
}
