import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import * as fromGrowthTrendsReducer from "./growth-trends.reducer";
import * as fromTrendsCommonReducer from "./trend-common.reducer";
import { TrendsActionReducerMapInterface } from "../state";

export const reducer: ActionReducerMap<TrendsActionReducerMapInterface> = {
  trendsCommon: fromTrendsCommonReducer.reducer,
  growthTrends: fromGrowthTrendsReducer.reducer,
};

export const getTrendsState =
  createFeatureSelector<TrendsActionReducerMapInterface>("trends-module");
