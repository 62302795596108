import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrendsComponent } from "./components/old-trends/trends.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";
import { TrendsBaseComponent } from "./components/trends-base/trends-base.component";
import { GrowthChartComponent } from "./components/growth-chart/growth-chart.component";
import { StoreModule } from "@ngrx/store";
import { reducer } from "./store/reducers";
import { TrendsLeftPanelComponent } from "./components/trends-left-panel/trends-left-panel.component";
import { GrowthTableComponent } from "./components/growth-chart/growth-table/growth-table.component";
import { TimezonePipeModule } from "@shared-modules/pipes/timezone-pipe/timezone-pipe.module";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { GrowthChartSupportService } from "./services/growth-chart-support.service";
@NgModule({
  declarations: [
    TrendsComponent,
    TrendsBaseComponent,
    GrowthChartComponent,
    TrendsLeftPanelComponent,
    GrowthTableComponent,
  ],
  imports: [
    CommonModule,
    // MatSelectModule,
    // MatDialogModule,
    // MatTabsModule,
    // MatTableModule,
    // MatIconModule,
    IrisComponentsModule,

    TimezoneLabelModule,
    TimezonePipeModule,

    // popover-module

    // NgRX Store
    StoreModule.forFeature("trends-module", reducer),
  ],
  providers: [GrowthChartSupportService],
  exports: [TrendsComponent],
})
export class TrendsModule {}
