export interface ConfirmDialogInterface {
  message?: string;
  buttonText?: string;
  alertType?: "accept" | "reject" | null;
  headerText?: string;
  secondaryButtonText?: string;
  width?: string;
}

export const DATA_CONFIG: ConfirmDialogInterface = {
  message: "",
  buttonText: "Continue",
  alertType: "accept",
  headerText: "Attention",
  secondaryButtonText: "Cancel",
  width: "462px",
};
