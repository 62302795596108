import {
  GROWTH_TRENDS_HEIGHT,
  GROWTH_TRENDS_WIDTH,
  TRENDS_DEFAULT_HEIGHT,
  TRENDS_DEFAULT_WIDTH,
} from "../constants/new-trends.data";
import { TrendType } from "../constants/trends.data";

export const getGraphHeightWidth = (trendType: TrendType) => {
  switch (trendType) {
    case TrendType.growthChart:
      return [GROWTH_TRENDS_HEIGHT, GROWTH_TRENDS_WIDTH];
    default:
      return [TRENDS_DEFAULT_HEIGHT, TRENDS_DEFAULT_WIDTH];
  }
};
