import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromPatDataReducer from "./pat-header.reducer";
import * as fromPatFormReducer from "./patient-form.reducer";
import { AppState } from "src/app/reducers";

export interface PatientHeaderState {
  data: fromPatDataReducer.State;
  form: fromPatFormReducer.State;
}

export interface State extends AppState {
  patientHeader: PatientHeaderState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromPatDataReducer.reducer,
  form: fromPatFormReducer.reducer,
};

/** SELECTOR setup */
export const getPatHeaderState =
  createFeatureSelector<PatientHeaderState>("patientHeader");

export const getPatHeaderData = createSelector(
  getPatHeaderState,
  (state) => state.data
);

export const isGcs = createSelector(getPatHeaderData, (state) => state.isGcs);

export const getRox = createSelector(getPatHeaderData, (state) => state.rox);

export const getpatForm = createSelector(
  getPatHeaderState,
  (state) => state.form
);

export const isFormLoaded = createSelector(
  getpatForm,
  (state) => state.loading
);

export const showSuccessScreenOne = createSelector(
  getpatForm,
  (state) => state.showSuccessScreenOne
);

export const showSuccessScreenTwo = createSelector(
  getpatForm,
  (state) => state.showSuccessScreenTwo
);

export const getCurrentPage = createSelector(
  getpatForm,
  (state) => state.currentPage
);

export const isNewPatient = createSelector(
  getpatForm,
  (state) => state.isNewPatient
);

export const getICUDischargeDate = createSelector(
  getPatHeaderData,
  fromPatDataReducer.getICUDischargeDate
);

export const getICUAdmitDate = createSelector(
  getPatHeaderState,
  (patient) => patient.data.ICUAdmitDate
);

export const getPatientCPMRNEncounters = createSelector(
  getPatHeaderData,
  (data) => ({ cpmrn: data.CPMRN, encounters: data.encounters })
);

export const getPatientHospitalName = createSelector(
  getPatHeaderData,
  (patient) => patient.hospitalName
);

export const getPatientHospitalAndUnitName = createSelector(
  getPatHeaderData,
  (patient) => ({
    hospitalName: patient.hospitalName,
    unitName: patient.unitName,
  })
);

/**
 * Description: used to get patient's hospital timezone
 */
export const getPatientHospitalTimeZone = createSelector(
  getPatHeaderData,
  (patient) => (patient?.hospitalInfo as any)?.timeZoneDetail
);

/**
 * Check if bedside order can placed for patient.
 * If a unit is cp managed then bedside option is disabled.
 *
 * @return {boolean}
 */
export const canPlaceBedsideOrder = createSelector(
  getPatHeaderData,
  (patient) => {
    if (
      !patient.unitName ||
      !patient.hospitalInfo ||
      !(patient.hospitalInfo as any)?.units?.length
    ) {
      return true;
    }

    // @ts-ignore
    const unit = patient.hospitalInfo.units.filter(
      (unitData) => unitData.name === patient.unitName
    );

    return !unit[0]?.cpManaged;
  }
);
