import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CounterComponent } from "./counter/counter.component";
import { AlertComponent } from "./alert/alert.component";
import { MatCardModule } from "@angular/material/card";
import { PortalModule } from "@angular/cdk/portal";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { LabelBadgeComponent } from "./label-badge/label-badge.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
// import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { IrisInputComponent } from "./iris-forms/iris-input/iris-input.component";
import { FieldErrorsComponent } from "./iris-forms/field-errors/field-errors.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { AvatarComponent } from "./avatar/avatar.component";
import { PopoverComponent } from "./popover/popover.component";
import { PopoverDirective } from "./directives/popover.directive";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { BtnTagComponent } from "./btn-tag/btn-tag.component";
import { IrisDesignSystemComponent } from "./iris-design-system/iris-design-system.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSortModule } from "@angular/material/sort";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MAT_DATE_FORMATS, MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { DateTimePickerComponent } from "./date-time-picker/date-time-picker.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { CarouselComponent } from "./carousel/carousel.component";
import { OverlayComponent } from "./overlay/overlay.component";
import { ImgCanvasComponent } from "../shared/img-canvas/img-canvas.component";
import { IconModule } from "../icon/icon.module";
import { ZoomPanContainerComponent } from "./zoom-pan-container/zoom-pan-container.component";
import { HammerModule } from "@angular/platform-browser";
import { AlertInlineComponent } from "./alert-inline/alert-inline.component";
import { ZoomTransformService } from "./service/zoom-transform.service";
import { ConfimDialogComponent } from "./confim-dialog/confim-dialog.component";
import { DialogService } from "./service/dialog.service";
import { MatSliderModule } from "@angular/material/slider";
import { ChoiceListComponent } from "./choice-list/choice-list.component";
import { LoaderModule } from "./loader/loader.module";
import { TimezoneLabelModule } from "@shared-modules/components/timezone-label/timezone-label.module";
import { EditInputComponent } from "./edit-input/components/edit-input/edit-input.component";
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
const CustomComponents = [
  AlertComponent,
  CounterComponent,
  LabelBadgeComponent,
  ConfimDialogComponent,
  // BreadcrumbComponent,
  IrisInputComponent,
  FieldErrorsComponent,
  AvatarComponent,
  PopoverComponent,
  PopoverDirective,
  ProgressBarComponent,
  BtnTagComponent,
  DateTimePickerComponent,
  CarouselComponent,
  OverlayComponent,
  ChoiceListComponent,
  EditInputComponent,
];

const MaterialComponents = [
  MatCardModule,
  MatSnackBarModule,
  MatChipsModule,
  MatListModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatFormFieldModule,
  MatDialogModule,
  MatButtonModule,
  MatTabsModule,
  MatSortModule,
  MatCheckboxModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatDatepickerModule,
  // MatNativeDateModule,
  MatMomentDateModule,
  MatTooltipModule,
  MatExpansionModule,
  PortalModule,
  MatSliderModule,
];

@NgModule({
  declarations: [
    ...CustomComponents,
    IrisDesignSystemComponent,
    DateTimePickerComponent,
    OverlayComponent,
    ZoomPanContainerComponent,
    AlertInlineComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    HammerModule,
    LoaderModule,
    ReactiveFormsModule,
    ...MaterialComponents,
    TimezoneLabelModule,
  ],
  exports: [
    CounterComponent,
    AvatarComponent,
    PopoverComponent,
    PopoverDirective,
    ProgressBarComponent,
    LoaderModule,
    CommonModule,
    ZoomPanContainerComponent,
    ...CustomComponents,
    ...MaterialComponents,
  ],
  providers: [
    DialogService,
    ZoomTransformService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["l", "LL"],
        },
        display: {
          dateInput: "YYYY/MM/DD",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class IrisComponentsModule {}
