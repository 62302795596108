import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

/** To set the size of avatar */

export const AVATAR_SIZE = {
  XS: "xtraSmall",
  S: "small",
  M: "medium",
  L: "large",
  XL: "xtraLarge",
};

/** To set the status of the user */

export const AVATAR_STATUS = {
  ACTIVE: "active",
  AWAY: "away",
};

export interface AvatarParams {
  /** Name of the person whose initials will be displayed in avatar */

  name?: string;

  /** To display an image in avatar */

  imageUrl?: string;

  /** Size of avatar could be large, medium, small and xtraSmall.
   *  Size should be set from the constant AVATAR_SIZE */

  size: string;

  /** Status is to show whether a user is active or away.
   *  Status should be set from the constant AVATAR_STATUS */

  status?: string;
}

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() avatarParams: AvatarParams[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avatarParams && this.avatarParams?.length) this.setInitials();
  }

  ngOnInit() {
    if (this.avatarParams?.length) this.setInitials();
  }

  /** To set the initials from given name */

  setInitials() {
    this.avatarParams.forEach((avatar) => {
      if (avatar.name) {
        const [first, last] = avatar.name.split(" ");

        let initials = "";

        initials += first ? first.split("")[0].toUpperCase() : "";
        initials += last ? last.split("")[0].toUpperCase() : "";

        avatar["initials"] = initials;
      }
    });
  }
}
