import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { ncpParams } from "../../data/data";

import { NcpService } from "../../service/ncp.service";
import { Store } from "@ngrx/store";
import * as fromNcpAction from "../../store/action/ncp.action";

@Component({
  selector: "app-ncp-modal",
  templateUrl: "./ncp-modal.component.html",
  styleUrls: ["./ncp-modal.component.scss"],
})
export class NcpModalComponent implements OnInit {
  public ncpParams = ncpParams;
  constructor(
    public dialogRef: MatDialogRef<NcpModalComponent>,
    public fb: FormBuilder,
    public ncpService: NcpService,
    public store: Store<any>
  ) {}
  @ViewChild("search") searchTextBox: ElementRef;

  public selectedNcpList;
  public diagnosisData;
  public modifiedData;
  public otherDiagnosis = "";
  public isOther: boolean = false;

  ncpSubmitForm: FormGroup;
  ncpForm: UntypedFormGroup = this.fb.group({
    diagnose: this.fb.group({
      name: ["", [Validators.required]],
      value: [""],
    }),
    parameters: ["", [Validators.required]],
  });

  get diagnosisValue(): AbstractControl {
    return this.ncpForm.get("diagnose.value");
  }
  ngOnInit(): void {
    //calling api to get diagnoses list @sheethalkhan  08-07-2023
    this.ncpService.getdiagnoses().subscribe((data) => {
      this.diagnosisData = data.data;
      this.modifiedData = this.diagnosisData.map((name) => ({ name }));
    });

    //for showing selectedlist in dropdown feild @sheethalkhan  07-07-2023
    // this.ncpList$.subscribe((data) => {
    //   const diagnoses = data.map((item) => item.diagnosis);

    //   this.selectedNcpList = diagnoses.map((item) => ({ name: item }));

    // });
  }

  onClose() {
    this.dialogRef.close();
  }

  //setting validation if "other" is selected @sheethalkhan  08-07-2023
  getDiagnosisList(event) {
    this.isOther = false;
    this.diagnosisValue.clearValidators();
    for (const element of event) {
      if (element.name === "Other") {
        this.isOther = true;
        this.diagnosisValue.setValidators([Validators.required]);
        break;
      }
    }
    this.diagnosisValue.updateValueAndValidity();
  }

  Submit() {
    //setting value of "other" to diagnosis array @sheethalkhan  08-07-2023
    if (this.isOther) {
      this.ncpForm.value.diagnose.name.forEach((element) => {
        if (element.name === "Other") {
          element.name = this.ncpForm.value.diagnose.value;
        }
      });
    }

    //removing name key
    let updatediagnosisArray = this.ncpForm.value.diagnose.name.map(
      (obj) => obj.name
    );

    let updateparamArray = this.ncpForm.value.parameters.map((obj) => obj.name);
    //transforming parameters into lowercase without space
    let transformedArray = updateparamArray.map((item) =>
      item.toLowerCase().replace(/\s/g, "")
    );

    //form which is submitting
    this.ncpSubmitForm = this.fb.group({
      ncpEntries: this.fb.array([]),
    });

    const ncpEntriesArray = this.ncpSubmitForm.get("ncpEntries") as FormArray;

    updatediagnosisArray.forEach((entry) => {
      const entryGroup = this.fb.group({
        createdAt: "",
        diagnose: [entry],
        fields: this.fb.group({}),
      });

      const fieldsGroup = entryGroup.get("fields") as FormGroup;

      transformedArray.forEach((element) => {
        fieldsGroup.addControl(element, new FormControl(""));
      });

      ncpEntriesArray.push(entryGroup);
    });

    this.store.dispatch(
      fromNcpAction.addInputData({
        data: this.ncpSubmitForm.value.ncpEntries,
        isEdit: false,
      })
    );
    this.dialogRef.close();
  }
}
