import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  NgZone,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";

import Compressor from "compressorjs";
import { Store, select } from "@ngrx/store";
import { BackendError } from "src/app/models/backend-error.model";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from "src/app/services/file.service";

import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import * as fromChatIndex from "src/app/patient/chat/store/reducers";
import * as fromChatDocuments from "src/app/labs-scans-module/store/reducers";
import { ChatService } from "src/app/patient/chat/services/chat.service";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faFilePdf,
  faFolderPlus,
  faPaperclip,
  faPaperPlane,
  faRedoAlt,
  faSearchPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { socket } from "src/app/services/socket.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "src/app/iris-components/avatar/avatar.component";
import { PatientService } from "src/app/services/patient.service";
import { OverlayComponent } from "src/app/iris-components/overlay/overlay.component";
import { FileObj } from "src/app/iris-components/model/carousel.model";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
import { fileType, FileTypes } from "src/app/models/file/File.model";
import { UploadFileInterface } from "../../models/chat.model";
import { dataURLtoFile } from "src/app/support-functions/base64ToFile";
import * as fromHospitalIndex from "src/app/store/reducers/hospitals/index";
import { TimeZoneDetails } from "src/app/models/hospital";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent extends UserRolesMap implements OnInit, OnChanges {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public chatMessagesOriginal: any = [];
  public chatMessages: any = [];
  public fileUploadSubscription: Subscription[] = [];

  // debounce timer
  public inDebounce;
  public fileTypes = FileTypes;
  @Input() user;
  @Input() currPatient;
  isUploadLoader: boolean = false;
  @Input()
  set chatData(chatData) {
    if (chatData) {
      this.chatMessagesOriginal = chatData;
      this.chatMessages = this.chatMessagesOriginal;
      this.setAvatarParams();
    }
  }
  @Input()
  chatType: string = "patient-chart";

  @ViewChild("overlayComp") overlayComp: OverlayComponent;

  @Input()
  unit: any = {};

  @Output() inputPopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() unitScrollEnd: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("chatEnterArea", { static: false })
  chatEnterArea: ElementRef;

  public videoSrc: string = "";

  public chatStatus$ = this.store.pipe(
    select(fromChatIndex.getChatStatus),
    takeUntil(this.unsubscribe$)
  );
  public chatReciepts$ = this.store.pipe(
    select(fromChatIndex.getChatReceipts),
    takeUntil(this.unsubscribe$)
  );
  public chatDraft$ = this.store.pipe(
    select(fromChatIndex.getDraftChat),
    takeUntil(this.unsubscribe$)
  );
  public chatMinimised$ = this.store.pipe(
    select(fromChatIndex.getChatMinimised),
    takeUntil(this.unsubscribe$)
  );

  public chatNotificationStatus$ = this.store.pipe(
    select(fromChatIndex.getNotificationStatus),
    takeUntil(this.unsubscribe$)
  );
  public chatDocs$ = this.store.pipe(
    select(fromChatDocuments.getDocs),
    takeUntil(this.unsubscribe$)
  );
  public chatTabOpen$ = this.store.pipe(
    select(fromChatIndex.getChatTabOpen),
    takeUntil(this.unsubscribe$)
  );

  public hospitals$ = this.store.pipe(
    select(fromHospitalIndex.getHospitals),
    takeUntil(this.unsubscribe$)
  );

  public chatMessage: string = null;

  public attchObj: UploadFileInterface | null = null;
  public fileValidationError: string | null = null;
  public backendError: BackendError[] | null = null;
  public chatReciept: any;
  public lastReadPoint: number = 0;
  public allReadUsers: any = [];
  public showNotification: boolean;
  public showUnreadMessage: boolean;
  public chatTabOpen: boolean;
  public isDischargeTimeElapsed: boolean;

  /* ICONS */
  faImage = faImage;
  faFilePdf = faFilePdf;
  faFolderPlus = faFolderPlus;
  faSearchPlus = faSearchPlus;
  faCheckCircle = faCheckCircle;
  faPaperClip = faPaperclip;
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;
  faRedoAlt = faRedoAlt;
  faTrashAlt = faTrashAlt;

  avatarParamsArr: AvatarParams[][] = [];
  public timeZoneDetail: TimeZoneDetails;

  constructor(
    private router: Router,
    private store: Store<{}>,
    private sanitisor: DomSanitizer,
    private _fileService: FileService,
    private ngZone: NgZone,
    private _chatService: ChatService,
    private _patientService: PatientService
  ) {
    super();
  }

  ngOnInit() {
    this.hospitals$.subscribe((data) => {
      if (this.currPatient?.hospitalInfo?.timeZoneDetail) {
        this.timeZoneDetail = this.currPatient?.hospitalInfo?.timeZoneDetail;
        return;
      }

      const foundHosp = data?.find(
        (hospObj) => hospObj?.name === this.unit?.hospitalInfo?.name
      );
      this.timeZoneDetail =
        (foundHosp && foundHosp?.timeZoneDetail) || undefined;
    });

    // when we switch to chat tab, we go to bottom of chat
    this.chatTabOpen$.subscribe((isOpen) => {
      this.chatTabOpen = isOpen;
      if (isOpen) {
        if (this.showNotification) this.setUnreadMessagePoint();
        else {
          this.showUnreadMessage = false;
          this.goToBottomOfChat();
        }
        this.store.dispatch(chatActions.hideNotification());
        this.socketUpdateLastSeen();
      }
    });

    this.chatNotificationStatus$.subscribe((showNotif) => {
      this.showNotification = showNotif;

      if (this.chatTabOpen && showNotif) {
        this.socketUpdateLastSeen();
      }
    });

    // subscribe to chat status
    this.chatStatus$.subscribe((data) => {
      if (data["chatSubmitSuccess"]) {
        this.attchObj = null;
        this.chatMessage = null;

        let attachmentField = <HTMLInputElement>(
          document.getElementById("attachmentField")
        );
        if (attachmentField) {
          attachmentField.value = "";
        }

        this.goToBottomOfChat();
      }
    });

    // get chat reciepts
    this.chatReciepts$.subscribe((data) => {
      if (data.length) {
        this.allReadUsers = data;

        // get the read reciept of current user
        this.chatReciept = data.find((reciept) => {
          return reciept.email == this.user.email;
        });
      }
    });

    // focus on the elem
    if (this.chatEnterArea) this.chatEnterArea.nativeElement.focus();

    // get draft
    this.chatDraft$.subscribe((draft) => {
      if (draft) this.chatMessage = draft;
      else this.chatMessage = null;
    });

    if (this.chatType === "unit-chat") {
      this.setUnreadMessagePoint();
      this.socketUpdateLastSeen();
    }
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currPatient && this.chatType === "patient-chart") {
      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(this.currPatient);
    }
  }

  setAvatarParams() {
    if (this.avatarParamsArr.length === 0) {
      this.chatMessages.forEach((chat) => {
        this.avatarParamsArr.push([
          {
            name: chat.senderName,
            size: AVATAR_SIZE.L,
          },
        ]);
      });
    } else {
      this.avatarParamsArr.push([
        {
          name: this.chatMessages[this.chatMessages.length - 1].senderName,
          size: AVATAR_SIZE.L,
        },
      ]);
    }
  }

  goToMessageReplied(refMsg, index) {
    let refTime = new Date(refMsg.timestamp).getTime();
    let indexToScroll = -1;

    for (let i = index; i >= 0; i--) {
      const chat = this.chatMessages[i];
      let chatTime = new Date(chat.timestamp).getTime();

      if (chatTime == refTime) {
        indexToScroll = i;
        break;
      }
    }

    if (indexToScroll >= 0) {
      let pointer = document.getElementById("chat-no-" + indexToScroll);
      if (pointer) {
        let topVal = pointer.offsetTop;
        this.goToBottomOfChat(topVal);

        // set border
        pointer.style.background = "#9ad1d8";
        pointer.style.transitionProperty = "background";
        pointer.style.transitionDuration = "1s";

        setTimeout(() => {
          pointer.style.background = "none";
        }, 1000);
      }
    }
  }

  getChatData(timestamp: string) {
    let refTime = new Date(timestamp).getTime();
    return this.chatMessages.find((chat) => {
      let chatTime = new Date(chat.timestamp).getTime();
      return chatTime == refTime;
    });
  }

  socketUpdateLastSeen() {
    let delay = 100;
    const lastChat = this.chatMessages[this.chatMessages.length - 1];
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => {
      let userChatReciept = this.allReadUsers.filter(
        (rec) => rec.email == this.user.email
      );

      if (this.chatMessages && this.chatMessages.length) {
        let userReciept: any;
        let emitLastSeen = true;

        if (!userChatReciept[0]) {
          userReciept = {
            name: this.user.name,
            email: this.user.email,
            lastRead: this.chatMessages[this.chatMessages.length - 1].timestamp,
          };
        } else {
          if (
            new Date(userChatReciept[0].lastRead).getTime() ==
            new Date(
              this.chatMessages[this.chatMessages.length - 1].timestamp
            ).getTime()
          ) {
            emitLastSeen = false;
          }

          userReciept = {
            ...userChatReciept[0],
            lastRead: this.chatMessages[this.chatMessages.length - 1].timestamp,
            name: this.user.name,
          };
        }

        if (emitLastSeen) {
          this.store.dispatch(
            chatActions.setLastSeen({ user: this.user, lastChat })
          );
          socket.emit("setLastSeenOfUser", {
            chatType: this.chatType,
            unit: {
              hospitalName: this.unit.hospitalInfo?.name,
              unitName: this.unit.name,
            },
            CPMRN: this.currPatient.CPMRN,
            encounters: this.currPatient.encounters,
            readReciept: userReciept,
          });
        }
      }
    }, delay);
  }

  public refMessage = null;
  replyToThisMessage(chat) {
    this.refMessage = chat;
    this.chatEnterArea.nativeElement.focus();
  }

  setUnreadMessagePoint() {
    // get the index last seen
    let foundIndex = -1;
    if (this.chatReciept && this.chatReciept.lastRead) {
      for (let i = this.chatMessages.length - 1; i >= 0; i--) {
        const chat = this.chatMessages[i];

        if (
          new Date(chat.timestamp).getTime() <=
          new Date(this.chatReciept.lastRead).getTime()
        ) {
          foundIndex = i;
          break;
        }
      }
    }

    // set the pos based on found index
    if (foundIndex >= 0) {
      this.chatMessages = JSON.parse(JSON.stringify(this.chatMessagesOriginal));
      if (foundIndex < this.chatMessages.length - 1) {
        this.chatMessages[foundIndex]["new"] = true;
        this.showUnreadMessage = true;
      }
    } else {
      this.chatMessages = JSON.parse(JSON.stringify(this.chatMessagesOriginal));
    }

    // set the scroll position
    setTimeout(() => {
      let pointer = document.getElementById("unread_message_point");
      if (pointer) {
        let topVal = pointer.offsetTop;
        this.goToBottomOfChat(topVal);
      } else {
        this.goToBottomOfChat();
      }
    }, 0);
  }

  cancelReply() {
    this.refMessage = null;
  }

  goToBottomOfChat(val = null) {
    let objDiv = document.getElementById("chat-area");

    if (objDiv) {
      let pos = val;
      if (!val) {
        pos = objDiv.scrollHeight;
      }

      objDiv.scrollTop = pos;
    }
  }

  public isRecieptOpen: Boolean = false;
  public clickedMessage: any = null;
  openReadReciepts(chat) {
    let readBy = this.allReadUsers.filter((user) => {
      return (
        new Date(user.lastRead).getTime() >= new Date(chat.timestamp).getTime()
      );
    });

    this.isRecieptOpen = true;
    this.clickedMessage = { ...chat, readBy: readBy };
  }

  addToDocs(chat) {
    // minimise the popup
    this.inputPopup.emit(false);
    // passing chat id for RPA
    const imageData = {
      _id: chat._id,
      key: chat.attachmentLink.key,
      link: chat.image,
      fileType: chat.attachmentLink.fileType,
      name: chat.attachmentLink.name,
      size: chat.attachmentLink.size,
    };

    // set the image key in store
    this.store.dispatch(chatActions.addToDoc({ imageData }));

    // navigate to scans and labs
    this.router.navigate([
      "patient",
      this.currPatient.CPMRN,
      this.currPatient.encounters,
      "labs",
    ]);
    this.chatDocs$.subscribe((data) => {
      chat.documentAdded = data.documentAdded;
    });
  }

  openDocInput() {
    this.attchObj = null;
    document.getElementById("attachmentField").click();
  }

  resetErrorMessages(): void {
    this.fileValidationError = null;
    this.backendError = null;
  }

  public attachNames: string = "";

  rotateBase64Image90deg(base64Image, isClockwise, index) {
    //create an off-screen canvas
    let offScreenCanvas = document.createElement("canvas");
    let offScreenCanvasCtx = offScreenCanvas.getContext("2d");

    // cteate Image
    var img = new Image();
    img.src = base64Image;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    // rotate and draw source image into the off-screen canvas:
    if (isClockwise) {
      offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
    } else {
      offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
    }
    offScreenCanvasCtx.drawImage(img, 0, 0);

    // encode image to data-uri with base64
    this.attchObj["fileUrl"] = offScreenCanvas.toDataURL("image/jpeg", 100);
    this.attchObj["rotate"] = true;
  }

  public async onFileUpload(
    fileAttachmentObj: UploadFileInterface
  ): Promise<void> {
    this.attchObj = fileAttachmentObj;
    if (!fileAttachmentObj.isFileLoading) return;
    // For video we are uploading in advance.
    try {
      const uploadedData = await this.attachmentUpload();
      if (!this.attchObj) return;
      const fileUrl = await this.getFile(uploadedData);
      this.attchObj.fileUrl = fileUrl;
      this.attchObj.isFileLoading = false;
      this.attchObj.key = uploadedData.key;
    } catch (err) {
      this.attchObj.isFileLoading = false;
      this.attchObj["fileUrl"] = null;
      this.fileValidationError = "Something went wrong!";
      console.error(err);
    }
  }

  clearAttachments() {
    this.isViewAttchOpen = false;
    this.attchObj = null;
    this.fileUploadSubscription.forEach((subscriptions) => {
      subscriptions.unsubscribe();
    });
    this.fileUploadSubscription = [];

    let attachmentField = <HTMLInputElement>(
      document.getElementById("attachmentField")
    );
    if (attachmentField) {
      attachmentField.value = "";
    }
  }
  private tempPdfsLoaded = [];
  async downloadImage(chat) {
    try {
      const dataToAdd = await this.getFile(chat.attachmentLink);
      chat["image"] = dataToAdd;
      chat["imageLoaded"] = true;
      this.store.dispatch(chatActions.updateChat({ message: chat }));
    } catch (err) {
      console.error(err);
    }
  }

  public isViewAttchOpen: Boolean = false;

  ngOnDestroy() {
    // remove scroll event listener
    // let chatArea = document.getElementById("chat-area");
    // chatArea.removeEventListener('scroll', this.scroll, true);

    // save draft
    this.overlayComp.closeModal();
    if (this.chatType == "patient-chart") {
      if (this.chatMessage && this.chatMessage.trim() != "") {
        this.saveChatDraft(this.chatMessage.trim());
      } else {
        this.saveChatDraft("");
      }
    }

    // sanitize pdfs
    if (this.tempPdfsLoaded.length) {
      this.tempPdfsLoaded.forEach((chat) => {
        chat.imageLoaded = false;
        chat.image = null;
        this.store.dispatch(chatActions.updateChat({ message: chat }));
      });
      this.tempPdfsLoaded = [];
    }

    // unsubscribe
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveChatDraft(message) {
    this.store.dispatch(chatActions.saveDraftChat({ message }));
  }

  private delay;
  scroll = (event: any): void => {
    clearTimeout(this.delay);

    this.delay = setTimeout(() => {
      let windowHeight = event.target.scrollTop + event.target.offsetHeight;
      let scrollHeight = event.target.scrollHeight;

      if (windowHeight == scrollHeight) {
        this.ngZone.run(() => {
          // set last seen
          this.socketUpdateLastSeen();
        });
      }
    }, 500);
  };

  public zoomedChat: FileObj[] = [];
  openZoomedView(chat) {
    if (chat?.attachmentLink.fileType == "application/pdf") {
      this.zoomedChat = [
        {
          fileType: "pdf",
          pdfUrl: chat["image"],
          name: chat?.attachmentLink?.name,
        },
      ];
    } else {
      this.zoomedChat = [
        {
          fileType: "image",
          imgData: chat["image"],
          name: chat?.attachmentLink?.name,
        },
      ];
    }
    this.overlayComp.openModal();
    this.videoSrc = null;
  }

  public closeZoomedView(): void {
    this.overlayComp.closeModal();
  }

  async detectKey(event) {
    let key = event.keyCode;

    if (key == 13) {
      this.chatType == "patient-chart"
        ? await this.submitMessagePC()
        : this.submitMessageUL();
    }
  }

  // strike off message
  public isStrikeOffOpen: boolean = false;
  openStrikeOffModal(val, message = null) {
    this.isStrikeOffOpen = val;
    this.clickedMessage = message;
  }
  strikeOffMessage() {
    if (this.clickedMessage) {
      if (this.chatType == "patient-chart") {
        delete this.clickedMessage.image;
        this.clickedMessage.imageLoaded = false;
        // this.store.dispatch(chatActions.strikeOffPcChat({CPMRN:this.currPatient.CPMRN, encounters: this.currPatient.encounters, message: this.clickedMessage}))
        this._chatService
          .strikeOffPatChat(
            this.currPatient.CPMRN,
            this.currPatient.encounters,
            this.clickedMessage
          )
          .subscribe(
            (data) => {
              this.isStrikeOffOpen = false;
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        this._chatService
          .strikeOffUnitChat(
            {
              unitName: this.unit.name,
              hospitalName: this.unit.hospitalInfo?.name,
              _id: this.unit._id,
            },
            this.clickedMessage
          )
          .subscribe(
            (data) => {
              this.isStrikeOffOpen = false;
            },
            (err) => {
              console.log(err);
            }
          );
      }
    }
  }

  // Patient chart codes
  async submitMessagePC() {
    if (!this.chatMessage || (this.chatMessage && !this.chatMessage.trim())) {
      return false;
    }
    // set the action
    this.store.dispatch(chatActions.submitChatStart());
    let messageToSend = {
      message: this.chatMessage.trim(),
      attachmentLink: {},
      timestamp: null,
      device: "RADAR",
      priority: null,
      isDeleted: false,
      refMessage: null,
    };

    // check if reply to
    if (this.refMessage) {
      messageToSend.refMessage = {
        senderName: this.refMessage.senderName,
        message: this.refMessage.message,
        timestamp: this.refMessage.timestamp,
      };
    }
    const sendMessage = (key) => {
      messageToSend.attachmentLink = {
        key,
        name: this.attchObj.name,
        size: this.attchObj.size,
        fileType: this.attchObj.type,
      };

      // attach message for the last index
      messageToSend.message = this.chatMessage.trim();

      // close modal
      this.isUploadLoader = false;
      this.isViewAttchOpen = false;
      // dispatch action
      this.store.dispatch(
        chatActions.sendMessage({
          CPMRN: this.currPatient.CPMRN,
          encounters: this.currPatient.encounters,
          message: messageToSend,
        })
      );
    };
    if (this.attchObj && !this.attchObj?.key) {
      this.isUploadLoader = true;
      try {
        const uploadedData = await this.attachmentUpload();
        sendMessage(uploadedData.key);
      } catch (err) {
        this.isUploadLoader = false;
        console.error(err);
      }
    } else if (this.attchObj?.key) {
      sendMessage(this.attchObj?.key);
    } else {
      // dispatch action
      this.store.dispatch(
        chatActions.sendMessage({
          CPMRN: this.currPatient.CPMRN,
          encounters: this.currPatient.encounters,
          message: messageToSend,
        })
      );
    }

    this.refMessage = null;
  }

  public async attachmentUpload(): Promise<any> {
    if (!this.attchObj) {
      return Promise.reject("Please attach the file");
    }
    let fileData = this.attchObj.file;
    let compressedFile;
    if (this.getFileType(this.attchObj.type) == this.fileTypes.IMAGE) {
      if (this.attchObj["rotate"]) {
        fileData = dataURLtoFile(this.attchObj.fileUrl, this.attchObj.name);
      }
      // // image compression logic start
      compressedFile = await new Promise((resolve, reject) => {
        new Compressor(fileData, {
          quality: 0.6,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(null);
            console.log(err.message);
          },
        });
      });

      if (compressedFile) {
        fileData = new File([compressedFile], File.name, {
          type: this.attchObj.type,
        });
      }
      // // image compression logic end
      return await this.uploadFiles(fileData);
    } else {
      return await this.uploadFiles(fileData);
    }
  }

  uploadFiles(fileData) {
    return new Promise((resolve, reject) => {
      const signedUrlSubscription = this._fileService
        .getSignedUrl({
          id: this.attchObj.id,
          name: this.attchObj.name,
          size: this.attchObj.size,
          type: this.attchObj.type,
        })
        .subscribe(
          (signedData) => {
            if (signedData["status"] == "success") {
              // upload file
              const uploadFileSubscription = this._fileService
                .uploadFile(
                  fileData,
                  signedData["data"]["url"],
                  this.attchObj.type
                )
                .subscribe(
                  (data) => {
                    if (data && data.type == 4) {
                      const attachmentLink = {
                        key: signedData["data"]["key"],
                        name: this.attchObj.name,
                        size: this.attchObj.size,
                        fileType: this.attchObj.type,
                      };
                      resolve(attachmentLink);
                    }
                  },
                  (err) => reject(err?.message || "Something went wrong!")
                );
              this.fileUploadSubscription.push(uploadFileSubscription);
            }
          },
          (err) => reject(err?.message || "Something went wrong!")
        );
      this.fileUploadSubscription.push(signedUrlSubscription);
    });
  }

  getFile(attachmentLink) {
    return new Promise((resolve, reject) => {
      this._fileService
        .getFile(attachmentLink["key"])
        // this._documentService.downloadImage(this.currPatient.CPMRN, chat.attachmentLink.key)
        .subscribe(
          (data) => {
            let fileType = attachmentLink["fileType"];
            if (data.status === "success") {
              let dataToAdd = data.data;
              if (fileType == "application/pdf") {
                dataToAdd =
                  this.sanitisor.bypassSecurityTrustResourceUrl(dataToAdd);
              }
              resolve(dataToAdd);
            }
          },
          (err) => {
            reject(err?.message || "Something went wrong!");
          }
        );
    });
  }

  // Unit List codes
  submitMessageUL() {
    if (!this.chatMessage || (this.chatMessage && !this.chatMessage.trim())) {
      return false;
    }

    // set the action
    this.store.dispatch(chatActions.submitChatStart());

    let messageToSend = {
      message: this.chatMessage.trim(),
      attachmentLink: {},
      timestamp: null,
      // senderName: this.user.name,
      // senderRole: this.user.role,
      // senderEmail: this.user.email,
      device: "RADAR",
      priority: null,
      isDeleted: false,
      refMessage: null,
    };

    // check if reply to
    if (this.refMessage) {
      messageToSend.refMessage = {
        senderName: this.refMessage.senderName,
        message: this.refMessage.message,
        timestamp: this.refMessage.timestamp,
      };
    }

    this.store.dispatch(
      chatActions.unitSendMessage({ unit: this.unit, message: messageToSend })
    );

    this.refMessage = null;
  }

  public getFileType(type: string): fileType {
    return this._fileService.getFileType(type);
  }

  public isVideoContainer(chat: any): boolean {
    return (
      this.getFileType(chat.attachmentLink?.fileType) == this.fileTypes.VIDEO &&
      chat.imageLoaded
    );
  }
}
