/**
 * This converts DOB into birthday. It handles bday in months(M), days(D)
 *
 * Pass dob into the function as parameter
 */

import moment from "moment-timezone";

export function calculateAge(dob) {
  let tempDob = new Date().getTime() - new Date(dob).getTime();
  let tempYear = tempDob * 3.17098e-11;

  let dobToReturn = Math.floor(tempYear) + "";

  if (tempYear < 1) {
    let tempMonth = tempDob * 3.80517e-10;
    dobToReturn = Math.floor(tempMonth) + "M";

    if (tempMonth < 1) {
      let tempDays = tempDob * 1.15741e-8;
      dobToReturn = (Math.floor(tempDays) ? Math.floor(tempDays) : 1) + "D";
    }
  }

  return dobToReturn;
}

export function displayAge(
  ageObj,
  addComma: boolean = false,
  pType = null,
  dob = null
) {
  if (!ageObj) return "";

  let ageString;
  if (pType == "neonatal" && dob) {
    // neonata live date
    const duration = moment.duration(moment().diff(dob));
    const noOfDays = Math.floor(duration.asDays());

    if (noOfDays >= 3) ageString = `${noOfDays} days`;
    else ageString = getProperUnitName(Math.floor(duration.asHours()), "hr");
  } else {
    ageString = generateAge(ageObj);
  }

  if (ageString && ageString.length && addComma) ageString += ",";
  return ageString;
}

const generateAge = (ageObj) => {
  return ageObj.year
    ? `${ageObj.year} yr`
    : ageObj.month
    ? `${ageObj.month} mos`
    : ageObj.day
    ? getProperUnitName(ageObj.day, "day")
    : ageObj.hour
    ? getProperUnitName(ageObj.hour, "hr")
    : "";
};

export const getProperUnitName = (
  value,
  unitName,
  isCapitalizeFirstLetter: boolean = false,
  makeUnitPlural: boolean = true
) => {
  if (isCapitalizeFirstLetter)
    unitName = unitName[0].toUpperCase() + unitName.slice(1);
  return `${value} ${+value > 1 && makeUnitPlural ? unitName + "s" : unitName}`;
};

export const createExistingDoB = (dob, age) => {
  return moment(dob)
    .add(age.year || 0, "y")
    .add(age.month || 0, "M")
    .add(age.day || 0, "d")
    .add(age.hour || 0, "h")
    .add(age.minute || 0, "m");
};
