import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { SocketService } from "../../services/socket.service";

@Injectable({ providedIn: "root" })
export class TvSocketResolver implements Resolve<null> {
  constructor(private _socketService: SocketService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): null {
    this._socketService.connect();

    return null;
  }
}
