import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CoreHttpService } from "src/app/services/base-service/core-http.service";
import { CreateInfBundChecklistPayload, InfectionBundle } from "../models";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InfectionBundleService extends CoreHttpService {
  constructor(private _http: HttpClient) {
    super();
  }

  getInfectionQuestion(CPMRN, encounters, pType) {
    return this._http.get(
      this.env.apiUrl + `patients/${CPMRN}/${encounters}/ifb/questions`,
      {
        params: {
          pType,
        },
      }
    );
  }

  getAllInfectionBundles(
    CPMRN: string,
    encounters
  ): Observable<InfectionBundle[] | null> {
    return this._http
      .get<InfectionBundle[]>(
        this.env.apiUrl + `patients/${CPMRN}/${encounters}/ifb`
      )
      .pipe(
        map((data) => data["infectionBundles"]),
        catchError(this.errorHandler)
      );
  }

  createInfectionBundleChecklist(payload: CreateInfBundChecklistPayload) {
    return this._http
      .post<InfectionBundle>(
        this.env.apiUrl + `patients/${payload.CPMRN}/${payload.encounters}/ifb`,
        payload.checklist,
        this.httpOptions
      )
      .pipe(
        map((data) => data["infectionBundles"][0]),
        catchError(this.errorHandler)
      );
  }

  updateInfectionBundleChecklist(payload: CreateInfBundChecklistPayload) {
    return this._http
      .patch<InfectionBundle>(
        this.env.apiUrl +
          `patients/${payload.CPMRN}/${payload.encounters}/ifb/${payload.checklist._id}`,
        payload.checklist,
        this.httpOptions
      )
      .pipe(
        map((data) => data["infectionBundles"][0]),
        catchError(this.errorHandler)
      );
  }
}
