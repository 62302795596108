import { AppState } from "src/app/reducers";
import { Action, createFeatureSelector, createSelector } from "@ngrx/store";

import * as fromUserReducer from "./user.reducers";
import * as fromUserPreference from "./user-preference.reducers";

export interface State extends AppState {
  user: fromUserReducer.State;
}

/** REDUCER setup */
export function reducer(
  state: fromUserReducer.State | undefined,
  action: Action
) {
  return fromUserReducer.userReducer(state, action);
}

/** SELECTOR setup */
export const getUserState =
  createFeatureSelector<fromUserReducer.State>("user");
export const getUser = createSelector(
  getUserState,
  (state: fromUserReducer.State) => state
);

export const getCurrUser = createSelector(
  getUser,
  (state) => state.currentUser
);

export const getCurrUserStatus = createSelector(
  getUser,
  (state) => state.currentUserState
);

export const getCurrentUserFullName = createSelector(getCurrUser, (user) => {
  if (user.title && user.name) {
    return user.title + " " + user.name;
  } else if ((!user.title || user.title === "") && user.name) {
    return user.name;
  }

  return "";
});

export const getCurrentUserEmail = createSelector(
  getCurrUser,
  (user) => user.email
);

export const getCurrentUserRole = createSelector(
  getCurrUser,
  (user) => user.role
);

export const isCommandCenterUser = createSelector(
  getCurrUser,
  (state) => state.isCommandCenterUser
);

export const getAllowedCommandCenters = createSelector(
  getCurrUser,
  (state) => state.allowedCommandCenters
);

export const getUserHospitals = createSelector(getCurrUser, (user) => {
  if (!user || !user.hospitals) {
    return [];
  }

  return user.hospitals.reduce((acc: any, hospital: any) => {
    acc.push(hospital.name);

    return acc;
  }, []);
});

export const getUserUnits = createSelector(getCurrUser, (user) => {
  if (!user || !user.units) {
    return [];
  }

  return user.units.reduce((acc: any, unit: any) => {
    acc.push(unit.name);

    return acc;
  }, []);
});

/*
 * User Preference Selectors
 * */
export const getUserPreference = createSelector(
  getUserState,
  (state) => state.preference
);

export const getUserPreferenceRoundar = createSelector(
  getUserPreference,
  fromUserPreference.getUserPreferenceRoundar
);
