import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NcpModalComponent } from "../ncp-modal/ncp-modal.component";
import { MatDialog } from "@angular/material/dialog";

import { Store, select } from "@ngrx/store";
import * as fromNcpSelector from "../../store/selector/ncp.selector";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-ncp-input",
  templateUrl: "./ncp-input.component.html",
  styleUrls: ["./ncp-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NcpInputComponent implements OnInit {
  @Input() currentPatient = null;
  private unsubscribe$: Subject<any> = new Subject<any>();
  get ncpList$() {
    return this.store.pipe(
      select(fromNcpSelector.getNcpListData),
      takeUntil(this.unsubscribe$)
    );
  }

  constructor(private _dialog: MatDialog, private store: Store<any>) {}

  ngOnInit(): void {}
  openModal() {
    this._dialog.open(NcpModalComponent, {
      autoFocus: false,
      disableClose: true,
    });
  }
  clearAll() {}
}
