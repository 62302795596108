import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { CommandCenter } from "../models/CommandCenter";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  constructor(private _http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "Server error");
  }

  getTimeZoneList() {
    return this._http.get(`${environment.apiUrl}management/timeZones`).pipe(
      map((data) => data["result"]),
      catchError(this.errorHandler)
    );
  }

  /**
   * To get the list of command centers
   * @returns Observable
   */
  getCommandCenters(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}management/command_center`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To get individual command center info
   * @param id {string} command center id
   * @returns Observable
   */
  getCommandCenterInfo(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}management/command_center/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To set the status of the command center (activate / deactivate)
   * @param commandCenter {CommandCenter} the command center obj
   * @returns Observable
   */
  editStatusCommandCenter(commandCenter: CommandCenter): Observable<any> {
    return this._http
      .patch(
        `${environment.apiUrl}management/command_center/updateStatus`,
        commandCenter
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To edit the command center info (name / code etc)
   * @param commandCenter {CommandCenter} the command center obj
   * @returns Observable
   */
  editCommandCenterInfo(commandCenter: CommandCenter): Observable<any> {
    return this._http
      .patch(
        `${environment.apiUrl}management/command_center/update/${commandCenter._id}`,
        commandCenter
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add command center
   * @param commandCenter {CommandCenter} the command center obj
   * @returns Observable
   */
  addCommandCenter(commandCenter: CommandCenter): Observable<any> {
    return this._http
      .post(
        `${environment.apiUrl}management/command_center/create`,
        commandCenter
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add user
   * @param userInfo {user} user obj
   * @returns Observable
   */
  addUser(userInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/user/create`, userInfo)
      .pipe(catchError(this.errorHandler));
  }

  editUser(userInfo: any): Observable<any> {
    return this._http
      .patch(
        `${environment.apiUrl}management/user/edit/` + userInfo._id,
        userInfo
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add hospital
   * @param hospitalInfo {Hospital} hospital obj
   * @returns Observable
   */
  addHospital(hospitalInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/add/`, hospitalInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To edit hospital
   * @param hospitalInfo {Hospital} hospital obj
   * @returns Observable
   */
  editHospital(hospitalInfo: any): Observable<any> {
    return this._http
      .patch(
        `${environment.apiUrl}management/hospital/update/` + hospitalInfo._id,
        hospitalInfo
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To get the individual hospital info
   * @param id {string}
   * @returns Observable
   */
  getHospitalInfo(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}management/hospital/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add unit to a hospital
   * @param unitInfo {Unit} unit obj
   * @returns Observable
   */
  addUnit(unitInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/submitUnit/`, unitInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To get the individual unit info
   * @param hospId {string} hospital id
   * @param unitId {string} unit id
   * @returns Observable
   */
  getUnitInfo(hospId: string, unitId: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}management/hospital/${hospId}/unit/${unitId}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add / edit the camera info into a unit
   * @param cameraInfo {Camera} camera obj
   * @returns Observable
   */
  addCamera(cameraInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/submitCamera`, cameraInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To delete the camera
   * @param cameraInfo {Camera} camera obj
   * @returns Observable
   */
  deleteCamera(cameraInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/deleteCamera`, cameraInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add / edit the bed info into a unit
   * @param bedInfo {Bed} bed obj
   * @returns Observable
   */
  addBed(bedInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/submitBed`, bedInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To delete the bed
   * @param bedInfo {Bed} bed obj
   * @returns Observable
   */
  deleteBed(bedInfo: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}management/hospital/deleteBed`, bedInfo)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To get the R-alert device info
   * @returns {Observable}
   */
  getRalertInfo(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}iot/ralert/devices/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To delete the ralert
   * @param {string} id
   * @returns Observable
   */
  deleteRalert(id: any): Observable<any> {
    return this._http
      .delete(`${environment.apiUrl}iot/ralert/devices/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To get the Patient monitor info
   * @returns {Observable}
   */
  getPmInfo(id: string): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}iot/patient-monitor/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add Patient monitor
   * @returns {Observable}
   */
  addPm(dataToSend: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}iot/patient-monitor`, dataToSend)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To add Patient monitor
   * @returns {Observable}
   */
  updatePm(dataToSend: any): Observable<any> {
    return this._http
      .patch(
        `${environment.apiUrl}iot/patient-monitor/${dataToSend._id}`,
        dataToSend
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * To delete the patient monitor
   * @param {string} id
   * @returns Observable
   */
  deletePm(id: any): Observable<any> {
    return this._http
      .delete(`${environment.apiUrl}iot/patient-monitor/${id}`)
      .pipe(catchError(this.errorHandler));
  }

  getSpecialityList() {
    return this._http
      .get(`${environment.apiUrl}api/v1/radar/config`)
      .pipe(catchError(this.errorHandler));
  }
}
