import { sbar } from "./sbar";
import { TimeZoneDetails, Unit } from "./hospital";

export enum Services {
  HERA = "HERA",
  AIRA = "AIRA",
  NETRA = "NETRA",
}

export enum NoteServices {
  CHARGEABLE = "CHARGEABLE",
}

export class Patient {
  _id: string;
  name: string;
  lastName?: string;
  isAssignedToMe?: boolean;
  birthStatus: birthStatusList;
  age?: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  };
  pin?: boolean;
  pinnedBy?: string[];
  dob?: Date;
  patientType?: PatientType;

  dateOfContactWithHealthFacility?: Date;
  dateOfHospitalization?: Date;
  dateOfOnsetSymptoms?: Date;
  onSet?: {
    _id?: string;
    onSetHospitalName?: string;
    onSetHospitalAddress?: string;
    onSetHospitalCity?: string;
    onSetHospitalState?: string;
    onSetHospitalMobile?: string;
    onSetHospitalCountryCode: string | null;
    onSetAdmitDate?: Date | null;
    onSetDidHospReport?: string;
  }[];
  ards?: string;
  chestXray?: string;
  renalFailure?: string;
  coagulopathy?: string;
  cardiacFailure?: string;
  signsAtAdmission?: string[];
  symptomsAtAdmission?: string[];
  underlyingMedicalConditions?: string[];

  initialSymtoms?: string[];
  allergies?: string[];
  chiefComplaint?: string[];
  otherComplications?: string[];

  covidDetails?: {
    covidContactHistory?: string;
    epidNo?: number;

    contactSettingOptions?: {
      contactSettingReason?: boolean;
      contactSettingContent?: string;
    }[];
    covidContactHistoryOptions?: string[];
    clusterPatientMember?: string;
    massGathering?: {
      gathered?: string;
      details?: string;
    };
    foreignTravel?: {
      travelled?: string;
      arrivalToIndia?: {
        date?: Date;
        flight?: string;
        seatNo?: string;
      };
      countriesVisited?: {
        countryName?: string;
        fromDate?: Date;
        toDate?: Date;
      }[];
      visitedWuhan?: string;
      otherChinesePlacesVisited?: string;
      visitedAnimalMarket?: string;
    };
    indiaTravel: {
      travelled?: string;
      placesVisited?: {
        placeName?: string;
        modeOfTravel?: string;
        vehicleNo?: string;
        ticketNo?: string;
        seatNo?: string;
        fromDate?: Date;
        toDate?: Date;
      }[];
    };
    labInformation?: {
      sampleCollected?: string;
      sampleNotCollectedReason?: string;
      labDetailList?: {
        sampleType?: string;
        sampleCollectedCenter?: string;
        dateOfSampleCollected?: Date;
        testLabName?: string;
        testResult?: string;
        dateOfResult?: Date;
      }[];
    };
    healthResponseInfo: {
      noOfHighRiskContact?: number;
      noOfHighRiskContactTraced?: number;
      noOfHighRiskContactSamplesCollected?: number;
      noOfHighRiskContactDevelopedSymptoms?: number;
      noOfHighRiskContactPositive?: number;

      noOfLowRiskContact?: number;
      noOfLowRiskContactSamplesCollected?: number;
      noOfLowRiskContactDevelopedSymptoms?: number;
      noOfLowRiskContactPositive?: number;
    };
  };
  covidInterviewerDetails?: {
    firstName?: string;
    lastName?: string;
    mobile?: string;
    countryCode?: string;
    dateInterviewed?: Date;
  };

  // Data-science fields
  apacheScore?: number;
  ventFreeDays?: number;

  sex?: string;
  height?: number;
  heightUnit?: string;
  occupation?: string;
  nationality?: string;
  fatherName?: string;
  email?: string;
  mobile?: string;
  weight?: number;
  weightUnit?: string;
  weightObj: WeightObj;
  birthWeight: number;
  birthWeightObj: WeightObj;
  IBW?: number;
  BMI?: number;
  bloodGroup?: string;
  MRN: string;
  CPMRN: string;
  hospitalName: string;
  unitName: string;
  hospitalID?: string;
  unitID?: string;
  bedNo?: string;
  camera?: string;
  hospitalInfo?: {
    address?: string;
    units?: [Unit];
    cityCode?: string;
    countryCode?: string;
    hospitalCode?: string;
    isActive?: Boolean;
    latestAdmissionId?: string;
    logo?: string;
    name?: string;
    stateCode?: string;
    timeZoneDetail?: TimeZoneDetails;
    services?: Services;
    noteFeatures?: NoteServices;
  };
  chronic?: [];
  immune?: [];
  operativeStatus?: string;
  code?: string;
  ICUAdmitDate?: Date;
  ICUDischargeDate?: Date;
  ICUDischargeDisposition?: string;
  palliative?: string;
  diagnoses?: string[];
  MEWS?: number;
  PCP?: string;
  PCP_phone?: string;
  PCP_speciality?: string;
  qsofa?: number;
  rox?: {
    value?: Number;
    timestamp?: Date;
  };
  DI?: {
    currentValue?: Number;
    varienceValue?: Number;
    currentThreshold?: Number;
    varienceThreshold?: Number;
  };
  // deltaDI?: {
  //   value?: Number;
  // }
  severity?: string;
  isGcs?: boolean;
  isNIV?: {
    value?: boolean;
    timestamp?: number;
  };
  isIntubated?: {
    value?: boolean;
    timestamp?: number;
  };
  isTrach?: {
    value?: boolean;
    timestamp?: number;
  };
  isPressor?: {
    value?: boolean;
    timestamp?: number;
  };
  isHFNC?: {
    value?: boolean;
    timestamp?: number;
  };
  isCurrentlyAdmitted: boolean;
  markedToWriteNotes?: boolean;
  pastMedicalHistory?: string;
  orders?: {
    active?: {
      medications?: [];
      labs?: [];
      diets?: [];
      communications?: [];
      vents?: [
        {
          ventType?: string;
        }
      ];
    };
    inactive?: {
      medications?: [];
      labs?: [];
      diets?: [];
      communications?: [];
    };
  };
  // ---------------
  days?: [
    {
      dayNumber?: number;
      dayDate?: Date;
      hours?: [
        {
          hourNumber?: number;
          minutes?: [
            {
              minuteNumber?: number;
              vitals?: {
                daysTemperature?: number;
                daysTemperatureUnit?: string;
                daysAVPU?: string;
                daysHR?: string;
                daysRR?: string;
                daysBP?: string;
                daysSpO2?: string;
                daysFiO2?: string;
                daysPain?: number;
                daysFalls?: number;
                daysFallsFallHistory?: number;
                daysFallsSecDiagnoses?: number;
                daysFallsAmbAssist?: number;
                daysFallsivTherapy?: number;
                daysFallsGait?: number;
                daysFallsMentalStatus?: number;
                daysGCS?: number;
                daysGCSeyes?: number;
                daysGCSverbal?: number;
                daysGCSmotor?: number;
                daysBraden?: number;
                daysBradenSensory?: number;
                daysBradenMoisture?: number;
                daysBradenActivity?: number;
                daysBradenMobility?: number;
                daysBradenNutrition?: number;
                daysBradenFriction?: number;
                daysRass?: number;
                daysRassCAMICU?: Boolean;
                daysRassAcuteYN?: string;
                daysRassFluctYN?: string;
                daysRassInattentionYN?: string;
                daysRassDisorganizedYN?: string;
                daysVentAirway?: string;
                daysVentOralAirway?: string;
                daysVentType?: string;
                daysVentMode?: string;
                daysVentSubMode?: string;
                daysVentI?: number;
                daysVentE?: number;
                daysVentMV?: number;
                daysVentTubeSize?: number;
                daysVentVt?: number;
                daysVentRRset?: number;
                daysVentPplat?: number;
                daysVentPpeak?: number;
                daysVentMAP?: number;
                daysVentPinsp?: number;
                daysVentPsupport?: number;
                daysVentPEEP?: number;
                isVerified?: boolean;
              };
            }
          ];
        }
      ];
    }
  ];
  // ------------------
  io?: any;
  sbar?: sbar;
  encounters?: number;
  phone?: string;
  countryCode?: string;
  hospitalLogo?: string;
  notes?: {
    isPended?: boolean;
    isDraft?: boolean;
  };
  covid?: string;
  isolation?: {
    precautionType?: string;
    reason?: string;
  }[];
  aadhar?: number;
  address?: PatientAddress;
  patientMonitorId?: string;
  createdBy?: string;
  editedBy?: string;
  gestationAge: GestationAgeObj;
  initialAssessment?: InitialAssessment;
  motherInfo?: {
    name: string;
    lastName: string;
    bloodGroup: string;
  };
  weightHistory: [PatientWeightHistory];
  timeZoneDetail?: TimeZoneDetails;
  profilePicture: string;
  genderForGrowthChart: string;
}

export interface PatientAddress {
  line1?: string;
  line2?: string;
  pincode?: string;
  city?: string;
  state?: string;
}

interface Note {
  noteType: string;
  noteSubType: string;
}

export interface PatientCPMRNEncounters {
  cpmrn: string;
  encounters: string;
  note: Note;
}

interface updateProperties {
  propName: string;
  value: string | number | null;
}

export interface PatientPayloadInterface {
  CPMRN: string;
  encounters: number;
  patient: updateProperties[];
}

export interface PatientParamInterface {
  cpmrn: string;
  encounters: string;
}

export interface InitialAssessment {
  vitals: any;
  modeOfAdmission: string;
  admittedFrom: string;
  requireOxygenTherapy: null;
  painSite: string;
  allergies: string;
  invasiveLineSites: [];
  diet: string;
  personalHealthHistory: [];
  pastHealthIssues: [];
  familyHealthHistory: [];
  pastSurgeries: [];
  artificialProsthesisSite: [];
  adl: {
    usualLevel: number;
    admissionLevel: number;
  };
  feeding: {
    usualLevel: number;
    admissionLevel: number;
  };
  bathing: {
    usualLevel: number;
    admissionLevel: number;
  };
  toileting: {
    usualLevel: number;
    admissionLevel: number;
  };
  mobilityOrGait: {
    usualLevel: number;
    admissionLevel: number;
  };
  dressing: {
    usualLevel: number;
    admissionLevel: number;
  };
  languageProblem: boolean;
  interpreterNeeded: boolean;
  primaryLanguage: string;
  culturalOrReligiousBarrierDetails: string;
  personalBelongingDetails: string;
  idBand: boolean;
  skinIssues: [];
  needsIdentification: boolean;
  incontinence: boolean;
}

export interface AssignToDoctor {
  patientCPMRNs: string[];
  userEmail: string;
}

export interface PinInfo {
  CPMRN: string;
  pin: boolean;
  unit?: string;
}

export interface PatientWeightHistory {
  weightKg: number;
  length: number;
  headCircumference: number;
  weightMeasuredTime: string;
  weightUnit: string;
  createdBy: CreatedByEditedBy;
  updatedBy: CreatedByEditedBy;
}

export interface CreatedByEditedBy {
  at: string;
  email: string;
  name: string;
}

export interface WeightObj {
  kg: string;
  gram: string;
}

export enum PatientType {
  Adult = "adult",
  Neonatal = "neonatal",
  Pediatric = "pediatric",
}

export interface GestationAgeObj {
  weeks: number;
  days: number;
}

export enum birthStatusList {
  Preterm = "Preterm",
  Mature = "Mature",
}
