import { ToggleDataInterface } from "src/app/iris-components/toggle-header/model";

export enum ASSESSMENT_TABS {
  ASSESSMENT = "assessment",
  NCP = "nurse care plan",
  INF = "infection bundle",
}

export const ASSESSMENT_TABS_PAYLOD: ToggleDataInterface[] = [
  {
    label: "Assessment",
    value: ASSESSMENT_TABS.ASSESSMENT,
  },
  // {
  //   label: "Nursing care plan",
  //   value: ASSESSMENT_TABS.NCP,
  // },
];

export const DEFAULT_ASSESSMENT_ACTIVE_TAB = ASSESSMENT_TABS.ASSESSMENT;
export const GROWTH_SEX_LIST = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];
