import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
// import { Socket } from "ngx-socket-io";
import { Store, select } from "@ngrx/store";
import * as documentActions from "src/app/actions/documents";
import { b64toBlob } from "src/app/support-functions/base64ToBlob";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { socket } from "./socket.service";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  env = environment;

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );

  constructor(private http: HttpClient, public store: Store<any>) {}

  startSockets(cpmrn: number, encounters: number) {
    socket.on(cpmrn + encounters + "DOC", (doc) => {
      this.store.dispatch({
        type: documentActions.ActionTypes.addDocuments,
        payload: [doc],
      });
    });
    socket.on(cpmrn + encounters + "DOC Edit", (props) => {
      this.store.dispatch({
        type: documentActions.ActionTypes.editDocument,
        payload: props,
      });
    });
    socket.on(cpmrn + encounters + "DOC Remove", (props) => {
      this.store.dispatch({
        type: documentActions.ActionTypes.documentRemoved,
        payload: props,
      });
    });
  }

  stopSockets(cpmrn: number, encounters: number) {
    socket.removeAllListeners(cpmrn + encounters + "DOC");
    socket.removeAllListeners(cpmrn + encounters + "DOC Edit");
    socket.removeAllListeners(cpmrn + encounters + "DOC Remove");
    this.store.dispatch({ type: documentActions.ActionTypes.reset });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getSignedUrl(CPMRN, docProps) {
    return this.http
      .post<any>(
        this.env.apiUrl + "patients/" + CPMRN + "/doc_uploads",
        docProps,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadFileAWSS3(fileUploadUrl, contentType, file) {
    const headers = new HttpHeaders({
      "Content-Type": contentType,
      DoNotIntercept: "true",
    });

    // check the type of the file
    let fileBlob = file;
    if (contentType.split("/")[0] == "image") {
      fileBlob = b64toBlob(file, contentType);
    }

    const req = new HttpRequest("PUT", fileUploadUrl, fileBlob, {
      headers: headers,
      reportProgress: true,
    });
    return this.http.request(req).pipe(catchError(this.errorHandler));
  }

  downloadImage(CPMRN, key, bucket?: string) {
    return this.http
      .post(
        this.env.apiUrl + "patients/" + CPMRN + "/download/",
        { key: key, bucket: bucket },
        {
          observe: "response",
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  addDocument(CPMRN, encounters, document) {
    return this.http
      .post(
        this.env.apiUrl +
          "patients/" +
          CPMRN +
          "/" +
          encounters +
          "/" +
          "documents/new",
        document
      )
      .pipe(catchError(this.errorHandler));
  }
  //get all documents
  getDocuments(CPMRN, encounters) {
    return this.http.get(
      this.env.apiUrl +
        "patients/" +
        CPMRN +
        "/" +
        encounters +
        "/" +
        "documents/"
    );
  }

  editDocument(CPMRN, encounters, props) {
    return this.http
      .post(
        this.env.apiUrl +
          "patients/" +
          CPMRN +
          "/" +
          encounters +
          "/" +
          "documents/edit",
        props
      )
      .pipe(catchError(this.errorHandler));
  }

  removeDocument(CPMRN, encounters, props) {
    return this.http
      .patch(
        this.env.apiUrl +
          "patients/" +
          CPMRN +
          "/" +
          encounters +
          "/" +
          "documents/remove",
        props
      )
      .pipe(catchError(this.errorHandler));
  }
  /**
   * for notes
   */
  getByTag(CPMRN, encounters, tag) {
    return this.http.get(
      this.env.apiUrl +
        "patients/" +
        CPMRN +
        "/" +
        encounters +
        "/" +
        "documents/tag/" +
        tag
    );
  }
}
