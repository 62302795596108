export interface NcpList {
  _id?: string;
  createdAt?: any;
  diagnose: string;
  fields: {
    assessment?: any;
    goalsandoutcomes?: any;
    interventions?: any;
    rationale?: any;
    evaluation?: any;
  };
  createdBy?: string;
  updatedAt?: string;
  isEdit?: boolean;
}

export interface NcpResponse {
  _id: string;
  name: string;
  lastName: string;
  CPMRN: string;

  encounters: number;

  nursingPlan: NcpList[];
}

export interface NcpData {
  status: string;
  message: string;
  data: NcpResponse[];
}

export interface UpdateNcp {
  planId: string;
  fields: {
    assessment?: any;
    goalsandoutcomes?: any;
    interventions?: any;
    rationale?: any;
    evaluation?: any;
  };
}
