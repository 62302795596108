import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Patient } from "src/app/models/patient";
import { DateChangeService } from "src/app/services/date-change.service";
import { TrendsComponent } from "src/app/shared-modules/features/trends/components/old-trends/trends.component";
import {
  BILIRUBIN_LAB_NAME,
  LFT_LAB_NAME,
  NICE_CHART_X_AXIS_RANGES,
  NICE_CHART_Y_AXIS_RANGES,
  TrendType,
} from "src/app/shared-modules/features/trends/constants/trends.data";
import { Vitals } from "../../models/Vital";
import { getAllLabs } from "src/app/labs-scans-module/store/reducers";
import { select, Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { PatientType } from "src/app/models/patient";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import moment from "moment-timezone";
import * as fromTrendsStore from "@shared-modules/features/trends/store";
import { GrowthTrendsConfig } from "@shared-modules/features/trends/models/growth-trends.model";
import {
  GROWTH_CHART_CONFIG,
  GROWTH_CHART_OPTIONS,
} from "@shared-modules/features/trends/constants/growth-trends.data";
import { GrowthChartComponent } from "@shared-modules/features/trends/components/growth-chart/growth-chart.component";
import { GROWTH_SEX_LIST } from "../../constants";
import { FormControl, Validators } from "@angular/forms";
import { FormsService } from "src/app/admit-form/services/forms.service";
import * as fromPatientFormActions from "src/app/store/actions/patient-chart/patient-header/patient-form.actions";
import {
  GROWTH_MODAL_HEIGHT,
  GROWTH_MODAL_WIDTH,
} from "@shared-modules/features/trends/constants/new-trends.data";
import { GrowthChartSupportService } from "@shared-modules/features/trends/services/growth-chart-support.service";

@Component({
  selector: "app-day-selector",
  templateUrl: "./day-selector.component.html",
  styleUrls: ["./day-selector.component.scss"],
})
export class DaySelectorComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();

  @ViewChild("newGenderBlock", { read: TemplateRef })
  newGenderBlock: TemplateRef<any>;

  @Input() vitals: Vitals[] = [];
  @Input() days: number[] = [];
  @Input() currPatient: Patient;
  @Input() displayGraphButtons: boolean = true;
  @Output() setSelectedDay: EventEmitter<any> = new EventEmitter();
  @Output() setSelectedDate: EventEmitter<any> = new EventEmitter();
  /**
   * @description To store the current selected day
   * @type {number}
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  @Input() selectedDay: number = 0;

  disablePrev: boolean = true;
  disableNext: boolean = false;

  @Input() selectedDate: Date;

  public patientHeader$ = this._store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  getAllLabs$ = this._store.pipe(
    select(getAllLabs),
    takeUntil(this.unsubscribe$)
  );
  bilirubinLabs: any;

  get patientType() {
    return PatientType;
  }

  get sexList() {
    return GROWTH_SEX_LIST;
  }

  genderControl = new FormControl("", Validators.required);

  constructor(
    private datechange: DateChangeService,
    private _dialog: MatDialog,
    private _store: Store<{}>,
    private _trendsStore: Store<fromTrendsStore.TrendsActionReducerMapInterface>,
    private _formService: FormsService,
    private _gcs: GrowthChartSupportService
  ) {}

  ngOnInit(): void {
    combineLatest([this.patientHeader$, this.getAllLabs$]).subscribe(
      ([patientData, labsData]) => {
        this.currPatient = patientData;
        this.bilirubinLabs =
          this.currPatient?.patientType === PatientType.Neonatal
            ? this.filterAndTransformBilirubinData(labsData)
            : [];

        if (this.trendComponentInstance) {
          this.trendComponentInstance.currentVitalData = this.bilirubinLabs;
        }

        if (this.isAutoOpenGrowth) {
          this.genderPopupRef.close();
          this.generateGrowthChart();
        }
      }
    );
  }

  filterAndTransformBilirubinData(labData) {
    if (!labData?.length) return [];

    return labData?.reduce((transformedData, labObj) => {
      if (!this.isLabContainBilirubin(labObj)) return transformedData;

      const dob = this.currPatient?.dob;
      const age = moment
        .duration(moment(labObj["reportedAt"]).diff(moment(dob)))
        .asDays();

      if (age >= 0 && age <= 14) {
        transformedData.push({
          _id: labObj?._id,
          value: this.getBilirubinValue(labObj),
          date: labObj?.reportedAt,
          age,
          phototherapyIntensity: labObj?.phototherapyIntensity,
        });
      }

      return transformedData;
    }, []);
  }

  isLabContainBilirubin(labObj) {
    if (labObj?.name?.includes(BILIRUBIN_LAB_NAME)) return true;

    return this.isLFTContainBilirubin(labObj);
  }

  isLFTContainBilirubin(labObj) {
    if (!labObj?.name?.includes(LFT_LAB_NAME)) return false;

    return (
      labObj?.attributes["Bilirubin.total"] &&
      labObj?.attributes["Bilirubin.total"]["value"] != null
    );
  }

  getBilirubinValue(labObj) {
    if (this.isLFTContainBilirubin(labObj)) {
      return (+labObj?.attributes["Bilirubin.total"]["value"] * 17.1).toFixed(
        2
      );
    }

    return (labObj?.attributes?.value?.value * 17.1).toFixed(2);
  }

  /**
   * @description Filters only required date for vitals
   * @param Date Date Variable
   * @date 17 Aug 2019
   */
  dateFilter = (date: Date | null): boolean => {
    return this.datechange.filterDate(this.vitals, date, "timestamp");
  };

  /**
   * @description When Date Changes the selected day should change and update its contents
   * @param Date Date Variable
   * @date 17 Aug 2019
   */
  onClickOfDate(date: Date) {
    if (!date) return;
    const getDayIndex = (): number =>
      this.days.findIndex((day) => day === this.selectedDay);

    const indexOfVitals = this.datechange.findElementByDate(
      this.vitals,
      date,
      "timestamp"
    );
    if (indexOfVitals > -1) {
      this.selectedDay = this.vitals[indexOfVitals]?.dayNum;
      this.selectedDate = date;
      this.checkButtonDisabled(getDayIndex());
      this.emitSelectedDay();
      this.emitSelectedDate();
    }
  }

  /**
   * @description Triggers when day is changed in vitals
   * @param {number} dayNum
   * @author Suraj Shenoy
   * @date June 2nd 2021
   */
  dayChanged(dayNum: number): void {
    const { timestamp } = this.datechange.findElementByKey(
      this.vitals,
      dayNum,
      "dayNum"
    );
    this.selectedDate = new Date(timestamp);
    this.selectedDay = dayNum;
    this.emitSelectedDay();
    this.emitSelectedDate();
    this.checkButtonDisabled();
  }

  /**
   * @description Triggers when next / prev day is clicked
   * @param {string} next / prev
   * @author Suraj Shenoy
   * @date July 26, 2022
   */
  changeDay(direction: string): void {
    const getDayIndex = (): number =>
      this.days.findIndex((day) => day === this.selectedDay);

    let dayIndex = getDayIndex();

    if (direction === "next") {
      this.selectedDay =
        dayIndex + 1 > this.days.length - 1
          ? this.selectedDay
          : this.days[dayIndex + 1];
    } else if (direction === "prev") {
      this.selectedDay =
        dayIndex - 1 < 0 ? this.selectedDay : this.days[dayIndex - 1];
    }

    const { timestamp } = this.datechange.findElementByKey(
      this.vitals,
      this.selectedDay,
      "dayNum"
    );
    this.selectedDate = new Date(timestamp);

    this.emitSelectedDay();
    this.emitSelectedDate();

    this.checkButtonDisabled(getDayIndex());
  }

  emitSelectedDay(): void {
    this.setSelectedDay.emit(this.selectedDay);
  }

  emitSelectedDate(): void {
    this.setSelectedDate.emit(this.selectedDate);
  }

  checkButtonDisabled(dayIndex: number | null = null): void {
    if (!dayIndex) {
      dayIndex = this.days.findIndex((day) => day === this.selectedDay);
    }

    this.disableNext = dayIndex === this.days.length - 1 ? true : false;
    this.disablePrev = dayIndex === 0 ? true : false;
  }

  trendComponentInstance: any;
  openNiceChartTrends() {
    const dialogRef = this._dialog.open(TrendsComponent, {
      width: "1320px",
      height: "705px",
      autoFocus: false,
      disableClose: true,
    });
    this.trendComponentInstance = dialogRef.componentInstance;

    // configurations
    this.trendComponentInstance.trendType = TrendType.niceChart;
    this.trendComponentInstance.isHeaderToggleVisible = false;
    this.trendComponentInstance.vitalNormalRange = [undefined, false];
    this.trendComponentInstance.vitalNames = {
      show: "Bilirubin.total",
      val: null,
      unitName: "mcmol/l",
    };
    this.trendComponentInstance.yAxisConfig = NICE_CHART_Y_AXIS_RANGES;
    this.trendComponentInstance.xAxisConfig = NICE_CHART_X_AXIS_RANGES;
    this.trendComponentInstance.currentVitalData = this.bilirubinLabs;
    this.trendComponentInstance.isShowDayDropdown = false;
    this.trendComponentInstance.currPatient = this.currPatient;
  }

  openGrownTrends() {
    const sex =
      this.currPatient?.sex === "O"
        ? this.currPatient?.genderForGrowthChart
        : this.currPatient?.sex;

    if (!sex || !["M", "F"]?.includes(sex)) return this.openGenderPopup();

    this.generateGrowthChart();
  }

  isGenderOthers: boolean = false;
  genderPopupRef;
  openGenderPopup() {
    if (this.currPatient?.sex == "O") this.isGenderOthers = true;
    this.genderPopupRef = this._dialog.open(this.newGenderBlock);
  }

  generateGrowthChart() {
    this.isAutoOpenGrowth = false;
    const initialGraph = this._gcs.makeGraphDataAndConfigToBePushed(
      Object.keys(GROWTH_CHART_OPTIONS)[0] as GROWTH_CHART_OPTIONS,
      this.currPatient
    );

    this._trendsStore.dispatch(
      fromTrendsStore.setInitialGraphAndData({ graphConfigs: initialGraph })
    );

    const trendCommonConfig: fromTrendsStore.TrendCommonReducerInterface = {
      trendType: TrendType.growthChart,
    };
    this._trendsStore.dispatch(
      fromTrendsStore.setTrendCommonConfig({ config: trendCommonConfig })
    );

    const dialogRef = this._dialog.open(GrowthChartComponent, {
      width: GROWTH_MODAL_WIDTH,
      height: GROWTH_MODAL_HEIGHT,
      autoFocus: false,
      disableClose: true,
    });
  }

  isAutoOpenGrowth: boolean = false;
  saveGender() {
    this.isAutoOpenGrowth = true;
    let updatedProps;
    if (!this.currPatient?.sex) {
      updatedProps = this._formService.transformUpdateData({
        sex: this.genderControl?.value,
      });
    } else {
      updatedProps = this._formService.transformUpdateData({
        genderForGrowthChart: this.genderControl?.value,
      });
    }

    const payload = {
      CPMRN: this.currPatient.CPMRN,
      encounters: this.currPatient.encounters,
      patient: updatedProps,
    };

    this._store.dispatch(
      fromPatientFormActions.updatePatient({
        payload,
        nextPage: 0,
        isFormComplete: false,
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
