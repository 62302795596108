<div class="emr-nav-tabs-comp main-container">
  <div
    *ngIf="patientInfo"
    class="patient-info"
    [ngClass]="{
      'discharge-border': patientType === 'discharged',
      're-admitted-border': patientType === 're-admitted'
    }"
  >
    <div class="name-image-cont">
      <div class="image-cont">
        <app-avatar [avatarParams]="patientAvatar"></app-avatar>
      </div>
      <div class="name-cont">
        <div class="patient-name">
          {{ patientInfo.name + " " + (patientInfo?.lastName || "") }}
        </div>
        <div class="pat-info">
          <div>
            <span>{{ getPatientAge() }}</span>
            <span *ngIf="patientInfo.sex === 'O'">
              <fa-icon [icon]="['fas', 'transgender']" title="Other"></fa-icon>
            </span>
            <span *ngIf="patientInfo.sex !== 'O'">
              {{
                patientInfo.sex === "M"
                  ? "Male"
                  : patientInfo.sex === "F"
                  ? "Female"
                  : ""
              }}
            </span>
          </div>
          <div class="pat-type" *ngIf="patientInfo?.patientType">
            {{ patientInfo?.patientType | titlecase }}
          </div>
        </div>
      </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div class="height-weight-cont">
      <div class="height-weight-cont_info height-weight-cont_info-1">
        <div class="display-flex">
          <span class="labels">Blood group:</span>
          <span class="values">{{ patientInfo.bloodGroup || "-" }}</span>
        </div>
        <div
          class="display-flex"
          *ngIf="patientInfo?.patientType !== PatientType.Neonatal"
        >
          <span class="labels">Height:</span>
          <span class="values height-weight-cont_overflow-hidden">{{
            patientInfo.height
              ? getRoundedValue(patientInfo.height) + "cm"
              : "-"
          }}</span>
        </div>
        <div
          class="display-flex"
          *ngIf="patientInfo?.patientType === PatientType.Neonatal"
        >
          <span class="labels">Birth Wt:</span>
          <span class="values">{{ patBirthWStr }}</span>
        </div>
        <div class="display-flex">
          <span class="labels"
            >{{
              patientInfo?.patientType === PatientType.Neonatal
                ? "Admn Wt"
                : "Weight"
            }}:</span
          >
          <span class="values">{{ patAdminWStr }}</span>
        </div>
      </div>
      <mat-divider class="divider-vertical" [vertical]="true"></mat-divider>
      <div
        class="height-weight-cont_info height-weight-cont_info-2"
        [ngClass]="{
          '--adult-pediatric': patientInfo?.patientType !== PatientType.Neonatal
        }"
      >
        <div class="display-flex">
          <span class="labels">DOB:</span>
          <span class="values">{{
            (patientInfo?.patientType === PatientType.Neonatal
              ? (patientInfo?.dob | timezone: "DD/MM/YY")
              : (patientInfo?.dob | timezone: "DD/MM/YYYY")) || "-"
          }}</span>
        </div>
        <ng-container *ngIf="patientInfo?.patientType !== PatientType.Neonatal">
          <div class="display-flex">
            <span class="labels">BMI:</span>
            <span class="values">{{ patientInfo.BMI?.toFixed(2) || "-" }}</span>
          </div>
          <div class="display-flex">
            <span class="labels">IBW:</span>
            <span
              class="values"
              *ngIf="patientInfo?.IBW == -1"
              popoverTrigger="hover"
              cpPopover="The Patient’s Height is less than 135cm, or the Patient’s Age is less than 18yr."
              position="right-center"
              >Height/Age range is invalid
            </span>
            <span
              class="values"
              *ngIf="patientInfo?.IBW != -1 && patientInfo?.IBW"
            >
              {{ patientInfo?.IBW | number: "1.0-0" }} kg
            </span>
            <span class="values" *ngIf="!patientInfo?.IBW"> - </span>
          </div>
        </ng-container>
        <ng-container *ngIf="patientInfo?.patientType === PatientType.Neonatal">
          <div class="display-flex">
            <span class="labels">Gest age:</span>
            <span class="values"
              ><ng-container *ngIf="patientInfo?.gestationAge?.weeks"
                >{{ patientInfo?.gestationAge?.weeks }}w </ng-container
              ><ng-container *ngIf="patientInfo?.gestationAge?.days"
                >{{ patientInfo?.gestationAge?.days }}d</ng-container
              ></span
            >
          </div>
          <div class="display-flex">
            <span class="labels">Corr gest age:</span>
            <span class="values">{{ patCorrGestAgeStr }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="height-weight-cont"
      *ngIf="patientInfo?.patientType === PatientType.Neonatal"
    >
      <div class="height-weight-cont_info height-weight-cont_info-1">
        <div class="display-flex">
          <span class="labels">Current Wt:</span>
          <span class="values">
            {{ patCurrentWObj[0] }}
          </span>
          <span class="weight-info" *ngIf="patCurrentWObj && patCurrentWObj[1]"
            >(Updated on
            {{
              patCurrentWObj[1]["weightMeasuredTime"] | timezone: "DD/MM/YYYY"
            }})</span
          >
        </div>
        <div class="display-flex">
          <span class="labels">Length:</span>
          <span class="values">
            {{ (patCurrLengthObj && patCurrLengthObj[0]) || "-" }}
          </span>
          <span
            class="weight-info"
            *ngIf="patCurrLengthObj && patCurrLengthObj[1]"
            >(Updated on
            {{
              patCurrLengthObj[1]["weightMeasuredTime"]
                | timezone: "DD/MM/YYYY"
            }})</span
          >
        </div>
        <div class="display-flex">
          <span
            class="labels"
            popoverTrigger="hover"
            cpPopover="Head circumference"
            >Head circumference:</span
          >
          <span class="values">
            {{ (patCurrHeadCircufObj && patCurrHeadCircufObj[0]) || "-" }}
          </span>
          <span
            class="weight-info"
            *ngIf="patCurrHeadCircufObj && patCurrHeadCircufObj[1]"
            >(Updated on
            {{
              patCurrHeadCircufObj[1]["weightMeasuredTime"]
                | timezone: "DD/MM/YYYY"
            }})</span
          >
        </div>
        <div
          class="dispay-flex align-text-center cursor-pointer primary prog-weight"
          (click)="openEnterWeigth(enterWeigthTemplate)"
        >
          <img src="../../../assets/icons/add.svg" alt="" />
          Enter anthropometric details
        </div>
      </div>
    </div>

    <!-- discharge reason other than death @sheethalkhan -->
    <div
      class="discharge-cont"
      [ngClass]="{ 're-admitted-cont': patientType === 're-admitted' }"
      *ngIf="
        (patientType === 'discharged' &&
          patientInfo.ICUDischargeDisposition != 'Death') ||
        patientType === 're-admitted'
      "
    >
      <mat-divider class="divider"></mat-divider>

      <div class="discharge-label">
        {{ patientType === "discharged" ? "Discharged" : "Re-admitted" }}
      </div>

      <mat-divider class="divider"></mat-divider>

      <div class="discharge-details" *ngIf="patientType === 'discharged'">
        {{ patientInfo.ICUDischargeDisposition }}
        on
        {{
          patientInfo.ICUDischargeDate
            | timezone: "MMM D, Y":timeZoneDetail?.name
        }}
        at
        {{
          patientInfo.ICUDischargeDate | timezone: "HH:mm":timeZoneDetail?.name
        }}
        hours
        <cp-timezone-label
          *ngIf="timeZoneDetail?.name"
          [timeZoneDetail]="timeZoneDetail"
          [textColor]="'#eb4049'"
        ></cp-timezone-label>
      </div>
    </div>
    <!-- discharge reason  death @sheethalkhan -->
    <div
      class="discharge-cont"
      *ngIf="
        patientType === 'discharged' &&
        patientInfo.ICUDischargeDisposition === 'Death'
      "
    >
      <mat-divider class="divider"></mat-divider>

      <div class="discharge-label">Death</div>

      <mat-divider class="divider"></mat-divider>

      <div class="discharge-details">
        Discharged on
        {{
          patientInfo.ICUDischargeDate
            | timezone: "MMM D, Y":timeZoneDetail?.name
        }}
        at
        {{
          patientInfo.ICUDischargeDate | timezone: "HH:mm":timeZoneDetail?.name
        }}
        hours
        <cp-timezone-label
          *ngIf="timeZoneDetail?.name"
          [timeZoneDetail]="timeZoneDetail"
          [textColor]="'#eb4049'"
        ></cp-timezone-label>
      </div>
    </div>
  </div>

  <div class="nav-tabs-btns">
    <div class="button-group-vertical" #buttonGroup>
      <div
        (mouseenter)="summaryImageUrl = 'assets/icons/summary_white.svg'"
        (mouseleave)="
          summaryImageUrl =
            tabInFocus === 'summary'
              ? 'assets/icons/summary_white.svg'
              : 'assets/icons/summary.svg'
        "
        class="button-secondary summary"
        [ngClass]="[tabInFocus === 'summary' ? 'divInFocus' : 'divNotInFocus']"
        (click)="tabInFocus = 'summary'; goTo('summary')"
      >
        Summary
        <img class="icon-right" [src]="summaryImageUrl" />
      </div>
      <div
        (mouseenter)="notesImageUrl = 'assets/icons/notes_white.svg'"
        (mouseleave)="
          notesImageUrl =
            tabInFocus === 'notes'
              ? 'assets/icons/notes_white.svg'
              : 'assets/icons/notes.svg'
        "
        (click)="tabInFocus = 'notes'; goTo('notes')"
        class="button-secondary notes"
        [ngClass]="[tabInFocus === 'notes' ? 'divInFocus' : 'divNotInFocus']"
        *ngxPermissionsOnly="['read_note']"
      >
        Notes
        <span
          *ngIf="(draftNote$ | async)?.length"
          class="inline-margin-left primary-bg-circle"
          style="margin-left: -10rem"
          >{{ (draftNote$ | async)?.length }}</span
        >
        <img class="icon-right" [src]="notesImageUrl" />
      </div>
      <div
        (mouseenter)="ordersImageUrl = 'assets/icons/orders_white.svg'"
        (mouseleave)="
          ordersImageUrl =
            tabInFocus === 'orders'
              ? 'assets/icons/orders_white.svg'
              : 'assets/icons/orders.svg'
        "
        class="button-secondary orders"
        [ngClass]="[tabInFocus === 'orders' ? 'divInFocus' : 'divNotInFocus']"
        (click)="tabInFocus = 'orders'; goTo('orders')"
        *ngxPermissionsOnly="['read_orders']"
      >
        Orders
        <img class="icon-right" [src]="ordersImageUrl" />
      </div>
      <div
        (mouseenter)="vitalsImageUrl = 'assets/icons/vitals_white.svg'"
        (mouseleave)="
          vitalsImageUrl =
            tabInFocus === 'vitals'
              ? 'assets/icons/vitals_white.svg'
              : 'assets/icons/vitals.svg'
        "
        class="button-secondary vitals"
        [ngClass]="[tabInFocus === 'vitals' ? 'divInFocus' : 'divNotInFocus']"
        (click)="goTo('entry', 'vitals')"
      >
        Vitals
        <img class="icon-right" [src]="vitalsImageUrl" />
      </div>
      <div
        (mouseenter)="assessmentImageUrl = 'assets/icons/assessment_white.svg'"
        (mouseleave)="
          assessmentImageUrl =
            tabInFocus === 'assessment'
              ? 'assets/icons/assessment_white.svg'
              : 'assets/icons/assessment.svg'
        "
        class="button-secondary vitals"
        [ngClass]="[
          tabInFocus === 'assessment' ? 'divInFocus' : 'divNotInFocus'
        ]"
        (click)="goTo('entry', 'assessment')"
      >
        Assessment
        <img class="icon-right" [src]="assessmentImageUrl" />
      </div>
      <div
        (mouseenter)="marImageUrl = 'assets/icons/mar_white.svg'"
        (mouseleave)="
          marImageUrl =
            tabInFocus === 'mar'
              ? 'assets/icons/mar_white.svg'
              : 'assets/icons/mar.svg'
        "
        class="button-secondary mar"
        [ngClass]="[tabInFocus === 'mar' ? 'divInFocus' : 'divNotInFocus']"
        *ngxPermissionsOnly="['get_mar']"
        (click)="tabInFocus = 'mar'; goTo('mar')"
      >
        MAR
        <img class="icon-right" [src]="marImageUrl" />
      </div>
      <div
        (mouseenter)="labsScansImageUrl = 'assets/icons/labs_scans_white.svg'"
        (mouseleave)="
          labsScansImageUrl =
            tabInFocus === 'labs'
              ? 'assets/icons/labs_scans_white.svg'
              : 'assets/icons/labs_scans.svg'
        "
        class="button-secondary labs-scans"
        [ngClass]="[tabInFocus === 'labs' ? 'divInFocus' : 'divNotInFocus']"
        (click)="tabInFocus = 'labs'; goTo('labs')"
      >
        Labs/Scans
        <img class="icon-right" [src]="labsScansImageUrl" />
      </div>
      <div
        (mouseenter)="ioImageUrl = 'assets/icons/input_output_white.svg'"
        (mouseleave)="
          ioImageUrl =
            tabInFocus === 'intake-output'
              ? 'assets/icons/input_output_white.svg'
              : 'assets/icons/input_output.svg'
        "
        class="button-secondary io"
        [ngClass]="[
          tabInFocus === 'intake-output' ? 'divInFocus' : 'divNotInFocus'
        ]"
        *ngxPermissionsOnly="['get_io']"
        (click)="tabInFocus = 'io'; goTo('intake-output')"
      >
        I/O
        <img class="icon-right" [src]="ioImageUrl" />
      </div>

      <!-- <div
        (mouseenter)="roundarImageUrl = 'assets/icons/roundar_white.svg'"
        (mouseleave)="
          roundarImageUrl =
            tabInFocus === 'notebook'
              ? 'assets/icons/roundar_white.svg'
              : 'assets/icons/roundar.svg'
        "
        class="button-secondary roundar"
        [ngClass]="[tabInFocus === 'notebook' ? 'divInFocus' : 'divNotInFocus']"
        (click)="tabInFocus = 'roundar'; goTo('notebook')"
      >
        Roundar
        <img class="icon-right" [src]="roundarImageUrl" />
      </div> -->
    </div>

    <div class="go-back-btn">
      <button mat-flat-button class="button-primary" (click)="backToPatList()">
        <img class="icon-left" src="assets/icons/go_back.svg" />
        Back to patient list
      </button>
    </div>
  </div>
</div>

<ng-template #enterWeigthTemplate>
  <div class="enter-weigth-dialog">
    <div class="header">Growth measurements</div>
    <form
      class="iris-form body"
      autocomplete="off"
      [formGroup]="growthParamForm"
    >
      <div class="iris-v-row">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Weight:</mat-label>
          <input
            type="number"
            matInput
            class="iris-input"
            placeholder="gram"
            formControlName="weight"
          />
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Length:</mat-label>
          <input
            type="number"
            matInput
            class="iris-input"
            placeholder="cm"
            formControlName="length"
          />
        </mat-form-field>
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Head circumference:</mat-label>
          <input
            type="number"
            matInput
            class="iris-input"
            placeholder="cm"
            formControlName="headCircumference"
          />
        </mat-form-field>
      </div>
    </form>
    <mat-divider class="divider"></mat-divider>
    <mat-dialog-actions class="dialog-actions">
      <button
        mat-flat-button
        class="button-secondary"
        matDialogClose="cancel"
        color="primary"
        (click)="growthParamForm.reset()"
      >
        Cancel
      </button>
      <button
        type="submit"
        mat-flat-button
        class="button-primary"
        matDialogClose="continue"
        [disabled]="growthParamForm.invalid || growthFormIsEmpty"
        (click)="updateWeight()"
      >
        Update
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
