import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { NcpService } from "../../service/ncp.service";
import * as fromNcpAction from "../action/ncp.action";
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { NcpData, NcpResponse } from "../../type/ncp-data.interface";
import { EMPTY, of } from "rxjs";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Store } from "@ngrx/store";
import * as fromNcpSelector from "../../store/selector/ncp.selector";

@Injectable()
export class NcpCreateEffects {
  constructor(
    private actions$: Actions,
    private service: NcpService,
    private _alertService: AlertService,
    private store: Store<any>
  ) {}
  createNcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNcpAction.createNcp),
      switchMap(({ data, cpmrn, encounter }) =>
        this.service.createNcp(data, cpmrn, encounter).pipe(
          switchMap((data: NcpResponse) => {
            if (data) {
              return of(fromNcpAction.createNcpSuccess({ data }));
            }
          }),
          catchError((error: any) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Error in creating ncp data",
            });

            return EMPTY;
          })
        )
      )
    )
  );

  fetchNcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNcpAction.fetchNcpData),
      switchMap(({ cpmrn, encounter }) =>
        this.service.fetchNcp(cpmrn, encounter).pipe(
          switchMap((data: NcpData) => {
            if (data) {
              return of(fromNcpAction.fetchNcpDataSuccess({ data }));
            } else {
            }
          }),
          catchError((error: any) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Error in fetching ncp data",
            });

            return EMPTY;
          })
        )
      )
    )
  );

  updateNcp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNcpAction.updateNcp),
      switchMap(({ data, cpmrn, encounter }) =>
        this.service.updateNcp(data, cpmrn, encounter).pipe(
          switchMap((data: NcpResponse) => {
            if (data) {
              return of(fromNcpAction.createNcpSuccess({ data }));
            }
          }),
          catchError((error: any) => {
            this._alertService.showNotification({
              type: "Error",
              message: "Error in updating ncp data",
            });

            return EMPTY;
          })
        )
      )
    )
  );
}
