import { select, Store } from "@ngrx/store";
import {
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { takeUntil } from "rxjs/operators";
import { VitalsService } from "src/app/services/vitals.service";
import { VitalsFormService } from "./vitals-form.service";
import { Subject } from "rxjs";
import { AlertService } from "src/app/iris-components/service/alert.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { Patient } from "src/app/models/patient";
import { TimezoneService } from "src/app/services/timezone.service";
import { TimeZoneDetails } from "src/app/models/hospital";
@Component({
  selector: "app-vitals-form",
  templateUrl: "./vitals-form.component.html",
  styleUrls: ["./vitals-form.component.scss"],
})
export class VitalsFormComponent implements OnInit, OnDestroy {
  /**
   * @description To unsubscribe the observable
   * @type {Observable}
   */
  private unsubscribe$ = new Subject();

  private _tz = inject(TimezoneService);

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;
  public timeZoneDetail: TimeZoneDetails;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description To know if patient is intubated
   * @type {boolean}
   */
  public isPatientIntubated = false;

  public vitalImage: any = null;
  public showNetraImage: boolean = false;
  public vitalDay: Vitals | null = null;

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Holds the values of patient position to display in the html
   * @type {Array}
   */
  public patientPositionValues: string[] = [
    "Supine",
    "Right Lateral",
    "Left Lateral",
    "Prone",
    "Sitting",
    "Fowler's",
    "Semi-Fowler's",
    "High-Fowler's",
    "Lithotomy",
    "Sim's",
    "Knee-chest",
    "Trendelenburg",
    "Other",
  ];

  /**
   * @description Holds the values of patient pupillary condition to display in the html
   * @type {Array}
   */
  public patientPupillaryValues: string[] = [
    "Brisk",
    "Sluggish",
    "Non reactive",
    "Dilated and fixed",
    "Asymmetric",
  ];

  /**
   * @description To store the GCS score
   * @type {number}
   */
  GCSScore: number = 0;

  /**
   * @description vitals form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  vitalsGcsForm: UntypedFormGroup = this._vitalFormService.initVitalsGcsForm();

  /**
   * @description To get the value of vitals form from parent form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get vitalsForm() {
    return this.vitalsGcsForm.get("vitalsForm");
  }

  /**
   * @description To get the value of gcs form from parent form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get gcsForm() {
    return this.vitalsGcsForm.get("gcsForm");
  }

  /**
   * @description To get the value of pupillary form from parent form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get pupillaryForm() {
    return this.vitalsGcsForm.get("pupillaryForm");
  }

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.vitalsGcsForm.get("timestamp");
  }

  /**
   * @description To get the value of daysPatPosition
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get patientPosition() {
    return this.vitalsForm.get("daysPatPosition");
  }

  isPatientFirstLoad: boolean = true;
  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient: Patient) {
    if (currPatient && currPatient.CPMRN) {
      if (currPatient.CPMRN !== this.currentPatient?.CPMRN)
        this.isPatientFirstLoad = true;

      this.currentPatient = currPatient;
      this.timeZoneDetail =
        this.currentPatient?.timeZoneDetail ||
        this.currentPatient?.hospitalInfo?.timeZoneDetail;
      this._cdrf.detectChanges();

      if (!this.isPatientFirstLoad) return;
      // For the initial load, set the timestamp according to patient's timezone
      this.vitalsGcsForm
        .get("timestamp")
        .setValue(this._tz.getCurrentTimestampStr(this.timeZoneDetail?.name));

      this.isPatientFirstLoad = false;
    }
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    day = day ?? <Vitals>{};
    if (day) {
      if (day.image?.attachmentLink?.key) {
        this.vitalImage = day.image;
        this.showNetraImage = true;
        this.vitalDay = day;
      } else {
        this.vitalImage = null;
        this.showNetraImage = false;
        this.vitalDay = null;
      }

      this.setFormValues(day);
    }
  }

  // passing from initial-assessment form [ admit form ]
  @Input() admitFormIsParent: boolean = false;

  constructor(
    private store: Store<{}>,
    private _vitalService: VitalsService,
    private _vitalFormService: VitalsFormService,
    private _alertService: AlertService,
    private _cdrf: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.formStateInit();

    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.vitalsGcsForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * @description To set the observables which listens to the form state in the store, Used to show loader / success message
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  formStateInit(): void {
    let formType;
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      // set form type
      if (this.formState.loadType) {
        formType = this.formState.loadType;
      }

      if (this.formState.showSuccess) {
        if (formType == "vitals") {
          // this.resetVitals();

          this._alertService.showNotification({
            type: "Success",
            message: "Vitals updated!",
          });

          this.store.dispatch(vitalActions.hideSuccess());
        }
      }
    });
  }

  /**
   * @description Resets the vitals form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitals(): void {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.vitalsGcsForm.reset();
    this.vitalsForm.get("daysTemperatureUnit").setValue("F");
    this.GCSScore = 0;
    this.formState.error = null;
  }

  /**
   * @description To submit the vitals to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitVitals(): void {
    /** Init the form loading */
    this.store.dispatch(vitalActions.submitForm({ loadType: "vitals" }));

    /** Get the form values */
    let patDataFromForm = this.vitalsForm.value;
    let pupillaryForm = this.pupillaryForm.value;
    let patGcsForm = this.gcsForm.value;
    let timestamp = new Date(this.formDate.value);

    let patientInfo: any = {};

    /* Don't save temperature unit unless temperature entered */
    let tempUnit = patDataFromForm.daysTemperatureUnit;
    if (!patDataFromForm.daysTemperature) {
      tempUnit = null;
    }

    /** Set the patient position if Other is selected */
    let patientPosition =
      patDataFromForm.daysPatPosition === "Other"
        ? patDataFromForm.daysPatPositionOther
        : patDataFromForm.daysPatPosition;

    /** Set the vital obj */
    let vitals = {
      ...patDataFromForm,
      daysTemperatureUnit: tempUnit,
      daysPatPosition: patientPosition,
      timestamp,
    };

    /** Update the props if GCS is entered */
    if (this.GCSScore > 0) {
      vitals = {
        ...vitals,
        ...patGcsForm,
        daysGCS: this.GCSScore,
      };
    }

    /** Update the props if Pupillary is entered */
    if (
      pupillaryForm.daysLeftPupilaryCondition &&
      pupillaryForm.daysRightPupilaryCondition
    ) {
      vitals = {
        ...vitals,
        ...pupillaryForm,
      };
    }

    /** Update the props if severity needs to be set to unstable */
    if (
      patDataFromForm.daysFiO2 > 80 &&
      this.currentPatient.severity !== "Unstable"
    ) {
      patientInfo.severity = "Unstable";
    }

    /** Dispatch the action */
    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals, patientInfo },
      })
    );
  }

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    const vitalValues = () => {
      setFormVal(
        this.vitalsForm,
        "daysTemperature",
        vitalData.daysTemperature || null
      );

      setFormVal(
        this.vitalsForm,
        "daysTemperatureUnit",
        vitalData.daysTemperatureUnit || "F"
      );

      setFormVal(this.vitalsForm, "daysAVPU", vitalData.daysAVPU || null);

      if (vitalData.daysPatPosition) {
        if (this.patientPositionValues.includes(vitalData.daysPatPosition)) {
          setFormVal(
            this.vitalsForm,
            "daysPatPosition",
            vitalData.daysPatPosition
          );
        } else {
          setFormVal(this.vitalsForm, "daysPatPosition", "Other");
          setFormVal(
            this.vitalsForm,
            "daysPatPositionOther",
            vitalData.daysPatPosition
          );
        }
      } else {
        setFormVal(this.vitalsForm, "daysPatPosition", null);
        setFormVal(this.vitalsForm, "daysPatPositionOther", null);
      }

      setFormVal(this.vitalsForm, "daysHR", vitalData.daysHR || null);

      setFormVal(this.vitalsForm, "daysRR", vitalData.daysRR || null);

      setFormVal(this.vitalsForm, "daysBP", vitalData.daysBP || null);

      setFormVal(this.vitalsForm, "daysMAP", vitalData.daysMAP || null);

      setFormVal(
        this.vitalsForm,
        "daysReasonBP",
        vitalData.daysReasonBP || null
      );

      setFormVal(this.vitalsForm, "daysCVP", vitalData.daysCVP || null);

      setFormVal(this.vitalsForm, "daysSpO2", vitalData.daysSpO2 || null);

      setFormVal(this.vitalsForm, "daysFiO2", vitalData.daysFiO2 || null);
    };

    const pupillaryValues = () => {
      setFormVal(
        this.pupillaryForm,
        "daysLeftPupillarySize",
        vitalData.daysLeftPupillarySize || null
      );

      setFormVal(
        this.pupillaryForm,
        "daysRightPupillarySize",
        vitalData.daysRightPupillarySize || null
      );

      setFormVal(
        this.pupillaryForm,
        "daysLeftPupilaryCondition",
        vitalData.daysLeftPupilaryCondition || null
      );

      setFormVal(
        this.pupillaryForm,
        "daysRightPupilaryCondition",
        vitalData.daysRightPupilaryCondition || null
      );
    };

    const gcsValues = () => {
      this.GCSScore = vitalData.daysGCS;

      setFormVal(this.gcsForm, "daysGCSeyes", vitalData.daysGCSeyes || null);

      setFormVal(this.gcsForm, "daysGCSmotor", vitalData.daysGCSmotor || null);

      setFormVal(
        this.gcsForm,
        "daysGCSverbal",
        vitalData.daysGCSverbal !== null && vitalData.daysGCSverbal >= 0
          ? vitalData.daysGCSverbal
          : null
      );
    };

    if (vitalData) {
      /** Set timestamp */
      if (vitalData.timestamp)
        setFormVal(this.vitalsGcsForm, "timestamp", vitalData.timestamp);

      /** Set vitals form values */
      vitalValues();

      /** Set Pupillary form values */
      pupillaryValues();

      /** Set GCS form values */
      gcsValues();
    }
  }

  /**
   * @description Calculate the MAP based on BP
   * @author Legacy
   * @date June 22 2021 (updated)
   */
  onChangeForMAP() {
    if (
      this.vitalsForm.get("daysBP").valid &&
      this.vitalsForm.get("daysBP").value
    ) {
      let split = this.vitalsForm.get("daysBP").value.split("/");
      let map = (2 * split[1] + parseInt(split[0])) / 3;
      if (map <= 500 && map >= 1) {
        this.vitalsForm.get("daysMAP").setValue(map.toFixed(2));
      } else {
        this.vitalsForm.get("daysMAP").setValue(null);
      }
    }
  }

  /**
   * @description Update the GCS score based on the inputs
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  onGcsChange() {
    let eye =
      this.gcsForm.value.daysGCSeyes === null ||
      typeof this.gcsForm.value.daysGCSeyes === "undefined"
        ? 0
        : parseInt(this.gcsForm.value.daysGCSeyes);
    let verbal =
      this.gcsForm.value.daysGCSverbal === null ||
      typeof this.gcsForm.value.daysGCSverbal === "undefined"
        ? 0
        : parseInt(this.gcsForm.value.daysGCSverbal);
    let motor =
      this.gcsForm.value.daysGCSmotor === null ||
      typeof this.gcsForm.value.daysGCSmotor === "undefined"
        ? 0
        : parseInt(this.gcsForm.value.daysGCSmotor);

    this.GCSScore = eye + verbal + motor;
  }

  // ngOnInit() {
  //   this.vitalsForm = this.fb.group(
  //     {
  //       daysTemperature: [
  //         null,
  //         Validators.compose([
  //           Validators.min(0),
  //           Validators.max(125),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       daysTemperatureUnit: ['F'],
  //       patAVPU: [null],
  //       patHR: [
  //         null,
  //         Validators.compose([
  //           Validators.min(0),
  //           Validators.max(350),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patRR: [
  //         null,
  //         Validators.compose([
  //           Validators.min(0),
  //           Validators.max(150),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patBP: [null, Validators.pattern(/^\d{1,3}\/\d{1,3}$/)],
  //       patReasonBP: [null],
  //       patMAP: [
  //         null,
  //         Validators.compose([
  //           Validators.nullValidator,
  //           Validators.min(0),
  //           Validators.max(500),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patCVP: [
  //         null,
  //         Validators.compose([
  //           Validators.nullValidator,
  //           Validators.min(-50),
  //           Validators.max(50),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patSpO2: [
  //         null,
  //         Validators.compose([
  //           Validators.min(0),
  //           Validators.max(100),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patFiO2: [
  //         null,
  //         Validators.compose([
  //           Validators.min(0),
  //           Validators.max(100),
  //           Validators.pattern(/^(\d*\.)?\d+$/),
  //         ]),
  //       ],
  //       patVitalsDate: [null, Validators.required],
  //       patVitalsTime: [null, Validators.required],
  //     },
  //     { validator: checkBlankVitals }
  //   );

  //   this.nowVitals();

  //   // Get latest patient monitor vitals data from store, and set it to form.
  //   this.store
  //     .pipe(select(fromPatientMonitors.getPatientMonitorVitalsLatestDataState))
  //     .pipe(filter<PatientMonitorVitalData>(Boolean))
  //     .subscribe((data) => {
  //       if (data.old) {
  //         this.patientMonitorVitalFormError.push(
  //           'Data from the patient monitor is old.'
  //         );
  //       }

  //       if (
  //         !this._patientMonitorVitalService.checkIfVitalsIsEmpty(data.vitals)
  //       ) {
  //         let bp = null;

  //         if (data.vitals.bp1 !== '0' && data.vitals.bp2 !== '0') {
  //           bp = data.vitals.bp1 + '/' + data.vitals.bp2;
  //         }

  //         this.vitalsForm.controls['patAVPU'].setValue(null);
  //         this.vitalsForm.controls['patHR'].setValue(
  //           data.vitals.hr !== '0' ? data.vitals.hr : null
  //         );
  //         this.vitalsForm.controls['patRR'].setValue(
  //           data.vitals.rr !== '0' ? data.vitals.rr : null
  //         );
  //         this.vitalsForm.controls['patBP'].setValue(bp);
  //         this.vitalsForm.controls['patReasonBP'].setValue(null);
  //         this.vitalsForm.controls['patMAP'].setValue(
  //           data.vitals.MAP !== '0' ? data.vitals.MAP : null
  //         );
  //         this.vitalsForm.controls['patCVP'].setValue(null);
  //         this.vitalsForm.controls['patSpO2'].setValue(
  //           data.vitals.spo2 !== '0' ? data.vitals.spo2 : null
  //         );
  //         this.vitalsForm.controls['patFiO2'].setValue(null);
  //         this.vitalsForm.controls['daysTemperature'].setValue(
  //           data.vitals.temp1 !== '0.0' ? data.vitals.temp1 : null
  //         );
  //         this.vitalsForm.controls['daysTemperatureUnit'].setValue(
  //           data.vitals.temp1Unit || 'F'
  //         );
  //         this.vitalsForm.controls['patVitalsDate'].setValue(
  //           new Date(data.timestamp)
  //         );
  //         this.vitalsForm.controls['patVitalsTime'].setValue(
  //           new Date(data.timestamp).toTimeString()
  //         );
  //       } else {
  //         this.patientMonitorVitalFormError = [];
  //         this.patientMonitorVitalFormError.push(
  //           'Data from the patient monitor seems to be empty.'
  //         );
  //       }
  //     });

  //   // listen to loading props
  //   let formType;
  //   this.vitalForm$.subscribe((data) => {
  //     this.formState = data;

  //     // set form type
  //     if (this.formState.loadType) {
  //       formType = this.formState.loadType;
  //     }

  //     if (this.formState.showSuccess) {
  //       if (formType == 'vitals') {
  //         this.vitalsForm.reset();
  //         this.vitalsForm.get['daysTemperatureUnit'].setValue('F');
  //       }
  //     }
  //   });

  //   // Initialy when the form loads from vision view component
  //   this.vitalsNotificationId =
  //     this.route.snapshot.queryParams &&
  //     this.route.snapshot.queryParams.notificationId;
  //   if (this.vitalsNotificationId) {
  //     this.store
  //       .pipe(
  //         select(
  //           fromNotification.getNotificationById,
  //           this.vitalsNotificationId
  //         )
  //       )
  //       .subscribe((data) => {
  //         if (data) {
  //           this.vitalsNotificationValues = data;
  //           this.setFormValuesOnChange(data);
  //         }
  //       });
  //   }

  //   this.ventForm = this.ventFormService.initForm(this.ventConfig);

  //   /* Get column header date/time value from table*/
  //   this.vitalsClickedDay$.subscribe((dateTimeObj: any) => {
  //     if (dateTimeObj && dateTimeObj['day']) {
  //       this.vitalImage = dateTimeObj.min.image ? dateTimeObj.min.image : null;
  //       let vitalsToLoad = dateTimeObj['min']['vitals'];
  //       let vitDate = new Date(dateTimeObj['day']);
  //       let vitTime =
  //         dateTimeObj['hr'] + ':' + dateTimeObj['min']['minuteNumber'];

  //       this.isPatientIntubated = vitalsToLoad.daysGCSverbal == 0;

  //       this.ventValues = {
  //         ...this.ventVitalService.convertVentVitalToVentForm(vitalsToLoad),
  //         patVentDate: vitDate,
  //         patVentTime: vitTime,
  //       };

  //       this.vitalsForm.controls['daysTemperature'].setValue(
  //         vitalsToLoad.daysTemperature
  //       );
  //       if (vitalsToLoad.daysTemperatureUnit) {
  //         console.log('temp unit ', vitalsToLoad.daysTemperatureUnit);
  //         this.vitalsForm.controls['daysTemperatureUnit'].setValue(
  //           vitalsToLoad.daysTemperatureUnit || 'F'
  //         );
  //       } else {
  //         this.vitalsForm.controls['daysTemperatureUnit'].setValue('F');
  //       }
  //       this.vitalsForm.controls['patAVPU'].setValue(vitalsToLoad.daysAVPU);
  //       this.vitalsForm.controls['patHR'].setValue(vitalsToLoad.daysHR);
  //       this.vitalsForm.controls['patRR'].setValue(vitalsToLoad.daysRR);
  //       this.vitalsForm.controls['patBP'].setValue(vitalsToLoad.daysBP);
  //       this.vitalsForm.controls['patReasonBP'].setValue(
  //         vitalsToLoad.daysReasonBP
  //       );
  //       this.vitalsForm.controls['patMAP'].setValue(vitalsToLoad.daysMAP);
  //       this.vitalsForm.controls['patCVP'].setValue(vitalsToLoad.daysCVP);
  //       this.vitalsForm.controls['patSpO2'].setValue(vitalsToLoad.daysSpO2);
  //       this.vitalsForm.controls['patFiO2'].setValue(vitalsToLoad.daysFiO2);
  //       // converting to date object for new date and time picker provider
  //       this.vitalsForm.controls['patVitalsDate'].setValue(vitDate);
  //       this.vitalsForm.controls['patVitalsTime'].setValue(vitTime);

  //       if (dateTimeObj.min.image && dateTimeObj.min.image.attachmentLink) {
  //         this.showNetraImage = true;
  //       } else {
  //         this.showNetraImage = false;
  //       }
  //     } else {
  //       this.vitalsForm.reset();
  //       this.ventForm.reset();
  //       this.vitalsForm.controls['daysTemperatureUnit'].setValue('F');
  //     }
  //   });
  // }

  onTimeDateChanged(dateObj) {
    this.showNetraImage = false;
    this.store.dispatch(vitalActions.setChangedTime({ dateObj }));
  }

  openNetraImage() {
    this._vitalService.triggerNetraImage(this.vitalDay);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
