import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import * as ncpAddReducer from "./ncp-add.reducer";
import * as ncpCreateReducer from "./ncp-create.reducer";
import * as ncpFetchReducer from "./ncp-fetch.reducer";
import * as ncpUpdateReducer from "./ncp-fetch.reducer";

export interface NcpState {
  ncpAdd: ncpAddReducer.State;
  ncpCreate: ncpCreateReducer.State;
  ncpFetch: ncpFetchReducer.State;
  ncpUpdate: ncpUpdateReducer.State;
}

export const reducer: ActionReducerMap<any, any> = {
  ncpAdd: ncpAddReducer.reducer,
  ncpCreate: ncpCreateReducer.reducer,
  ncpFetch: ncpFetchReducer.reducer,
  ncpUpdate: ncpUpdateReducer.reducer,
};

// selector
export const getNcpCreateState = createFeatureSelector<NcpState>("ncp");
